import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  CdsSizeSpecificM,
  CdsColorBrandPrimary40
} from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  apiAvatar: {
    width: '72px',
    height: '72px',
    fontSize: '32px',
    background: CdsColorBrandPrimary40
  },
  infoTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  infoSubtitle: {
    opacity: '0.6'
  },
  creatorLink: {
    display: 'flex',
    alignItems: 'center'
  },
  allActivitiesBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1em 0 0 0'
  },
  coloredBlockInfo: {
    color: 'white',
    padding: CdsSizeSpecificM,
    backgroundColor: CdsColorBrandPrimary40
  }
}));
