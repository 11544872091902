import Skeleton from 'Theme/components/Skeleton';
import { TableCollectionColumn } from 'Components/Shared/Table';
import { PersonOrder } from 'Apollo';
import useTranslate from './useTranslate';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';
import { IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomStatus from 'Components/Shared/Chips/CustomStatus';
import { copyToClipboard } from 'Tools/copyToClipboard';
import { FormattedDate } from 'react-intl';

export const useColumnsOrganization = (): TableCollectionColumn<
  IOrganizationDomain
>[] => {
  const ts = useTranslate();
  const isMobile = useMediaQuery('(max-width:740px)');

  return [
    {
      key: 'name',
      orderBy: {
        asc: PersonOrder.NameAsc,
        desc: PersonOrder.NameDesc
      },
      hidden: false,
      renderHead: () => ts('common.tableHead.name'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => row.name
    },
    {
      key: 'status',
      width: 0.2,
      hidden: false,
      renderHead: () => ts('common.tableHead.status'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => {
        if (row.validationMode === DomainValidationMode.Strong) {
          return (
            <CustomStatus
              variant="success"
              label={ts('page.organizationDomains.statusType', {
                value: (row.validationMode as unknown) as keyof typeof DomainValidationMode
              })}
            />
          );
        }
        if (row.validationMode === DomainValidationMode.None) {
          return (
            <CustomStatus
              variant="pending"
              label={ts('page.organizationDomains.statusType', {
                value: (row.validationMode as unknown) as keyof typeof DomainValidationMode
              })}
            />
          );
        }
        if (row.validationMode === DomainValidationMode.Weak) {
          return (
            <CustomStatus
              variant="warning"
              label={ts('page.organizationDomains.statusType', {
                value: (row.validationMode as unknown) as keyof typeof DomainValidationMode
              })}
            />
          );
        }
      }
    },
    {
      key: "expiration",
      width: 0.1,
      hidden: false,
      renderHead: () => ts('common.tableHead.expiration'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => {
        if (row.validationMode !== DomainValidationMode.None || row.validationCodeExpirationDate === null) {
          return '';
        }
        const limitDate = new Date(row.validationCodeExpirationDate);
        return (
          <Typography variant="caption" role="expiration-date">
            {<FormattedDate
              value={limitDate.toISOString()}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />}
          </Typography>
        )
      }
    },
    {
      key: 'code',
      hidden: false,
      renderHead: () => isMobile ? ts('common.tableHead.code') : ts('common.tableHead.validationCode'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => {
        if (row.validationCode !== null && row.validationMode === DomainValidationMode.None) { 
          return (
            <div style={{display: 'flex'}}>
              {!isMobile && (
                <Typography variant='caption' style={{alignSelf: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '96px'
                  }}>
                  {row.validationCode}
                </Typography>
              )}
              <Tooltip title={row.validationCode}>
                <IconButton
                  onClick={() => copyToClipboard(row.validationCode as string)}
                >
                  <ContentCopyIcon />
                </IconButton>

              </Tooltip>
              
            </div>
          )
        } else return '';
      }
    }
  ];
};
