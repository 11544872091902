import {
  generatePath,
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useParams
} from 'react-router-dom';
import { Tabs, Tab, Hidden, IconButton, Tooltip, Box } from '@mui/material';
import { TabProps } from '@mui/material/Tab';
import ReplayIcon from '@mui/icons-material/Replay';
import Container from 'Theme/components/Container';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useStyles } from './ApiKeys.styles';
import TABS from './Tabs';
import { PrivateRoutes } from 'Router/Routes';
import joinURL from 'url-join';
import GenerateApiKeyToggle from './GenerateApiKey/GenerateApiKeyToggle';
import { useContext, forwardRef } from 'react';
import useTranslate from 'Hooks/useTranslate';
import { LocalContext } from 'Context/Local.context';
import useActiveTabSelection from 'Hooks/useActiveTabSelection';

const ApiKeys = () => {
  const { apiKeyStatus } = useParams<{
    apiKeyStatus: string;
  }>();
  const { currentAccount } = useContext(LocalContext);
  const selectedAccountCode = currentAccount?.code ?? null;
  const ts = useTranslate();
  const classes = useStyles();
  const basePath = generatePath(PrivateRoutes.apiKeyServices.path, {
    accountCode: selectedAccountCode ?? 'all',
    apiKeyState: apiKeyStatus
  });
  const indexTabSelection = useActiveTabSelection({ tabs: TABS, basePath });

  return <>
    <Helmet title={ts('page.apiKeys.title')} />

    <div className={classes.tabsBar}>
      <div className={classes.tabsBarLeft}></div>
      <Tabs
        value={indexTabSelection}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {TABS.map(({ icon, name, path, testId }) => (
          <Tab
            key={name}
            component={forwardRef<RouterLink, TabProps>(
              ({ className, children }, ref) => (
                <RouterLink
                  ref={ref}
                  className={className}
                  to={joinURL(basePath, path)}
                  data-testid={testId}
                >
                  {children}
                </RouterLink>
              )
            )}
            label={
              <>
                <Hidden only="xs">
                  <FormattedMessage id={name} />
                </Hidden>
                <Hidden smUp>{icon}</Hidden>
              </>
            }
          />
        ))}
      </Tabs>
      <div className={classes.tabsBarRight}>
        <Tooltip title={ts('page.apiKeys.refreshTooltip')}>
          <Box display="block">
            <IconButton
              className={classes.refreshButton}
              onClick={(
                event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>
              ) => window.location.reload()}
              size="large">
              <ReplayIcon className={classes.icons} color="action" />
            </IconButton>
          </Box>
        </Tooltip>
        <GenerateApiKeyToggle accountCode={selectedAccountCode} />
      </div>
    </div>

    <Container>
      <Switch>
        {TABS.map(({ path, Tab, name }) => (
          <Route
            key={name}
            path={joinURL(PrivateRoutes.apiKeyServices.path, path)}
            exact={true}
          >
            <Tab accountCode={selectedAccountCode} />
          </Route>
        ))}
        <Route>
          <Redirect to={basePath} />
        </Route>
      </Switch>
    </Container>
  </>;
};

export default ApiKeys;
