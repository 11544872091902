import { gql } from '@apollo/client';
import { ApiKeyFragment } from '../../fragments/apikey/ApiKey';

export const GetApiKeys = gql`
  query GetApiKeys(
    $accountFilter: String
    $first: Int
    $offset: Int
    $serviceFilter: String
    $state: ApiKeyState
  ) {
    apiKeys(
      accountFilter: $accountFilter
      first: $first
      offset: $offset
      serviceFilter: $serviceFilter
      state: $state
    ) {
      edges {
        node {
          ...ApiKey
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ApiKeyFragment}
`;
