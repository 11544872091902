import { useEffect, useState } from 'react';
import {
  CircularProgress,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Fab
} from '@mui/material';
import { Search as IconSearch, Close as CloseIcon } from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client';
import {
  GetLightPersonAccessRightRequests,
  PersonAccessRightStatus
} from 'Apollo';
import TestIds from 'Tests/TestIds';
import { useStyles } from '../SearchBars.styles';
import { AvailabledResult } from './AvailabledResult';
import { userFullName } from 'Tools';
import useTranslate from 'Hooks/useTranslate';
import debounce from 'lodash.debounce';
import { DEBOUNCE_DELAY } from 'Theme/config';
import Container from 'Theme/components/Container';

interface Props {
  accountCode: string;
  onSearch: (value: string) => void;
  onItemSelect: (value: string) => void;
  onInit: () => void;
}

const SearchByUser = (props: Props) => {
  const { accountCode, onSearch, onItemSelect, onInit } = props;
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined
  );

  const classes = useStyles();
  const ts = useTranslate();

  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  let [getUsers, { loading, data: queryData }] = useLazyQuery<
    GetLightPersonAccessRightRequestsData,
    GetLightPersonAccessRightRequestsVars
  >(GetLightPersonAccessRightRequests, {
    fetchPolicy: 'cache-first'
  });

  const handleClean = () => {
    setCurrentSearch('');
    setSelectedUser(undefined);
    setIsDisabled(false);
    onInit();
  };

  const onUserSearch = async (value: string) => {
    const filterValue = value.trim();

    await getUsers({
      variables: {
        personAccessRightFilter: {
          infoPerson: filterValue,
          accountCode: accountCode !== 'default' ? accountCode : '',
          status: PersonAccessRightStatus.ToValidate
        },
        page: { first: 5, offset: 0 }
      }
    });
  };

  const debouncedOnUpdate = debounce(onSearch, DEBOUNCE_DELAY);
  const debouncedUserSearch = debounce(onUserSearch, DEBOUNCE_DELAY * 3);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const infoPerson = event.target.value;
    setCurrentSearch(infoPerson);
    setSelectedUser(undefined);
    debouncedUserSearch(infoPerson);
  };

  let noResult = queryData?.lightPersonAccessRightRequests.totalCount === 0;

  useEffect(() => {
    if (queryData !== undefined) {
      debouncedOnUpdate(currentSearch);
    }
  });

  return (
    <Container>
      <Grid
        container
        className={classes.searchbarBackground}
        onBlur={e => {
          const currentTarget = e.currentTarget;
          requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setIsFocused(false);
            }
          });
        }}
        wrap="wrap"
      >
        <Grid item className={classes.searchbar}>
          {selectedUser === undefined && (
            <TextField
              className={classes.searchbarTextField}
              variant="outlined"
              fullWidth={true}
              placeholder={ts('common.action.search')}
              value={currentSearch}
              hidden={selectedUser !== undefined}
              onChange={handleChange}
              size="small"
              disabled={isDisabled}
              onFocus={() => {
                setIsFocused(true);
              }}
              inputProps={{
                // @ts-ignore
                'data-testid': TestIds.component.searchByUser.input
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      color={isFocused ? 'primary' : 'inherit'}
                      data-testid={TestIds.component.searchByUser.searchIcon}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {loading && (
                      <CircularProgress
                        disableShrink
                        size={20}
                        color="secondary"
                        className={classes.circularProgressRoot}
                      />
                    )}
                    {!loading && currentSearch.length >= 1 && (
                      <IconButton
                        size="small"
                        data-testid={TestIds.component.searchByUser.closeIcon}
                        onClick={handleClean}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
          )}

          {selectedUser !== undefined && (
            <div className={classes.selectedUserSearchbar}>
              <IconSearch
                color={isFocused ? 'primary' : 'inherit'}
                style={{ margin: 12 }}
              />
              <Fab
                variant="extended"
                size="small"
                color="primary"
                disableRipple={true}
                className={classes.userSelectedFab}
                onClick={handleClean}
                data-testid={
                  TestIds.component.searchByUser.selectedUser.closeIcon
                }
              >
                {selectedUser}
                <CloseIcon fontSize="inherit" />
              </Fab>
            </div>
          )}
        </Grid>
        <Grid item className={classes.availabledResult}>
          {queryData?.lightPersonAccessRightRequests &&
            queryData?.lightPersonAccessRightRequests.edges.length > 0 &&
            isFocused &&
            currentSearch.length > 0 &&
            !noResult && (
              <AvailabledResult
                data={queryData?.lightPersonAccessRightRequests}
                currentSearch={currentSearch}
                onChooseUser={(value: ILightPersonProfile) => {
                  const { immutableId, firstName, lastName } = value;
                  onItemSelect(immutableId);
                  setIsFocused(false);
                  setIsDisabled(true);
                  setSelectedUser(userFullName(firstName, lastName));
                }}
              />
            )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchByUser;
