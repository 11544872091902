import { useState } from 'react';
import {  Paper, useMediaQuery } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { TableCollection } from 'Theme/components/TableCollection';
import EmptyState from 'Components/Shared/EmptyState';
import useTranslate from 'Hooks/useTranslate';
import useNotification from 'Hooks/useNotification';
import { GetFellowAdministrators } from 'Apollo';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { mdiAccountStar } from '@mdi/js';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import { CdsColorTextNeutralMediumEmphasis } from 'Theme/constants/tokens';
import TestIds from 'Tests/TestIds';
import { useStyles } from './AdministratorsTable.styles';
import { calculateNewPage } from 'Tools/calculateNewPage';
import { useColumnsAdministrators } from 'Hooks/useColumnsAdministrators';
import { useColumnsAdministratorsLight } from 'Hooks/useColumnsAdministratorsLight';

function AdministratorsTable() {
  const { accountCode } = useParams<IUriParams>();
  const ts = useTranslate();
  const classes = useStyles();
  const { onError } = useNotification();
  const isMobileP = useMediaQuery(
    '(max-width:600px) and (orientation: portrait)'
  );

  const admColumns = useColumnsAdministrators({});
  const lightAdmColumns = useColumnsAdministratorsLight({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const initvariables = {
    accountCode: accountCode === 'all' ? null : accountCode,
    first: rowsPerPage,
    offset: currentPage * rowsPerPage
  };

  const { loading: fetching, data } = useQuery<GetFellowAdministratorsData>(
    GetFellowAdministrators,
    {
      onError,
      variables: initvariables,
      fetchPolicy: 'cache-and-network'
    }
  );

  let loading = fetching;
  let rows: Array<{ node: IPerson }> = [];
  let totalCount = rowsPerPage;

  if (data?.fellowAdministrators) {
    rows = data.fellowAdministrators.edges;
    totalCount = data.fellowAdministrators.totalCount;
  } else {
    loading = true;
  }

  return (
    <Paper className={classes.root}>
      <TableCollection
        data-testid={TestIds.pages.administrators.administratorsTable}
        loading={loading}
        columns={!isMobileP ? admColumns : lightAdmColumns}
        rows={rows}
        page={currentPage}
        selectedIds={[]}
        isSelectionHidden={true}
        maxSelected={0}
        getRowId={({ node: personGroup }: { node: IPersonGroup }) =>
          personGroup.code
        }
        orderBy={undefined} /** query GetFellowAdministrators have no orderBy parameter */
        onChangeOrderBy={() =>{}}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(newRowsPerPage: number)=>{
          setRowsPerPage(newRowsPerPage);
          setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
        }}
        onChangePage={(newPage: number) => setCurrentPage(newPage)}
        onUnselect={() => {}}
        onSelect={() => {}}
        totalCount={totalCount}
        emptyStateData={
          <EmptyState
            icon={<SvgIcon path={mdiAccountStar} fill={CdsColorTextNeutralMediumEmphasis} />}
            primary={ts('page.administrators.emptyState.title')}
            secondary={ts('page.administrators.emptyState.description')}
          />
        }
      />
    </Paper>
  )
}

export default AdministratorsTable;
