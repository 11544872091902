import { Box, Button, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useTranslate from '../../Hooks/useTranslate';
import { generatePath, useParams, Link } from 'react-router-dom';
import TestIds from 'Tests/TestIds';
import { PrivateRoutes } from '../../Router/Routes';
import { useStyles } from './AccessRightsCard.styles';
import PersonAvatar from 'Theme/components/Avatar/PersonAvatar';
import { PersonAccessRightStatus } from '../../Apollo';
import { DatePickerButton } from '../Shared';
import { userFullName } from 'Tools';
import { LocalContext } from 'Context/Local.context';
import { PersonContext } from 'Context/CurrentPerson';

type Props = {
  data: IPersonAccessRight;
  onCardUpdate(a: Partial<IUpdateAccessRightRequestVars>): void;
};

const AccessRightsCard = ({ data, onCardUpdate }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  const [activeDatePicker, setActiveDatePicker] = useState<boolean>(false);
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(undefined);
  const { availableAccounts } = useContext(LocalContext);
  const { currentPersonRoles } = useContext(PersonContext);
  const { accountCode } = useParams<IUriParams>();

  const serviceIdentification = {
    accountCode: data.account?.code || null,
    profileImmutableId: data.person.immutableId,
    serviceCode: data.service.groupCode
  };

  const initDatePicker = () => {
    setExpiryDate(undefined);
    setActiveDatePicker(false);
  };

  const handleDeny = () => {
    onCardUpdate({
      ...serviceIdentification,
      processStatus: PersonAccessRightStatus.Refused
    });
    initDatePicker();
  };

  const handleAccept = () => {
    onCardUpdate({
      ...serviceIdentification,
      processStatus: PersonAccessRightStatus.Accepted,
      expiryDate: expiryDate?.toUTCString()
    });
    initDatePicker();
  };

  return (
    <Box>
      <Grid container style={{ paddingBottom: 5 }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component={'span'}
            display="block"
            noWrap
            data-testid={
              TestIds.component.accessRightRequests.serviceName
            }
          >
            {data.service.name}
          </Typography>
          {availableAccounts.length > 1 && (
            <Typography
              component={'span'}
              display="block"
              variant="caption"
              noWrap
              data-testid={
                TestIds.component.accessRightRequests.accountCode
              }
            >
              {data.account?.code} - {data.account?.name}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid
          item
          xs={4}
          data-testid={TestIds.component.accessRightRequests.userLink}
        >
          <Box
            display="flex"
            alignItems="center"
            mt={1}
            flexWrap={'wrap'}
          >
            <Box p={1}>
              <PersonAvatar
                component="span"
                size="medium"
                person={data.person}
              />
            </Box>
            <Box>
              <Typography variant="body2">
                {userFullName(
                  data.person?.firstName,
                  data.person?.lastName
                )}
              </Typography>
              {currentPersonRoles.hasAccessToAccounts ? (
                <Link
                  to={
                    data.person?.immutableId !== undefined &&
                    generatePath(PrivateRoutes.usersProfile.path, {
                      accountCode: data.account?.code || accountCode,
                      immutableId: data.person?.immutableId
                    })
                  }
                  color="primary"
                  className={classes.seeProfileLink}
                >
                  <Typography variant="caption">
                    {ts('common.action.seeProfile')}
                  </Typography>
                </Link>
              ) : (
                <Typography variant="body2">
                  {data.person?.email}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item container spacing={1} xs={8} justifyContent="flex-end">
          <Grid item xs={12} container justifyContent="flex-end">
            {!activeDatePicker ? (
              <Button
                size="small"
                color="inherit"
                startIcon={
                  <CalendarTodayIcon
                    data-testid={
                      TestIds.component.accessRightCard.calendarTodayIcon
                    }
                  />
                }
                className={classes.transformNone}
                onClick={() => setActiveDatePicker(true)}
                data-testid={
                  TestIds.component.accessRightCard
                    .defineExpirationDateButton
                }
              >
                {ts('page.accessRights.defineExpirationDate')}
              </Button>
            ) : (
              <Box display="flex" alignItems="center">
                <DatePickerButton
                  onAccept={(expiryDate: Date) =>
                    setExpiryDate(expiryDate)
                  }
                  onDecline={initDatePicker}
                  data-testid={
                    TestIds.component.accessRightCard.datePickerButton
                  }
                />
              </Box>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="text"
              size="small"
              color="inherit"
              startIcon={<CloseIcon />}
              onClick={handleDeny}
              data-testid={
                TestIds.component.accessRightRequests.denyButton
              }
            >
              {ts('common.action.deny')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              size="small"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={handleAccept}
              data-testid={
                TestIds.component.accessRightRequests.activateButton
              }
            >
              {ts('common.action.accept')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessRightsCard;
