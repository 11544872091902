import { useQuery } from '@apollo/client';
import { BubbleChart } from '@mui/icons-material';
import { ServiceSubscriptionOrder } from 'Apollo';
import { GetServiceSubscriptionsCount } from 'Apollo/queries/service/GetServiceSubscriptionsCount';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import CardCounter from 'Theme/components/CardCounter';
import { useParams } from 'react-router';

function ServicesCounter() {
  const ts = useTranslate();
  const { accountCode } = useParams<{ accountCode: string }>();

  const initialVariables = {
    first: ROW_PER_PAGE_DEFAULT,
    offset: 0,
    servicesFilter: '',
    accountCode: accountCode === 'all' ? null : accountCode,
    apiKeyEligibleOnly: false,
    orderBy: ServiceSubscriptionOrder.NameAsc
  };
  const { data, loading } = useQuery<GetServiceSubscriptionsCountData>(
    GetServiceSubscriptionsCount,
    { variables: initialVariables }
  );

  const totalServicesCard = data?.serviceSubscriptions.totalCount ?? 0;
  return (
    <CardCounter
      testId={TestIds.pages.services.cardTotalCount}
      title={ts('menu.services')}
      counter={totalServicesCard}
      loading={loading}
      icon={<BubbleChart />}
    />
  );
}

export default ServicesCounter;
