import { Divider, Grid, Typography } from "@mui/material";
import { Imgs } from "../../../../../../../Assets";
import useTranslate from "../../../../../../../Hooks/useTranslate";
import { capitalizeFirstLetter } from "../../../../../../../Tools";
import { useStyles } from "../../Directories.styles";
import MergeDirectory from "./MergeDirectory";

interface Props {
    dirKey: string;
    person: IPersonWithAccount;
 }


function MergeableDirectory(props: Props) {

    const ts = useTranslate();
    const classes = useStyles();
    const { dirKey, person } = props;

    const directoryLabel = capitalizeFirstLetter(dirKey);
    
    return (
        <>
            <Divider variant="inset" sx={{ opacity: 0.6 }} />
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.smColumn}
            >
                <Grid
                    item
                    container
                    sm={9}
                    alignItems="center"
                    direction="row"
                >
                    <Grid item>
                        {dirKey === "microsoft" && (
                            <img
                                src={Imgs.microsoft.src}
                                alt={Imgs.microsoft.alt}
                                className={classes.logo}
                            />
                        )}

                        {dirKey === "google" && (
                            <img
                                src={Imgs.google.src}
                                alt={Imgs.google.alt}
                                className={classes.logo}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.infoText}>
                        <Typography variant="body1">{directoryLabel}</Typography>
                        <Typography variant="body2">
                            {ts('page.userProfile.mergeAccountInfo', {
                                dir: directoryLabel
                            })}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    sm={3}
                    justifyContent="flex-end"
                >
                    <MergeDirectory
                        key={directoryLabel}
                        person={person}
                        directoryToMerge={directoryLabel}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default MergeableDirectory;