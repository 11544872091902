import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CdsColorActionCriticalDarkActive,
  CdsColorActionInfoDarkActive,
  CdsColorActionSuccessDarkActive,
  CdsColorActionWarnDarkActive,
  CdsColorBackgroundCriticalLight,
  CdsColorBackgroundInfoLight,
  CdsColorBackgroundSuccessLight,
  CdsColorBackgroundWarnLight,
  CdsSizeRadius
} from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  info: {
    boxShadow: 'none',
    borderRadius: CdsSizeRadius,
    margin: '1em 0px 1em 0px',
    color: CdsColorActionInfoDarkActive,
    borderLeft: '6px solid currentcolor',
    background: CdsColorBackgroundInfoLight
  },
  warning: {
    boxShadow: 'none',
    borderRadius: CdsSizeRadius,
    margin: '1em 0px 1em 0px',
    color: CdsColorActionWarnDarkActive,
    borderLeft: '6px solid currentcolor',
    background: CdsColorBackgroundWarnLight
  },
  error: {
    boxShadow: 'none',
    borderRadius: CdsSizeRadius,
    margin: '1em 0px 1em 0px',
    color: CdsColorActionCriticalDarkActive,
    borderLeft: '6px solid currentcolor',
    background: CdsColorBackgroundCriticalLight
  },
  success: {
    boxShadow: 'none',
    borderRadius: CdsSizeRadius,
    margin: '1em 0px 1em 0px',
    color: CdsColorActionSuccessDarkActive,
    borderLeft: '6px solid currentcolor',
    background: CdsColorBackgroundSuccessLight
  }
}));
