import { UpdatePersonAccount } from 'Apollo/mutations/person/UpdatePersonAccount';
import { GetPersonWithAccount } from 'Apollo/queries/person/GetPersonWithAccount';
import { GetAccounts, GetPersonAccessRights } from 'Apollo';
import { useLazyQuery, useMutation } from '@apollo/client';
import LinkToNewAccount from './LinkToNewAccount';
import useNotification from 'Hooks/useNotification';
import { useState } from 'react';

interface Props {
  person: IPersonWithAccount;
  accessRights: IPersonAccessRight[];
  isOpen: boolean;
  onClose: () => void;
}

function LinkToNewAccountContainer(props: Readonly<Props>) {
  const { person, accessRights, isOpen, onClose } = props;
  const { onError, onSuccess } = useNotification();

  let [selectedAccount, setSelectedAccount] = useState<string | undefined>(
    undefined
  );

   const [onUpdate, { loading: loadingMutation }] = useMutation<
    IUpdatePersonAccountData,
    IUpdatePersonAccountVars
  >(UpdatePersonAccount, {
    onError,
    onCompleted: () => {
      onClose();
      onSuccess(
        'component.userAccountUpdate.mutation.linkToNewAccountSuccess',
        { value: selectedAccount }
      );
    },
    refetchQueries: [GetPersonWithAccount, GetPersonAccessRights]
  });

  const handleUpdate = (accCode: string) => {
    setSelectedAccount(accCode);
    onUpdate({
      variables: {
        newAccountCode: accCode,
        personIdentifier: person.immutableId
      }
    });
  };

  const [getData, { data: selectorOptions }] = useLazyQuery<GetAccountsData>(
    GetAccounts,
    {
      variables: { first: 10 }
    }
  );

  const handleSearch = (value: string) => {
    let filterValue = value.trim();
    if (filterValue !== '' && selectedAccount === undefined) {
      getData({ variables: { filter: filterValue } });
    }
  };

  let selectorList: IAccount[] = [];
  if (selectorOptions) {
    selectorList = selectorOptions.accounts.edges.map(({ node }) => node);
  }

  return (
    <LinkToNewAccount
      person={person}
      accessRights={accessRights}
      isOpen={isOpen}
      loading={loadingMutation}
      onClose={onClose}
      onSearch={handleSearch}
      updateAccount={handleUpdate}
      accounts={selectorList}
    />
  );
}

export default LinkToNewAccountContainer;
