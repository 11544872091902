import { Button } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

import * as React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as InjectedIntlProps
} from 'react-intl';
import ErrorsDialog from './ErrorsDialog';
import ErrorSnackbarContent from './ErrorSnackbarContent';
import getErrorMessages from './getErrorMessages';
import { ErrorDomain } from './types';
import TestIds from 'Tests/TestIds';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

// Not a fan of having this kind of utility in our code.
// We might want to think about our data structures or find a simple lib.
function omit(obj: { [key: string]: any }, omitKey: string) {
  return Object.keys(obj)
    .filter(key => key !== omitKey)
    .reduce((acc, key) => Object.assign(acc, { [key]: obj[key] }), {});
}

interface State {
  openDialog: string | null;
  errors: {
    [key: string]: string[];
  };
}

let uniqueKey = 0;
function generateKey(): string {
  const currentKey = uniqueKey;
  uniqueKey += 1;

  return String(currentKey);
}

class ErrorHandlerProvider extends React.Component<
  InjectedIntlProps,
  State
> {
  public readonly state: State = {
    openDialog: null,
    errors: {}
  };

  public handle = (messages: string[], domain: ErrorDomain | null = null) => {
    if (messages.length === 1) {
      // if there is only 1 message, print it
      enqueueSnackbar(
        <ErrorSnackbarContent domain={domain}>
          {messages[0]}
        </ErrorSnackbarContent>,
        {
          variant: 'error',
          persist: true
        }
      );
      return;
    }

    if (messages.length > 1) {
      // if there are more then 1 message, print a generic one
      // and offer to see more details in a dialog
      const key = generateKey();
      this.setState(
        currentState => ({
          errors: {
            ...currentState.errors,
            [key]: messages
          }
        }),
        () => {
          enqueueSnackbar(<FormattedMessage id="error.type.batch" />, {
            key,
            variant: 'error',
            persist: true,
            action: () => (
              <React.Fragment>
                <Button
                  data-testid={TestIds.common.snackbar.action.close}
                  onClick={() => {
                    // dismiss the errors and close
                    this.setState(
                      ({ errors }) => ({
                        errors: omit(errors, key)
                      }),
                      () => {
                        closeSnackbar(key);
                      }
                    );
                  }}>
                  <FormattedMessage id="common.action.agree" />
                </Button>
                <Button
                  data-testid={TestIds.common.snackbar.action.close}
                  onClick={() => {
                    this.setState(
                      ({ openDialog, errors }) => ({
                        openDialog: key,
                        // if a new dialog is open while one is already open,
                        // dismiss the previous errors
                        errors: openDialog ? omit(errors, openDialog) : errors
                      }),
                      () => {
                        closeSnackbar(key);
                      }
                    );
                  }}>
                  <FormattedMessage id="common.action.moreDetails" />
                </Button>
              </React.Fragment>
            )
          });
        }
      );
      return;
    }

    // the error is unknown, fall back to a generic message
    enqueueSnackbar(<FormattedMessage id="error.type.E500" />, {
      variant: 'error',
      persist: true
    });
  };

  public onError = (error: ApolloError, domain: ErrorDomain | null = null) => {
    const { intl } = this.props;
    const authError = error.graphQLErrors?.filter(
      e => e.extensions?.code === 'E401'
    );

    if (authError?.length > 0) return;

    const messages = error.networkError
      ? [intl.formatMessage({ id: 'error.type.network' })]
      : getErrorMessages((error.graphQLErrors || []) as any[]);

    this.handle(messages, domain);
  };

  public render() {
    const { openDialog, errors } = this.state;
    const { children } = this.props;

    return (
      <ErrorHandlerContext.Provider
        value={{ handle: this.handle, onError: this.onError }}
      >
        {children}
        {openDialog && errors[openDialog] && (
          <ErrorsDialog
            messages={errors[openDialog]}
            onClose={() => {
              this.setState(({ errors: currentErrors }) => ({
                openDialog: null,
                errors: omit(currentErrors, openDialog)
              }));
            }}
          />
        )}
      </ErrorHandlerContext.Provider>
    );
  }
}

const errorHandler = {
  Provider: injectIntl(ErrorHandlerProvider),
  Consumer: ErrorHandlerContext.Consumer
};

export default errorHandler;
