import { Theme } from "@mui/material";
import { CdsSizeRadius } from "Theme/constants/tokens";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    header: {
        color: theme.palette.common.white,
        // Same as Avatar's default background color so it matches with the default PersonAvatar
        backgroundColor: theme.palette.grey[400],
        padding: theme.spacing(4),
        borderRadius: `${CdsSizeRadius} ${CdsSizeRadius} 0 0`
    },
    headerGroupName: {
        marginBottom: theme.spacing(0.5)
    },
    headerAction:{
        textAlign: 'right'
    },
    headerSubTitle: {
        opacity: 0.6
    },
    body: {
        padding: theme.spacing(4)
    }
}))