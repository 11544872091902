import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  DialogActions,
  Button
} from '@mui/material';
import joinURL from 'url-join';
import { generatePath } from 'react-router-dom';
import TestIds from 'Tests/TestIds';
import { useStyles } from '../../../../Directories.styles';
import DirectoryMigrationHeader from '../../../../../../../../../Components/Shared/DirectoryMigrationHeader';
import { PrivateRoutes } from '../../../../../../../../../Router/Routes';
import useTranslate from 'Hooks/useTranslate';

export interface Props {
  openDialog: boolean;
  person: IPersonWithAccount;
  handleOpenDialog: (directoryToMerge: string) => void;
  directoryName: string;
}

let redirectUri: string;

function MergeDirectoryDialog(props: Props) {
  const { openDialog, handleOpenDialog, directoryName, person } = props;
  const ts = useTranslate();

  const classes = useStyles();

  switch (directoryName) {
    case 'Microsoft':
      redirectUri = generatePath(PrivateRoutes.mergeIdentity.path, {
        accountCode: person.account ? person.account.code : 'all',
        identityProvider: 'microsoft'
      });
      break;
    case 'Google':
      redirectUri = generatePath(PrivateRoutes.mergeIdentity.path, {
        accountCode: person.account ? person.account.code : 'all',
        identityProvider: 'google'
      });
      break;
    default:
    // will NOT execute because for now only 2 idp merge possible.
  }

  return (
    <Dialog
      open={openDialog}
      key="mergeGoogleDirectoryDialog"
      maxWidth="sm"
      data-testid={
        TestIds.pages.profile.mergeAccount.directoryDialog + directoryName
      }
    >
      <DialogContent>
        <DirectoryMigrationHeader directory={directoryName} />
        <Typography variant="h5" className={classes.title}>
          {ts('page.userProfile.beforeDirectoryMergeTitle', {
            dir: directoryName
          })}
        </Typography>
        <Typography variant="subtitle1" className={classes.customSubtitle1}>
          {ts('page.userProfile.beforeDirectoryMergeConditions', {
            dir: directoryName
          })}
        </Typography>
        <Typography variant="body2">
          {ts('page.userProfile.beforeDirectoryMergeText', {
            dir: directoryName
          })}
        </Typography>
        <Box height={8} />
        <Typography variant="body2">
          {ts('page.userProfile.mergeValidationText', { dir: directoryName })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleOpenDialog(directoryName)}
          data-testid={TestIds.common.snackbar.action.cancel} 
          color='inherit'>
          {ts('common.action.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid={TestIds.pages.profile.mergeAccount.understandButton}
          href={window.AppSettings.GRAPHQL_ENDPOINT_URI.replace(
            /GraphQL$/,
            `api/identitymerge/challenge?redirectUri=${encodeURIComponent(
              joinURL('/', redirectUri)
            )}&mergeIdentityProvider=${directoryName}`
          )}
        >
          {ts('common.action.understand')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MergeDirectoryDialog;
