import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorBackgroundPrimaryLight } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
    container :{
        background: `${CdsColorBackgroundPrimaryLight}`,
        overflow: 'hidden'
    },
    input: {
        display: 'flex',
          background: 'white',
          margin: '1em auto',
          width: '50%',
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            width: "80%",
         },
        [theme.breakpoints.down('sm')]: {
            width: "90%",
         },
    },
}));