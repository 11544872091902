import flat from 'flat';
import {
  AuditAction,
  AuditActionResult,
  ActivityType,
  PersonAccessRightStatus
} from '../Apollo';
import { ApiKeyEventType } from '../Apollo/fragments/apikey/ApiKeyEvent';
import TProfileManagerMessages from './TProfileManagerMessages';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';

export default flat<TProfileManagerMessages, { [messageId: string]: string }>({
  applicationName: 'Gestión de cuentas',
  common: {
    action: {
      loading: 'Cargando...',
      search: 'Búsqueda',
      subscribe: 'Suscribir',
      accept: 'Aceptar',
      confirm: 'Confirmar',
      deny: 'Rechazar',
      add: 'Añadir',
      delete: 'Eliminar',
      edit: 'Editar',
      back: 'volver',
      export: 'Exportar',
      finish: 'Acabado',
      previous: 'Anteriormente',
      next: 'próximo',
      cancel: 'Anular',
      reset: 'Reiniciar',
      toEnd: 'Acabado',
      agree: `De acuerdo`,
      retry: 'Recargar',
      understand: `Lo entiendo`,
      moreDetails: 'Más detalles',
      validate: 'Validar',
      migrate: 'Migrar',
      open: 'Abrir',
      close: 'Cerrar',
      ignore: 'Ignorar',
      clear: 'Borrar búsqueda',
      yes: 'Sí',
      no: 'No',
      ok: 'Ok',
      backToHomePage: 'Volver a la página inicial',
      activate: 'Activar',
      deactivate: 'Desactivar',
      doNotModify: 'No modificar',
      seeProfile: 'Ver perfil',
      login: 'Conectarse',
      copyCode: 'Copiar el código',
      see: {
        seeMore: 'Ver más',
        seeDescription: 'Ver descripción',
        seeExpirationDate: 'Ver fecha de expiración',
        seeGroupDetails: 'Ver detalles del grupo',
      }
    },
    date: {
      editExpirationDate: 'Editar la fecha de expiración',
      expireDetails: 'Expirará el {date} - {hour}.',
    },
    person: {
      role: {
        adm: 'Administrador',
        superAdm: 'Super Administrador',
        superAdmSentence: 'Navega como super administrador',
        superReaderSentence: 'Navega como super lector',
        admSuperReader: 'Administrador / Super-lector',
        reader: 'Lector',
        superReader: 'Super-lector',
        user: 'Usuario'
      }
    },
    service: {
      unavailable: 'Servicio no disponible'
    },
    terminology: {
      alert: {
        info: 'Information'
      },
      client: 'Cuenta cliente',
      status: 'Estado',
      active: 'Activo',
      inactive: 'Inactivo',
      account: 'Cuenta',
      accounts: 'cuentas',
      description: 'Descripción',
      codeGroup: 'Código de grupo',
      famillies: 'familias',
      groups: 'grupos',
      localAccount: 'Cuenta local',
      menu: 'Menú',
      MSaccount: 'Cuenta Microsoft',
      GoogleAccount: 'Cuenta Google',
      required: 'Obligatorio',
      services: 'servicios',
      tutorial: 'Tutorial',
      immutableId: 'Id inmutable',
      email: 'Dirección electrónica',
      or: 'o',
      groupName: 'Nombre del grupo',
    },
    dates: {
      creationDate: 'Fecha de creación : {value}',
      today: 'Hoy',
      yesterday: 'Ayer',
      previously: 'Anteriormente',
      thisInstant: 'Hace 0 segundos',
      jours: 'días',
    },
    label: {
      id: 'ID : ',
      page: 'Página',
      rowPerPage: 'Resultados / página',
      name: 'Nombre',
    },
    pagination: {
      firstPage: 'Primera página',
      lastPage: 'Última página',
      previousPage: 'Página anterior',
      nextPage: 'Página siguiente',
      displayedRows: '{from}-{to} de {count}',
    },
    xhrMaxItemCountReached:
      'Actualmente, no puede seleccionar más de {XhrMaxItemCount} usuarios.',
    welcome: 'Bienvenido,',
    accountLabel: '{name} - {zipCode} {city} / {code}',
    switchAccount: 'Cambiar la cuenta del cliente',
    disabledAccount: '(Desactivado)',
    tableHead: {
      name: 'Nombre',
      status: 'Estado',
      code: 'Código',
      validationCode: 'Código de validación',
      expiration: 'Expiración',
    }
  },
  component: {
    modals: {
      createUser: {
        heading: 'Invitar usuarios',
        submit: 'Inivitar',
        wizard: {
          heading: 'Tome el control de sus usuarios',
          subheading: `Personalice y administre fácilmente los datos, suscripciones y grupos de los usuarios.`
        },
        labels: {
          emails: 'Correos de los usuarios',
          emailsHelperText:
            'Agrega varios usuarios utilizando comas. Máx. 100 usuarios.',
          accountCode: 'Cuenta cliente'
        },
        invitationSent:
          'Has eviado {invitationsQuantity, plural, zero {{invitationsQuantity} invitación } one {{invitationsQuantity} invitación} other {{invitationsQuantity} invitaciones}}',
        invitationFailed: 'Las siguientes invitaciones no pudieron ser creadas:'
      },
    },
    form: {
      placeholder: {
        emailsInput: 'Ej: nombre@empresa.com, nombre1@empresa.com'
      },
      label: {
        emailInputCount: 'Total de mails por agregar: {emailsCount}'
      }
    },
    accessrights: {
      pendingRequest: {
        count: `{value, plural, zero { {value} solicitud } one { {value} solicitud } other { {value} solicitudes }} `,
        sentence: `{value, plural, zero { {value} solicitud } one { {value} solicitud } other { {value} solicitudes }} en espera de validación.`
      }
    },
    administratedAccountsSelector: {
      allAccounts: 'Todas las cuentas',
      seeAll: 'Ver todas las cuentas'
    },
    searchBars: {
      availabledResult: {
        noResultPrimary: 'No hay resultados que coincidan con su entrada',
        noResultSecondary:
          'Encuentre un usuario por su nombre, apellido o correo electrónico.'
      },
      userSelectedSearchBar: {
        sentence: `{total, plural, one {{total} solicitud que coincide con su búsqueda} other {{total} solicitudes que coinciden con su búsqueda}}`
      },
      placeholder: {
        users: 'Buscar usuarios',
        services: 'Buscar servicios'
      }
    },
    table: {
      selectAll: 'Seleccionar todo ({count})',
      elementSelected: '{count} elemento(s) seleccionado(s)'
    },
    tableServiceOwners: {
      remove: {
        confirmation:
          'Está a punto de eliminar al propietario {name} del servicio',
        success: 'El usuario ya no es propietario del servicio'
      }
    },
    editToggle: {
      editGroup: {
        update: {
          success: 'El grupo ha sido actualizado'
        }
      }
    },
    userAccountUpdate: {
      tooltip: 'Modificar el tercero del usuario',
      unlink: `Desasociar del tercero {value}`,
      link: 'Asociar a otro tercero',
      unlinkDialogTitle: 'Estás a punto de desvincular el usuario del tercero',
      irreversibleAction: 'Esta acción es irreversible. ¿Desea continuar?',
      linkDialogTitle: 'Estás a punto de modificar el tercero asociado a este usuario',
      whichNewAccount: '¿A qué cuenta desea vincular el usuario?',
      chooseAccount: 'Cuenta principal',
      lostAccessCount: `El usuario perderá acceso a {value, plural, one {{value} servicio} other {{value} servicios}}. `,
      inheritedServicesFromGroup: ` - (servicio heredado del grupo {value})`,
      alertInfo: {
        previousMembersList: `El usuario ya no aparecerá en la lista de usuarios del tercero {value}.`,
        previousAccountAccessRights: `El usuario perderá acceso a los siguientes servicios:`,
        previousExternalisableAccessRights: `Si el usuario todavía tiene acceso a un servicio externalizable de {value} antes de su confirmación, este se conservará.`,
        newAccountMembersList: `El usuario aparecerá en los miembros del tercero {value}.`
      },
      alertWarning: {
        retainedAccessRights: `Se conservarán los siguientes accesos:`,
        unlinkGroups: `El usuario será desvinculado de todos los grupos del tercero.`,
        alertWarningTitle: 'Atención',
        noMoreAccessToProfile: `Ya no podrás acceder a este perfil de usuario una vez que se desvincule del tercero {value}.`,
        removeAllAccessWarning: `Si desea retirar, total o parcialmente, los accesos del usuario {value} a los servicios mencionados anteriormente, asegúrese de eliminarlos antes de desvincularlo del tercero. Puede realizar esta acción desde su lista de `,
        awareness: 'Esta acción es delicada. Por favor, tenga precaución antes de confirmar.'
      },
      switchAccount: {
        newAffiliationAccount: `Nuevo tercero de vinculación`,
        dialogTitle: `Estás a punto de modificar el tercero asociado a este usuario. ¿Deseas continuar?`,
        newInheritedServices: `El usuario obtendrá automáticamente acceso a los servicios predeterminados de este tercero.`,
        servicesListLink: `Ver la lista de servicios`,
        alertInfoTitle: `Para el nuevo tercero de vinculación`
      },
      accessToProfileFromNewAccount: `Podrás acceder a este perfil de usuario desde este tercero.`,
      forSuperUserOnly: {
        newAccessToProfile: `Podrás acceder a este perfil de usuario desde la lista de todos `,
        administratedUsers: `los usuarios que administras`
      },
      mutation: {
        linkToNewAccountSuccess: `El usuario ha sido vinculado con éxito al tercero {value}.`,
        unLinkSuccess: `El usuario ha sido desvinculado con éxito del tercero {value}.`
      }
    }
  },
  selectorList: {
    ipRangeType: {
      persons: 'Usuario',
      apiKey: 'Api Key'
    }
  },
  error: {
    noAccountSelected: "Ninguna cuenta de cliente seleccionada",
    selectAnAccount: 'Seleccione previamente a un tercero para ver los detalles de este servicio.',
    somethingHappened: 'Se ha producido un error',
    comeBackLater: 'Vuelve a intentar luego',
    oops: `Uy.`,
    copyText: 'Copiar el texto',
    textCopied: 'Texto copiado',
    details: 'Detalles de los errores',
    noDataAvailable: '¡No hay datos disponibles!',
    infoWillBeDisplayedHere: 'Su información se mostrará aquí.',
    form: {
      invalidEmail: 'Esta dirección de correo electrónico no es válida',
      invalidEmailsLength: 'El número máximo de correos electrónicos es 100.',
      emailsDuplication: 'Hay al menos un correo electrónico duplicado.',
      invalidIp: 'El formato es inválido',
      invalidIpRange: 'El rango IP es inválido',
      requiredField: 'El campo es obligatorio',
      min: `El valor mínimo es {value}`,
      max: `El valor máximos es {value}`,
      minLength: `Mínimo {value} son permitidos`,
      maxLength: `Máximo {value} son permitidos`
    },
    type: {
      signin: 'Se ha producido un error durante su intento de conexión.',
      activation: `Error de activación`,
      requiredField: `El campo es obligatorio.`,
      userAlreadyExists: `El usuario ya existe.`,
      network: `Se ha producido un error de red. ¿Está conectado a Internet?`,
      batch: `La acción se interrumpió debido a varios errores.`,
      E101: `El usuario ya existe en este tercero.`,
      E104: `Múltiples usuarios con esta configuración.`,
      E121: `No está permitido borrar tu propio perfil.`,
      E123: `Ya existe una cuenta con esta dirección de correo electrónico.`,
      E125: `La dirección de correo electrónico es obligatoria.`,
      E126: `No está permitido eliminar el último usuario de una cuenta.`,
      E139: `No puede eliminar este perfil.`,
      E145: `El dominio de correo electrónico está vinculado a un directorio federado.`,
      E247: `No se permite el borrado masivo.`,
      E403: `Acceso denegado`,
      E404: 'Página no encontrada',
      E500: `Se ha producido un error, inténtelo de nuevo más tarde.`,
      HC0045: `La solicitud esta siendo procesada. Por favor verifique el resultado de su operación en unos minutos.`,
      invalidDateFormat: 'El formato de la fecha no es válido',
      superUserAccessRefused: `No tienes acceso de superusuario`
    },
    sentence: {
      emailChange: 'El email no ha sido modificado',
      signin: 'Se ha producido un error durante su intento de conexión',
      activation: `Su enlace de activación ha caducado o su perfil ya está activado.`,
      E403: `No está autorizado a acceder a este contenido.`,
      E404: `No se puede encontrar la página solicitada.`
    },
    illustrationAlt: {
      ballons: 'Ilustración de una casa de papel que vuela gracias a globos'
    }
  },
  menu: {
    activities: 'Actividades',
    apiKey: `Claves API`,
    dashboard: 'Tablero de mandos',
    groups: 'Grupos',
    management: 'Gestión',
    settings: 'Preferencias',
    notifications: 'Notificaciones',
    services: 'Servicios',
    accessRights: 'Solicitudes de subscripción',
    users: 'Usuarios',
    validateRequest: 'Validar una solicitud',
    profileManager: `Volver a la versión anterior`,
    profileManagerWarning: 'Fin en marzo de 2024.',
    profile: 'Perfil',
    shortcuts: 'Atajos',
    addUser: 'Invitar usuarios',
    addGroup: 'Añadir un grupo',
    organizations: 'Organizaciones'
  },
  myMenu: {
    profile: 'Perfil',
    logout: 'Cerrar sesión'
  },
  banner: {
    pendingRequest: `Su delegación temporal de la administración termina en {hours}h {minutes}mn`,
    bannerText: `Solicitar una delegación temporal de la administración a un administrador de cuentas.`,
    buttonText: `Solicitar una delegación de administración`,
    accessRightRequestDialog: {
      title: `Solicitar acceso a un administrador`,
      noAdministratorYet:
        'Esta cuenta aún no tiene administrador. Póngase en contacto con el servicio de asistencia.',
      text: `Introduzca la dirección de correo electrónico de un administrador de la cuenta de cliente para realizar cambios. Enviaremos un correo electrónico de confirmación al administrador para validar su solicitud.`,
      subtext: `El correo electrónico contiene un enlace de validación válido durante 5 minutos. Una vez aceptada su solicitud, tendrá acceso a la administración de la cuenta durante 2 horas.`,
      sendEmail: `Enviar correo electrónico`,
      pendingRequest: `Se ha enviado un mensaje al administrador para validar la solicitud de delegación.`,
      pendingRequestInfo: `El correo electrónico enviado al administrador de la cuenta contiene un enlace que es válido durante 5 minutos. Este enlace le permite aceptar su solicitud. Tras la validación de su solicitud, se le concederá un acceso temporal a la administración de la cuenta durante un periodo de 2 horas.
      Se ha enviado un mensaje al administrador para validar la solicitud de delegación.`
    }
  },
  delegation: {
    success: `Los derechos de administración se han concedido con éxito.`,
    error: `No se han podido añadir derechos de administración. Póngase en contacto con el servicio de asistencia.`
  },
  onBoarding: {
    step1H5: 'Hemos actualizado la gestión de la cuenta.',
    step1Subtitle1: 'Descubramos algunas características de la nueva versión.',
    step2H5: 'Simplemente vea la información importante',
    step2Subtitle1:
      'Un panel de resumen le permite ver la información principal de las cuentas que administra.',
    step3H5: 'Gestione más fácilmente las suscripciones de sus usuarios.',
    step3Subtitle1:
      'Las listas le permiten seleccionar fácilmente varios usuarios y servicios para abrir rápidamente el acceso a sus usuarios.'
  },
  page: {
    accountProfile: {
      title: 'Cuenta {value}',
      youConsult: 'Está consultando la cuenta de cliente',
      aboutTitle: 'Información administrativa',
      membersSection: {
        membersTitle: 'Miembros',
        members: 'Usuarios',
        seeAllMembers: 'Ver todos los usuarios',
        notificationSettings: 'Configuración de notificaciones',
        notificationSettingsTooltip: 'Los usuarios reciben una notificación por correo electrónico cuando se otorga el acceso a un servicio.'
      },
      sections: {
        administrators: {
          title: 'Administradores',
          numberOfAdministrators: `Número de administradores`,
          seeAllAdministrators: 'Ver todos los administradores'
        },
        about: {
          accountName: 'Nombre de la cuenta',
          accountCode: 'Código'
        },
        services: {
          servicesCatalog: 'Catálogo de servicios',
          seeCatalog: 'Ver el catálogo'
        },
        apikeys: {
          title: 'Claves API',
          activedApiKeys: 'Claves API activas',
          seeAll: 'Ver todas las claves API'
        },
        groups: {
          title: 'Grupos',
          seeAllGroups: 'Ver todos los grupos'
        },
        pendingRequests: {
          title: 'Solicitudes de subscripción',
          manageRequests: 'Gestionar las solicitudes'
        }
      }
    },
    apiKeyProfile: {
      info: {
        title: `Nombre de la clave API : `,
        subtitle: `ID : {value}`
      },
      technicalInformations: 'Información técnica',
      contact: 'Contacto',
      creator: 'Creador :',
      creationDate: 'Fecha de creación :',
      revocationDate: 'Fecha de revocación :',
      relatedServices: 'Servicio asociado',
      noRelatedServices: 'No servicio asociado a la clave API',
      service: 'servicio :',
      tiers: 'Tercero :',
      activities: 'Actividades',
      allActivities: 'Ver todas las actividades'
    },
    apikeyProfile: {
      menu: {
        general: 'General',
        audit: 'Actividades'
      },
      title: `Clave API {uuid}`,
      activities: {
        title: 'Actividades',
        type: `Tipo`,
        date: `Fecha`,
        author: `Autor`
      },
      activity: {
        type: `{value, select, ${ApiKeyEventType.Creation} {Creación} ${ApiKeyEventType.AccessGranted} {Acceso autorizado} ${ApiKeyEventType.Revocation} {Revocación} ${ApiKeyEventType.AccessDenied} {Acceso denegado}  other {-}}`,
        emptyStateTitle: `No hay historial para esta clave API.`,
        emptyStateText: `Los eventos futuros aparecerán aquí.`
      }
    },
    changeEmailValidation: {
      title: 'Validacion del cambio de email',
      subtitle: 'Tu direccion de correo ahora es {email}',
      goToCegid: 'Ir a  Cegid Account'
    },
    accessRights: {
      newRequests: 'Nuevas solicitudes de suscripción',
      title: 'Peticiones de subscripcion de servicios',
      subtitle:
        'Consulta y administra todas las peticiones de subscripcion de servicios de los usuarios',
      empty: {
        title: 'Sin solicitudes de suscripción',
        subtitle: 'Las próximas solicitudes de suscripción aparecerán aquí.'
      },
      acceptRequestSuccess: 'Acaba de aceptar la solicitud',
      denyRequestSuccess: `Acaba de denegar la solicitud`,
      updateRequestStatusSuccess: `La aplicación ha sido actualizada`,
      expirationDate: `Fecha de expiración: `,
      defineExpirationDate: `Fecha límite`,
      changeExpirationDate: `Editar`,
      cancelExpirationDate: `Cancelar`
    },
    accountSelection: {
      title: 'Selección de cuentas de clientes',
      whichAccount: '¿En qué <b>cuenta de cliente</b> le gustaría trabajar?',
      accountsFound: `{count, plural, zero {Se ha encontrado {count, number} tercio} one {Se ha encontrado {count, number} tercio} other {Se han encontrado {count, number} tercios}}`,
      accountLabel: 'Código de tercero',
      loginToAccount: 'Acceder a la cuenta',
      noAccountsFound: 'No se ha encontrado ninguna cuenta'
    },
    administrators: {
      title: 'Administradores',
      index: {
        administrators: 'Administradores',
        email: 'Dirección electrónica',
        allStatus: 'Estado',
        language: 'Idioma'
      },
      accountAdministrators: 'Administradores de cuenta',
      emptyState: {
        title: 'No hay administradores de cuenta',
        description: 'Los administradores futuros aparecerán aquí.'
      }
    },
    apiKeys: {
      title: `Claves API`,
      addApikeyTooltip:
        'Una clave API está asociada a un servicio y da derecho de acceso a un programa.',
      requireAccountSelectionTooltip: `Seleccione primero una cuenta para generar una clave API.`,
      refreshTooltip: 'Actualizar la lista de claves API',
      apikeyHistory: `Actividad de la clave {nombre}`,
      noApiKey: `Sin clave API`,
      futureApiKeysText: `Las futuras claves API generadas aparecerán aquí.`,
      creator: `Creador : {fullName}`,
      undefinedCreator: 'Creador desconocido',
      undefinedCreationDate: 'Fecha de creación desconocida',
      revoke: 'Revocar',
      details: 'Detalles',
      history: 'Actividad',
      revocationConfirmationApiKeyName: `Revocarás la clave API `,
      revocationConfirmationText: `. Esta acción será definitiva. ¿Quieres continuar?`,
      revocationSuccessMessage: `La clave api {value} ha sido revocada con éxito.`,
      revokedTitle: 'Revocadas',
      activeTitle: 'Activas',
      generate: {
        apiKey: `Generar una clave API`,
        theApiKey: `Generar la clave API`,
        selectService: `Seleccione un servicio`,
        enterNewApiKeyName: `Introduzca el nombre de una nueva clave`,
        apiKeyNameAlreadyExist: `Este servicio ya tiene una clave API con el mismo nombre.`,
        mandatoryService: `Debe elegir un servicio.`,
        mandatoryName: `Debe introducir un nombre para esta nueva clave.`
      },
      success: {
        title: 'Clave API generada con éxito',
        yourApiKey: `Su clave API`,
        apiKeySecret: `Secreto de la clave API`,
        warning: `El secreto de la clave API generada no podrá volver a mostrarse.`
      }
    },
    createPersonGroup: {
      title: 'Añadir un grupo',
      heading: 'Gestione sus usuarios más fácilmente',
      subheading:
        'Puede clasificar a los usuarios de su empresa en grupos. Los servicios asociados a los grupos se añadirán automáticamente a cada usuario miembro del grupo.',
      stepInformation: {
        name: 'Nombre del grupo',
        description: 'Descripción',
        accountCode: 'Cuenta de terceros'
      },
      success: `El grupo {groupName} ha sido añadido con éxito`,
      groupAdded: '• Se ha agregado un grupo.'
    },
    dashBoard: {
      title: 'Tablero de mandos',
      index: {
        accountList: 'Lista de terceros',
        administrators: 'Administradores',
        seeTheList: 'Ver la lista',
        initializing: 'Iniciación',
        subscriptionRequests: {
          title: 'Nuevas solicitudes de subscription a servicios',
          noPendingRequests:
            'No hay solicitues de subscription a servicios pendientes',
          seeMore: 'Ver más'
        },
        totalAdministrators: `{count, plural, one {{count, number} elemento} other {{count, number} elementos}}`
      },
      disabledUser: `Este usuario ya no existe`
    },
    groupProfile: {
      title: 'Grupo {name}',
      youConsultGroup: 'Está consultando al grupo',
      information: {
        title: 'Información'
      },
      removeGroup: {
        removeGroupConfirmation: `Está a punto de eliminar el grupo {groupName}. Los miembros actuales del grupo perderán su acceso (a través del grupo) a los servicios asociados. ¿Desea continuar?`,
        removeSuccess: `El groupo  {groupName} ha sido eliminado con éxito.`
      },
      creationBy: ' por {creator}',
      editProfile: {
        title: 'Editar el grupo',
        name: 'Nombre',
        description: 'Descripción'
      },
      services: {
        subscription: {
          removeConfirmation: `Esta acción eliminará el servicio {serviceName} del grupo {groupName}`,
          addServicesTitle: `Añadir uno o varios servicios`,
          deleteMessage: 'El servicio ha sido eliminado del grupo.',
          successMessage: `{count, plural, one {{count, number} se ha añadido al grupo.} other {{count, number} servicios se han añadido al grupo.}} `
        },
        emptyState: {
          title: 'No hay servicios asociados.',
          description: 'Los servicios futuros aparecerán aquí.'
        }
      },
      addServices: {
        emptyState: {
          title: 'La lista está vacía',
          description: 'No hay servicios disponibles'
        },
        selection:
          '{count, plural, one {{count, number} servicio ha sido seleccionado} other {{count, number} servicios han sido seleccionados}}',
        completedSuccess:
          'Usted ha añadido {count, plural, one {{count, number} servicio} other {{count, number} servicios}}.'
      },
      members: {
        add: 'Añadir un miembro',
        removeDialogTitle: 'Confirmación de la supresión',
        removeMemberConfirmation: `Usted va a eliminar al usuario {fullName} del grupo {groupName}`,
        removeSuccess: `El usuario ha sido eliminado del grupo.`,
        pageAllSelected:
          '{count, plural, one {{count, number} miembro ha sido seleccionado} other {{count, number} miembros han sido seleccionados}} en esta página.',
        selectAll:
          'Seleccione {count, plural, one {{count, number} miembro} other {{count, number} miembros}}',
        maxSelectedExceeded:
          'Su selección excede el límite máximo de {count, plural, one {{count, number} miembro} other {{count, number} miembros}}. Por favor, seleccione menos.',
        emptyState: {
          title: 'No hay miembros asociados.',
          description: 'Los miembros futuros aparecerán aquí.'
        },
        addMembers: {
          emptyState: {
            title: 'Ningún candidato de grupo',
            description: 'No hay miembros disponibles'
          },
          completedSuccess:
            'Usted ha añadido {count, plural, one {{count, number} miembro} other {{count, number} miembros}}.',
          selection:
            '{count, plural, one {{count, number} miembro ha sido seleccionado} other {{count, number} miembros han sido seleccionados}}'
        }
      }
    },
    groups: {
      title: 'Grupos',
      addGroupTooltip:
        'Crear grupos para gestionar los derechos de acceso de un grupo de usuarios',
      help:
        'El grupo reúne a un conjunto de usuarios de un conjunto de servicios.',
      index: {
        group: 'Grupo',
        account: 'Cuenta'
      },
      emptyState: {
        title: 'No se ha creado ningún grupo',
        description: 'Comience por crear un nuevo grupo',
        superReaderDescription: 'Aquí aparecerán los grupos creados.'
      }
    },
    notifications: {
      title: 'Notificaciones',
      help:
        'Gestione los correos electrónicos que usted y sus colegas desean recibir.',
      subtitle: 'Seleccione los criterios que le convengan.',
      email: 'Dirección electrónica',
      newAccessRightsTitle: `Añadir un servicio`,
      newAccessRightsDescription: `Permite elegir si se envía o no un correo electrónico a los usuarios cuando se suscriben a un nuevo servicio.`,
      successfullySaved: `Sus preferencias de notificación se han actualizado correctamente para {account}.`
    },
    organizationProfile: {
      pageTitle: 'Organizacion {value}',
      youConsultSentence: 'Está consultando la organización',
      uniqueIdentifier: 'Identificador único',
      aboutTitle: 'Información administrativa',
      creationDate: 'Fecha de creación',
      seeAllDomains: 'Ver los dominios',
      menu: {
        general: 'General',
        domains: 'Dominios'
      },
      countDomains: 'Dominios gestionados',
    },
    organizations: {
      title: 'Organizaciones',
      noOrganizationFound: 'No se ha encontrado ninguna organización',
    },
    organizationDomains: {
      title: 'Dominios de la organización {value}',
      uniqueIdentifier: 'Identificador único {value}',
      domains: 'Dominios',
      statusType: `{value, select, ${DomainValidationMode.Strong} {Fuerte} ${DomainValidationMode.None} {Débil} ${DomainValidationMode.Weak} {Débil}}`,
      emptyState: 'No se ha encontrado ningún dominio',
      startAddingDomain: 'Empiece por añadir un dominio',
    },
    services: {
      title: 'Servicios',
      tableTitle: `Catálogo de servicios`,
      help: `Su catálogo de servicios reúne todas las aplicaciones que están vinculadas a su contrato Cegid. Desde esta tabla, puede modificar las propiedades de acceso a estos servicios, otorgar derechos de acceso a sus usuarios sobre una o varias aplicaciones, delegar la administración de un servicio y consultar sus detalles.`,
      index: {
        family: 'Familias',
        selectedServiceCount: `{count, plural, zero {{count, number} servicio seleccionado} one {{count, number} servicio seleccionado} other {{count, number} servicios seleccionados}}`,
        accountDistribution: '{label} • {serviceCount, number} servicios',
        fromAccount: ' (desde {accountSubscriber})',
        visibleServices: 'Servicios visibles • {value}',
        notVisibleServices: 'Servicios no visibles • {value}'
      },
      action: {
        subscribeExternalUser: 'Suscribir a un usuario externo',
        subscribeExternalOwner: 'Agregar un administrador externo',
        updateServicesProperties: 'Cambiar las propiedades del servicio',
        updateServicesPropertiesSuccess: 'Las propiedades del servicio han sido actualizadas'
      },
      createSubscription: {
        title: 'Suscribir un usuario',
        stepEmail: {
          email: 'Dirección electrónica',
          invalidEmail: 'Esta dirección de correo electrónico no es válida.',
          checkEmail: `Comprobar la dirección de correo electrónico`,
          userDoesNotExist: `Este usuario no existe.`
        },
        stepInfo: {
          checkInfo: 'Compruebe la información'
        }
      },
      updateProperties: {
        title: 'Editar los servicios seleccionados'
      },
      service: {
        availability: 'Disponibilidad',
        info: 'Información'
      }
    },
    servicesIpRestrictions: {
      index: {
        title: 'Añadir un rango IP autorizado',
        tableTitle: 'Rangos IPs autorizados',
        type: 'Tipo',
        description: 'Descripción',
        startIp: 'Ip Inicial',
        endIp: 'Ip Final',
        enabled: 'Activo',
        disabled: 'Inactivo',
        successAdd: 'El rango Ip ha sido creado',
        successRemove: 'El rango Ip ha sido eliminado',
        successUpdate: 'El rango Ip ha sido actualizado'
      }
    },
    servicesMembers: {
      tableTitle: 'Usuarios con acceso al servicio',
      emptyTitle: 'Ningún usuario tiene acceso al servicio',
      noAvailableUserTitle: 'Ningún usuario puede ser agregado al servicio',
      invite: 'Invitar',
      invitationResult: {
        external: 'El usuario ha sido invitado al servicio.',
        existing: 'Los usuarios han sido suscritos al servicio.'
      },
      groups: {
        tableTitle: 'Grupos con acceso al servicio',
        emptyTitle: 'Ningún grupos tiene acceso al servicio',
        noAvailableGroupTitle: 'No se pueden agregar grupos al servicio',
        successAdd: 'El grupo ha sido agregado al servicio'
      }
    },
    externalserviceProfile: {
      title: `Información sobre su servicio:`,
      details: `{service} en nombre de {targetAccount} `
    },
    serviceProfile: {
      serviceMembers: {
        add: 'Añadir un miembro',
        expirationDate: 'Fecha de caducidad',
        removeExpiration: 'Borrar la fecha de caducidad',
        changeExpirationDate: `Editar o establecer una fecha de caducidad`,
        editExpirationDate: 'Editar la fecha de caducidad',
        expirationDateAlert: {
          infoSentence1:
            'El acceso al servicio ya no estará limitado en el tiempo para el usuario o usuarios seleccionados.',
          infoSentence2: `El acceso al servicio para los usuarios seleccionados expirará el: {date}.`
        },
        tableTitle: 'Usuarios con acceso al servicio',
        emptyTitle: 'Ningún usuario tiene acceso al servicio',
        giveAccess: 'Dar acceso al servicio',
        invite: 'Invitar',
        removeAccess: 'Eliminar el acceso al servicio',
        removeOneOrMoreAccess:
          'Eliminar {count, plural, one {el acceso} other {los accesos}} al servicio',
        confirmRemove:
          'Está seguro de que desea eliminar el acceso de {count} usuario(s)?',
        updateServiceEndDateForOneOrManyUser: `Elija la fecha de finalización del servicio para {count, plural, one {el usuario seleccionado} other { los {count, number} usuarios seleccionados}}`,
        invitationResult: {
          external: 'El usuario ha sido invitado al servicio.',
          existing: 'Los usuarios han sido suscritos al servicio.'
        },
        manageAccess: 'Gestionar el acceso',
        remove: 'Eliminar',
        updateEndDateResult: {
          one:
            'El acceso de este usuario a este servicio ha sido actualizado correctamente',
          many: 'Los accesos se han sido actualizado correctamente'
        },
        removeResult: {
          one:
            'El acceso de este usuario a este servicio se ha eliminado correctamente',
          many: 'Los accesos se han eliminado correctamente'
        },
        groups: {
          tableTitle: 'Grupos con acceso al servicio',
          emptyTitle: 'Ningún grupos tiene acceso al servicio',
          noAvailableGroupTitle: 'No se pueden agregar grupos al servicio',
          successAdd: 'El grupo ha sido agregado al servicio'
        }
      },
      title: `Servicio - {service}`,
      youConsultService: `Está consultando el servicio`,
      info: {
        title: `Información sobre su servicio: {value}`,
        defaultService: `Suscripción automática por defecto para todos los usuarios`,
        adminValidation: `La suscripción al servicio está sujeta a la validación de un administrador`
      },
      label: {
        defaultService: `Subscripción automática`,
        adminValidation: `Validación administración`,
        serviceCode: `Código del servico`
      },
      aboutService: `Acerca del servicio`,
      security: {
        formTitle: `Permitir un rango de IP`,
        formSubTitle: `{serviceName} / {accountName}`,
        descriptionPlaceholder: 'Escribe la descripción',
        heading: 'Opción de seguridad',
        subheading: `La opción de seguridad permite restringir el acceso a un servicio a uno o varios rangos de IP autorizados.`,
        addRange: 'Agregar rango Ip',
        emptyTitle: 'No hay ninguna restricción',
        emptySubtitle: 'Utilise el formulario para agregar restricciones'
      },
      subscribers: 'Suscriptores',
      owners: {
        ownersTitle: 'Propietarios',
        help: `Un propietario de servicio puede gestionar el servicio, añadir o eliminar miembros y actualizar las propiedades del servicio.`,
        emptyTitle: `Este servicio aún no tiene propietario`,
        owner: 'Propietarios',
        add: 'Agregar un propietario',
        sucess: 'Has agregado {ownersCount? 200: 100} propietarios al servicio'
      }
    },
    userProfile: {
      title: 'Perfil de {fullName}',
      yourProfile: 'Su perfil',
      accountHelp: `Número de cliente Cegid`,
      acceptedServices: {
        help: 'Derechos de acceso autorizados para este usuario.',
        updateServiceEndDateForUser: `Elija la fecha de finalización del servicio para el usuario seleccionado`,
      },
      deniedServices: {
        help:
          'Estas solicitudes de derechos de acceso han sido denegadas por un administrador. Para obtener más información, póngase en contacto con su administrador.'
      },
      suspendedServices: {
        help:
          'Estos derechos de acceso fueron suspendidos por un administrador.Para más información, póngase en contacto con su administrador.'
      },
      pendingServices: {
        help:
          'Estas solicitudes de derechos de acceso están pendientes de una validación del administrador.'
      },
      accountDisabledAlert: {
        title: 'La cuenta deshabilitada',
        message: 'Lo sentimos, esta cuenta no se encuentra activa.'
      },
      section: {
        account: {
          title: 'Cuenta'
        },
        administrators: {
          title: 'Administradores'
        },
        directories: {
          title: 'Directorios'
        },
        personal: {
          title: 'Información personal',
          fullname: 'Nombre',
          password: 'Contraseña',
          securityLevel: 'Nivel de protección de la contraseña : ',
          email: 'Dirección electrónica',
          pendingEmailRequest: `Solicitud de cambio de dirección de correo electrónico para: {value}`,
          phone: 'Teléfono',
          status: {
            activated: `Activado`,
            created: `Creado`,
            toActivate: `Para ser activado`,
            disabled: `Fuera de`
          }
        },
        technical: {
          technicalInformation: 'Información técnica',
          immutableId: 'Immutable Id',
          identifier: 'Identificador: ',
          identityIdentifier: 'Identificador de identidad: ',
          tenantId: 'Tenant ID: ',
          objectId: 'Object ID: ',
          upn: 'UPN: '
        }
      },
      personalInformations: {
        activatedServices: `{value, plural, one {Servicio activado} other {Servicios habilitados} }`,
        pendingRequest: `{value, plural, zero {Solicitud en espera de validación} one {Solicitud en espera de validación} other {Solicitudes en espera de validación} } `,
        cancelRequest: 'Cancelar la solicitud',
        cancelRequestConfirmationTitle: `Confirmación de anulación`,
        cancelRequestConfirmationText: `¿Está seguro de que desea cancelar la solicitud de cambio de correo electrónico actual?`,
        cancelRequestSuccess: `La solicitud de cambio de dirección de correo electrónico ha sido cancelada`,
        renewRequest: `Reenviar el correo`,
        resendRequestSuccess:
          'Se ha enviado un correo electrónico a esta dirección para su confirmación.',
        activatedSince: 'Activo desde',
        userNotActivated: `A la espera de la activación`,
        seeAllServices: 'Ver todos los servicios activados'
      },
      lastActivities: {
        title: 'Actividades recientes',
        activity: 'Actividad',
        result: 'Resultado',
        date: 'Fecha',
        activityType: `{value, select, ${AuditAction.Signin} {Conexión} ${AuditAction.ProfileEdited} {Perfil editado} ${AuditAction.PersonStatusChanged} {Estado modificado} ${AuditAction.MigrationToMicrosoft} {Migración a Microsoft} ${AuditAction.PersonGroupCreation} {Grupo creado} other {-}}`,
        activityResult: `{value, select, ${AuditActionResult.Disabled} {Perfil desactivado} ${AuditActionResult.SigninSucceeded} {Éxito} ${AuditActionResult.PersonCreated} {Perfil creado} ${AuditActionResult.SigninFailed} {Varado} ${AuditActionResult.PersonDisabled} {Perfil desactivado} ${AuditActionResult.PersonActivated} {Perfil activado} ${AuditActionResult.PersonToActivate} {A la espera de la activación} ${AuditActionResult.AuthenticationSucceeded} {Éxito} ${AuditActionResult.Activated} {Activado} other {-}}`,
        allActivities: 'Ver todas las actividades'
      },
      activities: {
        title: 'Actividades',
        filterLabel: 'Actividad',
        activityType: `{value, select, ${ActivityType.All} {Todo} ${ActivityType.Creation} {Creación} ${ActivityType.Update} {Modificación} ${ActivityType.Connection} {Conexión} ${AuditAction.PersonGroupCreation} {Grupo creado} other {-}}`,
        emptyStateTitle: 'No hay actividad registrada por el momento',
        emptyStateText: 'Los eventos futuros aparecerán aquí'
      },
      services: {
        service: 'Servicio',
        family: 'Familia',
        subscribers: 'Suscriptores',
        numberOfSubscribers: `{subscribers}`,
        numberOfLicenses: `{licenses}`,
        withValidation: 'Validación',
        administratedValidation: 'Requiere la validación del administrador',
        notAdministratedValidation: 'No requiere validación',
        auto: 'Por defecto',
        accessByDefault: 'Añadido a los usuarios por defecto',
        notAccessByDefault: 'No se añade a los usuarios por defecto',
        available: 'Disponible',
        unavailable: 'No disponible',
        inheritedFromGroup: 'Heredado del grupo',
        accessToServiceSince: 'Acceso al servicio desde ',
        expired: 'Expirado',
        refused: 'Rechazado',
        suspendedLabel: 'Suspendido',
        suspended: `{value, plural, zero {Servicio suspendido} one {Servicio suspendido} other {Servicios suspendidos} } `,
        toValidate: 'Solicitud de servicio pendiente',
        add: 'Añadir un servicio',
        removeConfirmation: `Va a eliminar el acceso a este servicio, ¿desea continuar?`,
        removeSuccess: `Se ha dado de baja del servicio {serviceName}.`,
        pageAllSelected:
          '{count, plural, one {{count, number} se ha seleccionado el servicio} other {{count, number} se han seleccionado los servicios}} en esta página.',
        selectAll:
          'Seleccionar {count, plural, one {{count, number} servicio} other {{count, number} servicios}}',
        maxSelectedExceeded:
          'Su selección supera el límite máximo de {count, plural, one {{count, number} servicio} other {{count, number} servicios}}. Por favor, seleccione menos.',
        completedPending: `{count, plural, one {Su solicitud de añadir al servicio {count, number} ha sido enviada} other {Se han enviado sus solicitudes para añadir {count, number} servicios}}.`,
        pendingServices: 'Pendiente de validación',
        deniedServices: `{value, plural, zero {Servicio denegado} one {Servicio denegado} other {Servicios denegados} } `,
        emptyStateTitle: 'No se han encontrado servicios',
        emptyStateText: 'Los servicios suscritos futuros aparecerán aquí',
        emptyState: {
          title: 'No hay solicitudes pendientes.',
          description: 'Sus solicitudes pendientes aparecerán aquí.'
        },
        subscriptionEndDate: `Suscripción hasta el {date} - {hour}`,
        filterLabel: 'Estado',
        statusType: `{value, select, ${PersonAccessRightStatus.Accepted} {Aceptado(s)} ${PersonAccessRightStatus.ToValidate} {En espera de validación} ${PersonAccessRightStatus.Refused} {Rechazado(s)} other {Suspendido(s)}}`
      },
      editName: {
        firstName: 'Nombre',
        lastName: 'Appelido',
        editName: 'Cambiar el nombre'
      },
      editPhone: {
        phoneNumber: 'Teléfono',
        mobileNumber: 'Teléfono móvil',
        editPhone: 'Cambiar el número de teléfono'
      },
      editLanguage: {
        language: 'Idioma',
        editLanguage: 'Cambiar el idioma'
      },
      editSecurityLevel: {
        securityLevel: 'Nivel de seguridad',
        editSecurityLevel: 'Cambiar el nivel de seguridad'
      },
      editEmail: {
        editEmail: `Cambiar la dirección de correo electrónico`,
        email: 'Dirección electrónica',
        confirmation: {
          title: 'Correo electrónico enviado',
          message: `Se ha enviado un correo electrónico a la dirección de correo electrónico {email} con el asunto "Confirmar cambio de correo electrónico". Haga clic en el enlace del correo electrónico para confirmar el cambio de correo electrónico.`,
          hint: `Si no encuentra el correo electrónico, compruebe la carpeta de correo no deseado. Si sigue sin encontrar el correo electrónico, compruebe que la nueva dirección de correo electrónico es correcta y vuelva a intentarlo.`
        }
      },
      editPassword: 'Cambiar contraseña',
      updatedProfile: 'El perfil ha sido actualizado con éxito',
      identityMerged:
        'Su cuenta ha sido migrada, tendrá que autenticarse con sus credenciales de Microsoft.',
      beforeDirectoryMergeTitle:
        'Será redirigido a la página de inicio de sesión de {dir}',
      beforeDirectoryMergeConditions:
        'Para cambiar su cuenta Cegid a su cuenta {dir}',
      beforeDirectoryMergeText: `Debe iniciar la sesión con sus credenciales de {dir}. La dirección de correo electrónico utilizada puede ser la misma que la de su cuenta Cegid.`,
      mergeAccountInfo: `Desea iniciar sesión con una cuenta de {dir}`,
      mergeAction: `Vincular una cuenta {value}`,
      yourInformation: 'Su información',
      mergeValidationText:
        'Tras la migración, la conexión se realizará con sus identificadores de {dir} para todas las aplicaciones de Cegid.',
      mergeValidationSubText:
        'Para sincronizar su cuenta de {dir}, sólo tiene que volver a conectarse.'
    },
    personGroupProfile: {
      tabServices: {
        addServiceButton: 'Añadir un servicio'
      },
      about: {
        title: 'Acerca del grupo'
      }
    },
    users: {
      title: 'Usuarios',
      help:
        'En esta lista, puede seleccionar uno o varios usuarios para revocar, exportar o concederles algunos derechos de acceso sobre una o varias aplicaciones de su catálogo de servicios.',
      name: 'Apellido',
      firstname: 'Nombre',
      action: {
        addUser: 'Invitar usuarios',
        delete: {
          confirmation: `Usted borrará {count, plural, one {{count, number} usuario} other {{count, number} usuarios}}. ¿Quiere continuar?`,
          success: `{count, plural, one {Se ha eliminado {count, number} usuario.} other {Se han eliminado {count, number} usuarios.}}`
        }
      },
      pageSelectedUserCount:
        '{count, plural, one {{count, number} usuario de esta página ha sido seleccionado} other {{count, number} usuarios de esta página han sido seleccionados}}.',
      selectUserCount:
        'Seleccione {count, plural, one {{count, number} usuario} other {{count, number} usuarios}}',
      maxLimitExceeded:
        'Su selección supera el límite máximo de {count, plural, one {{count, number} usuario} other {{count, number} usuarios}}. Por favor, seleccione menos.',
      index: {
        administrators: 'Administrador',
        users: 'Usuario',
        email: 'Dirección electrónica',
        account: 'Cuenta de terceros',
        creationDate: 'Fecha de creación',
        activationDate: `Fecha de activación`,
        language: 'Idioma',
        phoneNumber: 'Teléfono',
        mobileNumber: 'Teléfono móvil',
        titleCode: 'Título',
        allGroups: 'Todos los grupos',
        allRoles: 'Todos los roles',
        allStatus: 'Estado',
        item: {
          email: '{value, select, null { } other {{value}} }',
          group: '{value, select, TOCLASSIFY {A archivar} other { }}',
          role: '{value, select, null { } other {{value}} }',
          status:
            '{value, select, CREATED {Creado} TOACTIVATE {Para ser activado} ACTIVATED {Activo} DISABLED {Inactivo} }'
        },
        selectedUserCount: `{count, plural, one {{count, number} usuario seleccionado} other {{count, number} usuarios seleccionados}}`,
        userRoles: {
          administrators: 'Administradores • {count, number}',
          notadministrators: 'No administradores • {count, number}'
        },
        userStatus: {
          actives: 'Activos • {count, number}',
          pending: `A la espera de la activación • {count, number}`,
          created: 'Creados • {count, number}'
        }
      },
      subscription: {
        success: {
          meToOne: `Se ha suscrito a 1 servicio.`,
          meToMany: `Se ha suscrito a {servicesCount} servicios.`,
          oneToOne: `El usuario se ha suscrito al servicio.`,
          oneToMany: `El usuario se ha suscrito a {servicesCount} servicios.`,
          manyToOne: `{usersCount} usuarios se han suscrito al servicio.`,
          manyToMany: `{usersCount} usuarios se han suscrito {servicesCount} servicios.`
        }
      },
      export: {
        lists: 'Listas',
        columns: 'Columnas',
        help: 'Seleccione sólo las columnas que desee exportar.',
        needServiceList: 'Seleccione sólo las listas que desee exportar.',
        itemToExport: 'Artículos a exportar',
        withServiceList: 'Lista de servicios por usuario',
        accountCode: 'Código de tercero',
        accountLabel: 'Nombre del tercero',
        creationDate: 'Fecha de creación',
        email: 'Dirección electrónica',
        securityLevel: 'Nivel de seguridad',
        status: 'Estado',
        services: 'Lista de servicios por usuario'
      }
    }
  },
  profile: {
    menu: {
      general: 'General',
      services: 'Servicios',
      restrictions: 'Restricciones',
      activities: 'Actividades',
      members: 'Miembros',
      domains: 'Dominios',
    },
    about: {
      title: 'Acerca de',
      creationDate: 'Creación',
      directories: 'Directorios',
      language: 'Idioma',
      outsourceableService: {
        title: 'Servicio externalizable'
      },
      serviceFamily: 'Familia de servicios'
    }
  },
  securityLevel: {
    weak: 'Bajo',
    medium: 'Medio',
    high: 'Alto'
  },
  tableCollectionColumnSelection: {
    title: 'Opción de columna',
    hint: `Seleccione las columnas que desee mostrar y arrástrelas para reorganizarlas. Es posible que no se muestren todas las columnas en las pantallas pequeñas.`
  },
  about: {
    google: {
      title: 'Conectarme a Cegid Cloud con mi cuenta de Google',
      subtitle:
        'Cegid Cloud es el ecosistema de aplicaciones y servicios en línea de Cegid',
      connectToCegidProducts: `Puedes conectarte a la plataforma y a los servicios que la soportan utilizando tu cuenta de Google.`,
      followSteps: `Para ello, sólo tienes que seguir los pasos según tus necesidades:`,
      whatYouWant: '¿Qué quieres hacer?',
      connectToCegidApplicationWithYourGoogleAccount:
        'Te conectas a las aplicaciones y servicios de Cegid con tus credenciales de Google',
      activateYourCegidAccountWithGoogle:
        'Activa tu nueva cuenta de Cegid con tu cuenta de Google',
      youAlrearyHaveACegidAccount:
        'Ya tienes una cuenta de Cegid y quieres acceder con tu cuenta de Google: ¡Fusiona tu cuenta!',
      youNeedHelpWriteTo: `Si necesitas ayuda para activar o conectar tu cuenta de Cegid a tu cuenta de Google, puedes escribir a `,
      forInformation: `Para obtener información, puedes encontrar`,
      ourCGV: `nuestras condiciones de uso `,
      andOur: `así como nuestro`,
      privacyPolitic: `política de privacidad y cookies`,
      userActivation: {
        youReceiveAnEmailToActivateYourAccount:
          'Has recibido un correo electrónico de activación de la cuenta de Cegid y te gustaría utilizar las credenciales de tu cuenta de Google para conectarte a las aplicaciones de Cegid',
        step1Title: '1. Has recibido un correo electrónico',
        step1Content: 'Haz click en el enlace de activación para continuar',
        step2Title:
          '2. Se te redireccionará a una página de activación de cuenta',
        step2Content:
          'Puedes activar tu cuenta de Cegid utilizando tu cuenta de Google haciendo clic en el botón "Continuar con Google"',
        step3Title:
          '3. Se te redireccionará a una página de autenticación de Google.',
        step3Content: 'Introduce las credenciales de tu cuenta de Google',
        step4Title: '4. Se te redireccionará a la Cuenta Cegid',
        step4Content: 'Tu cuenta se ha activado correctamente',
        step4Success:
          'La sesion debió haber iniciado en la Cuenta Cegid que te dará acceso a tu información actualizada.'
      },
      userUpdateIdp: {
        step1: '1. Accede a tu perfil de Cuenta Cegid',
        step2:
          '2. Desde la sección de directorios, puedes vincular tu cuenta de Google',
        step3: `3. Haz click en el botón "Vincular una cuenta Google", donde aparacera una información correspondiente al proceso. Haz click en "Lo tengo" para ser redirigido a una pantalla de inicio de sesión de Google`,
        step4:
          '4. Introduce tu dirección de correo electrónico vinculada a la cuenta de Google que deseas utilizar para acceder a las aplicaciones de Cegid',
        step5: `5. Verifica tu información, para continuar con la fusión de cuentas, haz click en "Migrar"`,
        step5End: `Se cerrará la sesión de la aplicación. `,
        step6: `6. Accede con el correo electrónico de la cuenta de Google que acabas de vincular a tu perfil de Cegid`,
        step7: `Ahora debería haber iniciado sesión en tu perfil con tus datos actualizados.`
      }
    }
  },
  helperText: {
    groupNameRequired: 'El nombre del grupo es obligatorio',
    groupDescriptionRequired: 'La descripción del grupo es obligatoria'
  },
  dataPrivacy:{
    title: 'Política de protección de datos',
    userProfileDataPrivacy: "Cegid SAS procesa sus datos personales como parte de la gestión de su cuenta Cegid Account. Usted tiene derechos de acceso, rectificación, supresión y portabilidad de sus datos, así como el derecho de oposición y limitación a ciertos tratamientos. Para más información sobre el tratamiento y cómo ejercer sus derechos, puede consultar la política de protección de datos.",
    sentence1: "La información personal proporcionada en Cegid Account es procesada por Cegid SAS para permitirle acceder a los servicios de Cegid en el contexto de la relación contractual.",
    sentence2: "Sus datos pueden ser comunicados a filiales del responsable del tratamiento, así como a proveedores externos encargados de la implementación del tratamiento o a terceros autorizados en caso de solicitudes por autoridades competentes. Es necesario proporcionar un identificador (correo electrónico o número de matrícula, por ejemplo). En su defecto, el tratamiento no podrá realizarse.",
    sentence3: "Sus datos personales se conservan {value} años.",
    sentence4: "Sus datos personales pueden ser transferidos a países ubicados fuera de la Unión Europea para los fines detallados anteriormente. Estas transferencias están sujetas a un marco jurídico específico para asegurar que estos datos estén protegidos por un nivel adecuado de protección.",
    sentence5: "De acuerdo con la regulación aplicable en materia de protección de datos personales, usted tiene derecho de acceso, rectificación, supresión y portabilidad de sus datos, así como el derecho de oposición y limitación a todos los datos que le conciernen.",
    sentence6: "Estos derechos pueden ejercerse escribiendo a nuestro delegado de protección de datos enviando su solicitud a dataprivacy@cegid.com. Puede ejercer su derecho de recurso en cualquier momento ante la Autoridad competente en materia de protección de datos personales (CNIL)."
  }
});
