import { PersonOrder } from 'Apollo';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import { MAX_SELECTED, ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { Box, Grid } from '@mui/material';
import TestIds from 'Tests/TestIds';
import { Person as IconPerson } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import { CdsColorBackgroundPrimaryLight } from 'Theme/constants/tokens';
import EmptyState from 'Components/Shared/EmptyState';
import useTableHandlers from 'Hooks/useTableHandlers';
import ExpandedSearch from './../../Components/Shared/ExpandedSearch';
import { useColumnsCandidates } from 'Hooks/useColumnsCandidates';

interface Props {
  loading: boolean;
  rows: IPerson[];
  selectedIds: string[] | null;
  onUpdate: any;
  onUpdateSearch: (search: string) => void;
  totalCount: number;
  orderBy: PersonOrder;
  currentPage: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  onChangeOrderBy: (orderBy: PersonOrder) => void;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (newRowsPerPage: number) => void;
}

function TableGroupCandidates(props: Readonly<Props>) {
  const {
    loading,
    selectedIds,
    rows,
    onUpdate,
    onUpdateSearch,
    totalCount,
    orderBy,
    currentPage,
    rowsPerPage,
    rowsPerPageOptions,
    onChangeOrderBy,
    onChangeRowsPerPage,
    onChangePage
  } = props;

  const ts = useTranslate();

  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });

  const columns = useColumnsCandidates();

  return (
    <Grid container direction="column">
      <Grid item style={{ background: `${CdsColorBackgroundPrimaryLight}` }}>
        <ExpandedSearch
          onChange={onUpdateSearch}
          testId={TestIds.pages.users.searchUserInput}
          placeholder={ts('component.searchBars.placeholder.users')}
        />
      </Grid>
      <Grid item>
        <Box height={600} style={{ overflow: 'auto' }}>
          <TableCollection
            loading={loading}
            columns={columns}
            rows={rows}
            getRowId={(r: IPerson) => r.immutableId}
            orderBy={orderBy}
            onChangeOrderBy={onChangeOrderBy as (orderBy: string) => void}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions ?? [ROW_PER_PAGE_DEFAULT]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={currentPage}
            onChangePage={onChangePage}
            onUnselect={handleUnselect}
            selectedIds={selectedIds}
            onSelect={handleSelect}
            maxSelected={MAX_SELECTED}
            totalCount={totalCount ?? 100}
            messages={{
              maxSelectedExceeded:
                'page.groupProfile.members.maxSelectedExceeded',
              pageAllSelected: 'page.groupProfile.members.pageAllSelected',
              selectAll: 'page.groupProfile.members.selectAll'
            }}
            emptyStateData={
              <EmptyState
                icon={<IconPerson />}
                primary={ts(
                  'page.groupProfile.members.addMembers.emptyState.title'
                )}
                secondary={ts(
                  'page.groupProfile.members.addMembers.emptyState.description'
                )}
              />
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default TableGroupCandidates;
