import { Grid } from '@mui/material';
import { Imgs } from '../../../Assets';
import { useStyles } from '../../../Page/MergeDirectory/MergeDirectory.styles';

interface Props {
  directory: string
}

function DirectoryMigrationHeader(props: Props) {
  const classes = useStyles();
  const { directory } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.mergeDirectoryHeader}
    >
      <Grid item>
        <img
          src={Imgs.cegidCorners.src}
          alt={Imgs.cegidCorners.alt}
          className={classes.logo}
        />
      </Grid>
      <Grid item className={classes.arrow}>
        <img  src={Imgs.arrow.src} alt="arrow" />
      </Grid>
      <Grid item>
        {directory === "Microsoft" && (<img
          src={Imgs.microsoft.src}
          alt={Imgs.microsoft.alt}
          className={classes.logo}
        />)}
        {directory === "Google" && (<img
          src={Imgs.google.src}
          alt={Imgs.google.alt}
          className={classes.logo}
        />)}
      </Grid>
    </Grid>
  );
}

export default DirectoryMigrationHeader;
