import { Grid, Tooltip } from '@mui/material';
import ServiceProfileLink from 'Components/Shared/ServiceProfileLink';
import Adm2ServiceProfileLink from 'Components/Shared/Adm2ServiceProfileLink';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  serviceSubscription: IServiceSubscription;
}

function CellService(props: Readonly<Props>) {
  const ts = useTranslate();
  const { serviceSubscription } = props;

  return (
    <>
      {serviceSubscription.service.groupCode === 'ADM-2' && (
        <Adm2ServiceProfileLink serviceSubscription={serviceSubscription} />
      )}
      {serviceSubscription.isAvailable === false && (
        <Grid container alignItems="center">
          <Grid item>
            <ServiceProfileLink serviceSubscription={serviceSubscription} />
          </Grid>
          <Grid item>
            <Tooltip title={ts('common.service.unavailable')}>
              <InfoOutlinedIcon fontSize="inherit" style={{ marginLeft: 8 }} />
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {serviceSubscription.service.groupCode !== 'ADM-2' &&
        serviceSubscription.isAvailable && (
          <ServiceProfileLink serviceSubscription={serviceSubscription} />
        )}
    </>
  );
}

export default CellService;
