import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from 'Theme/constants/colors';
import palette from 'Theme/constants/palette';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    minWidth: 'unset'
  },
  outlinedComponent: {
    borderRadius: '2px',
    border: `1px solid ${palette.border.default}`,
    '&:hover': {
      border: `1px solid ${colors.blue[500]}`
    }
  }
}));
