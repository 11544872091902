import { FormattedMessage } from 'react-intl';
import { Grid, IconButton, Tooltip } from '@mui/material';
import Skeleton from 'Theme/components/Skeleton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TableCollectionColumn } from 'Components/Shared/Table';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import { mdiCalendarClock } from '@mdi/js';
import TestIds from 'Tests/TestIds';
import { CdsColorActionNeutralActive } from 'Theme/constants/tokens';

export function getActionsOnLightProfileColumns(
  onRemove: (row: ILightPersonProfile) => void,
  onUpdate: (row: ILightPersonProfile) => void
): TableCollectionColumn<ILightPersonProfile>[] {
  return [
    {
      key: 'manage',
      width: 0.1,
      hidden: false,
      renderHead: () => <span></span>,
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: ILightPersonProfile }) => (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
        >
          <Grid item>
            <Tooltip
              title={
                <FormattedMessage id="page.serviceProfile.serviceMembers.editExpirationDate" />
              }
            >
              <IconButton
                onClick={() => onUpdate(row)}
                data-testid={
                  TestIds.pages.serviceProfile.membersTableSetExpirationAction
                }
                size="large"
              >
                <SvgIcon
                  path={mdiCalendarClock}
                  fill={CdsColorActionNeutralActive}
                  width="24"
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <FormattedMessage id="page.serviceProfile.serviceMembers.removeAccess" />
              }
            >
              <IconButton
                onClick={() => onRemove(row)}
                style={{ float: 'right' }}
                data-testid={
                  TestIds.pages.serviceProfile.membersTableRemoveAction
                }
                size="large"
              >
                <RemoveCircleIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )
    }
  ];
}
