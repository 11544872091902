import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CdsSizeRadius } from "Theme/constants/tokens";

export const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        fontSize: '1.3em'
    },
    smaller: {
        fontSize: 10,
        letterSpacing: '1.39px',
        width: 24,
        height: 24,
        borderRadius: CdsSizeRadius
    },
    small: {
        fontSize: 12,
        letterSpacing: '1.67px',
        width: 32,
        height: 32,
        borderRadius: CdsSizeRadius
    },
    medium: {
        fontSize: 16,
        letterSpacing: '1.67px',
        width: 40,
        height: 40,
        borderRadius: CdsSizeRadius
    },
    large: {
        fontSize: 24,
        letterSpacing: '1.67px',
        width: 56,
        height: 56,
        borderRadius: CdsSizeRadius
    },
    larger: {
        fontSize: 32,
        letterSpacing: '1.67px',
        width: 64,
        height: 64,
        borderRadius: CdsSizeRadius,
        margin: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
            4
        )} ${theme.spacing(2)} `
    }
}))