import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'Theme/constants';
import { CdsColorBrandSecondary50 } from "Theme/constants/tokens";

export const useStyles = makeStyles((theme: Theme) => ({
  requestBanner: {
    backgroundColor: CdsColorBrandSecondary50
  },
  pendingBanner: {
    backgroundColor: theme.palette.primary.main
  },
  banner: {
    padding: `0px ${theme.spacing(1)} 0px ${theme.spacing(2)}`,
    height: theme.spacing(6),
    display: 'flex'
  },
  bannerText: {
    color: colors.white,
    display: 'flex',
    alignItems: 'center'
  },
  svgIcon:{
    paddingRight: theme.spacing(1)
  }
}));