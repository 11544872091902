import { Theme } from "@mui/material";
import { CdsColorBackgroundPrimaryMain, CdsSizeRadius } from "Theme/constants/tokens";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        width: 64,
        height: 64,
        margin: `16px 16px 32px 16px`,
        fontSize: 32,
        borderRadius: CdsSizeRadius,
        letterSpacing: 1.67,
        backgroundColor: CdsColorBackgroundPrimaryMain
      },
      header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
      },
}))