import { gql } from '@apollo/client';
import { PersonFragment } from '../../fragments/person/Person';

export const GetAvailablePersonsForPersonGroup = gql`
  query GetAvailablePersonsForPersonGroup(
    $personGroupCode: String!
    $first: Int
    $offset: Int
    $searchTerm: String
    $orderBy: PersonOrder
  ) {
    availablePersonsForPersonGroup(
      personGroupCode: $personGroupCode
      first: $first
      offset: $offset
      searchTerm: $searchTerm
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...Person
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonFragment}
`;
