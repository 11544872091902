import * as React from 'react';
import { useParams, generatePath, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { ButtonProps } from '@mui/material/Button';
import { Button, Skeleton } from '@mui/material';
import {
  Group as IconGroup,
  ArrowForward as IconArrowForward
} from '@mui/icons-material';
import { GetPersonGroupsTotalCount } from '../../Apollo';
import CardCounter from 'Theme/components/CardCounter';
import { PrivateRoutes } from 'Router/Routes';
import TestIds from 'Tests/TestIds';
import useNotification from 'Hooks/useNotification';

interface Props {
  showButton?: boolean;
}

function GroupsCounter(props: Readonly<Props>) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const { onError } = useNotification();

  const { data, loading } = useQuery<GetPersonGroupsTotalCountData>(
    GetPersonGroupsTotalCount,
    {
      variables: {
        accountCode: accountCode === 'all' ? null : accountCode
      },
      onError,
      fetchPolicy: 'cache-first'
    }
  );

  return (
    <CardCounter
      testId={TestIds.pages.dashboard.card.groups}
      title={<FormattedMessage id="menu.groups" />}
      counter={
        (loading ?? data === undefined) ? (
          <Skeleton variant="rectangular" width="100px" title="progressbar" />
        ) : (
          <div data-testid={TestIds.pages.dashboard.groups.totalCount}>
            <FormattedNumber value={data.personGroups.totalCount} />
          </div>
        )
      }
      icon={<IconGroup />}
      action={
        props.showButton ? (
          <Button
            color="primary"
            component={React.forwardRef<Link, ButtonProps>(
              ({ className, children }, ref) => (
                <Link
                  ref={ref}
                  className={className}
                  to={generatePath(PrivateRoutes.personGroups.path, {
                    accountCode: accountCode
                  })}
                  data-testid={TestIds.pages.dashboard.card.groupListButton}
                >
                  {children}
                </Link>
              )
            )}
            endIcon={<IconArrowForward />}
          >
            <FormattedMessage id="page.dashBoard.index.seeTheList" />
          </Button>
        ) : null
      }
    />
  );
}

export default GroupsCounter;
