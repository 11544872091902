import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { typography } from 'Theme/constants';
import { CdsTypographyHeadline2LineHeight } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  action: {
    padding: theme.spacing(2)
  },
  cta: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      marginRight: 0
    }
  },
  customSubtitle1: {
    fontWeight: typography.fontWeightMedium,
    paddingBottom: theme.spacing(1)
  },
  title: {
    paddingBottom: theme.spacing(2)
  },
  directoryExtraInfoBloc: {
    margin: `${theme.spacing(2)}  auto`,
    padding: theme.spacing(2)
  },
  infoText: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(2)
    }
  },
  logo: {
    width: '64px',
    height: CdsTypographyHeadline2LineHeight,
    margin: theme.spacing(2)
  },
  overline: {
    textTransform: 'uppercase'
  },
  smColumn: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  }
}));
