import TopProfile from 'Components/Shared/ProfileTemplate/TopProfile';
import useTranslate from 'Hooks/useTranslate';
import {
  SectionAbout,
  SectionAdministrators,
  SectionApiKeys,
  SectionGroups,
  SectionMembers,
  SectionPendingRequests,
  SectionServices
} from 'Page/AccountProfile/Sections';
import SectionNotification from 'Page/AccountProfile/Sections/Notifications';
import { PrivateRoutes } from 'Router/Routes';
import SectionWithTitle from 'Theme/components/SectionWithTitle/SectionWithTitle';
import { CdsColorBackgroundNeutralMainDisabled, CdsColorBackgroundPrimaryMain } from 'Theme/constants/tokens';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { generatePath } from 'react-router';

interface Props {
  account: IAccountWithNotificationSettings;
}

type TBlock = {
  title: string;
  helpText?: string;
  children: React.ReactNode;
};

function TabGeneral(props: Readonly<Props>) {
  const { account } = props;
  const ts = useTranslate();

  const blocks = [
    {
      title: ts('page.accountProfile.aboutTitle'),
      link: generatePath(PrivateRoutes.administrators.path, {
        accountCode: account.code
      }),
      children: (
        <SectionAbout account={account} />
      )
    },
    {
      title: ts('page.accountProfile.sections.administrators.title'),
      children: <SectionAdministrators account={account} />
    },
    {
      title: ts('page.accountProfile.membersSection.membersTitle'),
      children: (
        <>
          <SectionMembers account={account} />
          <SectionGroups account={account} />
          <SectionNotification account={account} />
        </>
      )
    },
    {
      title: ts('menu.services'),
      children: (
        <>
          <SectionServices account={account} />
          <SectionPendingRequests account={account} />
        </>
      )
    },
    {
      title: ts('page.accountProfile.sections.apikeys.title'),
      children: <SectionApiKeys account={account} />
    }
  ];

  let isDisabled = account.active === false;

  return (
    <>
      <TopProfile
        name={account.name ?? ''}
        colorBlockBgColor={
          isDisabled
            ? CdsColorBackgroundNeutralMainDisabled
            : CdsColorBackgroundPrimaryMain
        }
        youConsultSentence={ts('page.accountProfile.youConsult')}
        colorBlockTitle={ts('common.terminology.client')}
        colorBlockSubTitle={shortAccountlabel(account)}
        contextualHelpContent={ts('page.userProfile.accountHelp')}
      />
      {blocks.map((b: TBlock, index) => (
        <SectionWithTitle key={index as any} title={b.title} info={b.helpText}>
          {b.children}
        </SectionWithTitle>
      ))}
    </>
  );
}

export default TabGeneral;
