import {
  Box,
  IconButton,
  Paper,
  Typography,
  MenuItem,
  useMediaQuery
  } from '@mui/material';
  import useTranslate from 'Hooks/useTranslate';
  import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
  import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchBar from '../SearchBar';
  
  interface Props {
    accounts: IAccount[];
    selectedAccount: IAccount | null;
    filterValue: string;
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClear: () => void;
    handleChooseAccount: (acc: IAccount) => void;
    inputLabel: string;
    name: string;
    loading?: boolean;
  }
  
  const SearchByAccount = (props: Props) => {
    const ts = useTranslate();
    const {
      accounts,
      selectedAccount,
      filterValue,
      handleSearch,
      handleClear,
      handleChooseAccount,
      inputLabel,
      name,
      loading
    } = props;
  
    const isMobileP = useMediaQuery(
      '(max-width:600px) and (orientation: portrait)'
    );
  
    return (
      <Box py={1} sx={selectedAccount ? { display: 'flex', flexDirection: 'column' } : {}} style={{ position: 'relative' , width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row'  }}>
          <SearchBar
            name={name}
            inputLabel={inputLabel}
            value={
              selectedAccount ? shortAccountlabel(selectedAccount) : filterValue
            }
            onChange={handleSearch}
            disabled={selectedAccount !== null}
          />
          {selectedAccount && (
            <IconButton
              onClick={handleClear}
              style={{ margin: '8px' }}
              size="large"
              data-testid="clearNewAccount"
            >
              <RemoveCircleIcon color="inherit" />
            </IconButton>
          )}
        </div>
        {!loading && filterValue !== '' && selectedAccount === null && (
          <Paper style={{ padding: '1em', maxHeight: '200px', overflow: 'auto', position: 'absolute', zIndex: 2, width: '100%' }}>
            {accounts.length === 0 && (
              <Typography>
                {ts('component.searchBars.availabledResult.noResultPrimary')}
              </Typography>
            )}
            {accounts.map((acc: IAccount) => {
              return (
                <MenuItem
                  key={acc.code}
                  value={acc.code}
                  role="menuitem"
                  onClick={() => handleChooseAccount(acc)}
                >
                  {isMobileP ? acc.code : `${shortAccountlabel(acc)}`}
                </MenuItem>
              );
            })}
          </Paper>
        )}
      </Box>
    );
  };
  
  export default SearchByAccount;
  