import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
            1
        )} ${theme.spacing(2)}`
    },
    circularClearer: {
        opacity: '0.3'
    },
    label: {
        width: '40%'
    },
    family: {
        width: '20%'
    },
    availability: {
        width: '17%'
    },
    description: {
        width: '23%'
    }
}));