import { ListItemButton, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ListItemProps } from '@mui/material/ListItem';
import * as React from 'react';
import { colors, palette } from 'Theme/constants';
import { CdsColorInteractionNeutralHovered } from 'Theme/constants/tokens';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      // Hide the overflowing text when this item is contained within a minified drawer
      overflow: 'hidden',
      '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: `${CdsColorInteractionNeutralHovered} !important`
      }
    },
    selected: {
      backgroundColor: `${colors.blue[50]} !important`,
      color: `${palette.primary.main} !important`,
      '&:hover': {
        backgroundColor: `${colors.blue[50]} !important`
      }
    },
    // material-ui's selected styles are overwriting ours without !important
  });

interface ListItemPropsWithComponent extends ListItemProps<React.ElementType> {
  // There's an issue with ListItemProps.component, and with <Component>Props.component in general:
  // https://github.com/mui-org/material-ui/issues/15827
  component?: React.ElementType;
}

class NavigationListItem extends React.Component<
  ListItemPropsWithComponent & WithStyles<typeof styles>
> {
  public render() {
    const { classes, ...props } = this.props;

    return (
      <ListItemButton
        {...props}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      />
    );
  }
}

export default withStyles(styles)(NavigationListItem);
