import React from 'react';
import {
  RevokeApiKey,
  RevokeApiKeyMutation
} from 'Apollo/mutations/apiKey/revokeApiKey';
import {
  Button,
  Popper,
  Paper,
  Typography,
  MenuItem,
  ListItemText,
  Box
} from '@mui/material';
import {
  usePopupState,
  bindToggle,
  bindPopper
} from 'material-ui-popup-state/hooks';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Mutation } from '@apollo/client/react/components';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import TestIds from 'Tests/TestIds';
import { ApolloError, MutationResult } from '@apollo/client';
import { IApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import { useStyles } from './ApiKey.styles';
import useTranslate from 'Hooks/useTranslate';
import useNotification from 'Hooks/useNotification';
import { mdiKey } from '@mdi/js';
import { CdsColorActionWhiteActive } from 'Theme/constants/tokens';

interface Props {
  api: IApiKey;
  refetchQueries: any;
  onClose?: () => void;
  button?: boolean;
}

function ApiKeyRevokeConfirmation(props: Props) {
  const { api, refetchQueries, onClose, button } = props;
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'apikeyRevokeConfirmation'
  });
  const { onSuccess } = useNotification();
  const classes = useStyles();
  const ts = useTranslate();

  const onCompleted = () => {
    onSuccess(
      'page.apiKeys.revocationSuccessMessage',
      { value: api.name },
      'persist',
      mdiKey,
      `${TestIds.pages.apiKeys.revocation.successMessage}`
    );
  };

  return (
    <ErrorHandler.Consumer>
      {errorHandler => (
        <Mutation<RevokeApiKeyData, RevokeApiKeyVariables>
          mutation={RevokeApiKey}
          variables={{
            apiKeyUuid: api.uuid
          }}
          onError={(error: ApolloError) => {
            errorHandler.onError(error, 'apiKey');
          }}
          onCompleted={onCompleted}
          refetchQueries={refetchQueries}
        >
          {(
            revokeApiKey: RevokeApiKeyMutation,
            { loading, data }: MutationResult
          ) => {
            return (
              <Box onClick={onClose}>
                {button && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      color: CdsColorActionWhiteActive
                    }}
                  >
                    <Button
                      color="inherit"
                      variant="outlined"
                      endIcon={<RemoveCircleIcon color="inherit" />}
                      {...bindToggle(popupState)}
                      data-testid={`${TestIds.pages.apiKeyProfileGeneral.revokeButton}`}
                    >
                      {ts('page.apiKeys.revoke')}
                    </Button>
                  </div>
                )}
                {!button && (
                  <MenuItem
                    className={classes.apiActionMenuItem}
                    {...bindToggle(popupState)}
                    data-testid={`${TestIds.pages.apiKeys.card.removeIconButton}`}
                  >
                    <ListItemText>{ts('page.apiKeys.revoke')}</ListItemText>
                    <Typography variant="body1">
                      <RemoveCircleIcon color="inherit" />
                    </Typography>
                  </MenuItem>
                )}
                <Popper
                  {...bindPopper(popupState)}
                  placement="top-start"
                  modifiers={[
                    {
                      name: 'arrow',
                      enabled: true
                    }
                  ]}
                >
                  <Paper className={classes.popperPaper}>
                    <form
                      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();

                        if (loading) {
                          return;
                        }

                        revokeApiKey();
                        data !== null && popupState.close();
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.wordBreak}
                        data-testid={
                          TestIds.pages.apiKeys.revocation.confirmationText
                        }
                      >
                        {ts('page.apiKeys.revocationConfirmationApiKeyName')}
                        <strong>{api.name}</strong>
                        {ts('page.apiKeys.revocationConfirmationText')}
                      </Typography>
                      <div className={classes.popperAction}>
                        <Button
                          onClick={popupState.close}
                          color='inherit'
                          data-testid={
                            TestIds.pages.apiKeys.revocation.cancelButton
                          }
                        >
                          {ts('common.action.cancel')}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          autoFocus
                          data-testid={
                            TestIds.pages.apiKeys.revocation.confirmButton
                          }
                        >
                          {ts('page.apiKeys.revoke')}
                        </Button>
                      </div>
                    </form>
                  </Paper>
                </Popper>
              </Box>
            );
          }}
        </Mutation>
      )}
    </ErrorHandler.Consumer>
  );
}

export default ApiKeyRevokeConfirmation;
