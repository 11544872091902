import {
  Box,
  Button,
  CardActions,
  List,
  ListItem,
  Paper
} from '@mui/material';
import { ArrowForward, CheckCircleOutline } from '@mui/icons-material';
import AccessRightsCard from 'Components/AccessRightsCard';
import useTranslate from 'Hooks/useTranslate';
import { Link } from 'react-router-dom';
import PaperTitle from 'Theme/components/PaperTitle';
import DashBoardSubscriptionRequestsSkeleton from './DashBoardSubscriptionRequests.skeleton';
import { useStyles } from './DashBoardSubscriptionRequests.styles';
import EmptyState from 'Components/Shared/EmptyState';

type Props = {
  accessRightsRequests: IPersonAccessRight[];
  loading: boolean;
  onRefetch(): void;
};

const DashBoardSubscriptionRequests = ({
  accessRightsRequests,
  onRefetch,
  loading
}: Props) => {
  const classes = useStyles();
  const ts = useTranslate();
  const hasRequest = accessRightsRequests.length > 0;
  return (
    <Paper className={classes.root}>
      <PaperTitle
        variant="subtitle1"
        title={ts('page.dashBoard.index.subscriptionRequests.title')}
      />

      <Box className={classes.content}>
        {loading && <DashBoardSubscriptionRequestsSkeleton />}
        {!loading && !hasRequest && (
          <EmptyState
            primary={ts(
              'page.dashBoard.index.subscriptionRequests.noPendingRequests'
            )}
            icon={<CheckCircleOutline />}
          />
        )}
        {!loading && hasRequest && (
          <List>
            {accessRightsRequests.map((request, i) => {
              return (
                <ListItem
                  key={
                    request.person.immutableId +
                    request.service.groupCode +
                    request.account?.code
                  }
                  divider
                >
                  <Box py={1} style={{ width: '100%' }}>
                    <AccessRightsCard data={request} onRefetch={onRefetch} />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
      {hasRequest && (
        <CardActions classes={{ root: classes.cardActions }}>
          <Button
            className={classes.seeMoreButton}
            color="primary"
            endIcon={<ArrowForward />}
            component={Link}
            to={'/all/accessRights'}
          >
            {ts('page.dashBoard.index.subscriptionRequests.seeMore')}
          </Button>
        </CardActions>
      )}
    </Paper>
  );
};

export default DashBoardSubscriptionRequests;
