import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorBackgroundPrimaryLight } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100vh',
        background: CdsColorBackgroundPrimaryLight,
    },
    errorContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    invertedRoot: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
            textAlign: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    backToHomePage: {
        marginTop: '2em',
        marginLeft: '1.5em',
        [theme.breakpoints.up('md')]: {
            marginTop: '5em'
        }
    },
    bg: {
        maxWidth: '100%',
        maxHeight: '100%',
        position: 'absolute',
        top: '-5%',
        left: '-5%',
        zIndex: 0,
        [theme.breakpoints.only('xs')]: {
            maxHeight: '540px'
        }
    },
    customBg: {
        maxWidth: '100%',
        maxHeight: '100%',
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 0
    },
    content: {
        zIndex: 1,
        // padding: '2em',
        textAlign: 'right',
        alignSelf: 'center',
        [theme.breakpoints.only('xs')]: {
            textAlign: 'center',
            alignSelf: 'flex-end'
        }
    },
    invertedContent: {
        textAlign: 'left',
        [theme.breakpoints.only('xs')]: {
            alignSelf: 'flex-start',
            textAlign: 'center'
        }
    },
    errorSentence: {
        padding: '0.5em',
        [theme.breakpoints.down('md')]: {
            fontSize: '1em'
        },
        [theme.breakpoints.only('sm')]: {
            '@media(orientation: portrait)': {
                fontSize: '2em'
            }
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5em',
            paddingLeft: '1em'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.2em',
            fontWeight: 100
        }
    },
    errorType: {
        fontSize: '2em',
        padding: '0.5em',
        [theme.breakpoints.down('md')]: {
            fontSize: '2em'
        },
        [theme.breakpoints.only('sm')]: {
            '@media(orientation: portrait)': {
                fontSize: '3em'
            }
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '3em'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '4em',
        }
    },
    redirectionButton:{
        margin: '3em 1em 1em 1em',
    }
}))