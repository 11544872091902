import * as React from 'react';
import { Typography } from '@mui/material';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { ToolTipContent } from './TooltipContent';
import { settings } from './ServiceAccountDistribution.data';

import { useStyles } from './ServiceAccountDistribution.style';
import { useParams } from 'react-router-dom';
import useTranslate from '../../../Hooks/useTranslate';

interface IData {
  label: string;
  serviceCount: number;
  index: number;
}

interface Props {
  data: {
    accountsWithServicesSubscriptionsCount: IPaginatedList<
      IAccountWithSubscriptionsCount
    >;
  };
}

export const AccountsChart = ({ data }: Props) => {
  const classes = useStyles();
  const { accountCode } = useParams<IUriParams>();
  const ts = useTranslate();

  const isMultiAccounts = accountCode.toLowerCase() === 'all';

  const { accountsWithServicesSubscriptionsCount } = data;

  const chartData: IData[] = isMultiAccounts
    ? accountsWithServicesSubscriptionsCount.edges.map((cv, index) => ({
        index,
        label: cv.node.account.name || '',
        serviceCount: cv.node.subscriptionCount
      }))
    : accountsWithServicesSubscriptionsCount.edges
        .filter(v => v.node.account.code === accountCode)
        .map((cv, index) => ({
          index,
          label: cv.node.account.name || '',
          serviceCount: cv.node.subscriptionCount
        }));

  const totalCount = !!data
    ? data.accountsWithServicesSubscriptionsCount.totalCount
    : 0;

  return (
    <React.Fragment>
      <PieChart width={190} height={120}>
        <Tooltip content={<ToolTipContent colors={settings.colors} />} />
        <Pie
          stroke="none"
          innerRadius={50}
          cx="50%"
          cy="90%"
          outerRadius={80}
          startAngle={180}
          endAngle={0}
          dataKey="serviceCount"
          data={chartData}
        >
          {chartData.map(entry => (
            <Cell key={entry.label} fill={settings.colors[entry.index % 5]} />
          ))}
        </Pie>
      </PieChart>
      <Typography className={classes.titleChart}>
        {isMultiAccounts ? totalCount : 1}{' '}
        {isMultiAccounts && totalCount > 1
          ? ts('common.terminology.accounts')
          : ts('common.terminology.account')}
      </Typography>
    </React.Fragment>
  );
};

export default AccountsChart;
