import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  from
} from '@apollo/client';
import LocalStorage from '../Tools/localStorage';
import { typePolicies } from './typePolicies';
import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.code === 'E401' && window) {
        window.location.reload();
      }

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

function createClient(uri: string) {
  return new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: (o: any) => {
        const typename = o.__typename;
        switch (typename) {
          case 'DirectoryAuthenticatingPersonType':
            return `${typename}:${o.identityIdentifier}`;
          case 'ServiceIPRange':
            return `${typename}:${o.immutableId}`;
          case 'Person':
            return `${typename}:${o.immutableId}`;
          case 'LightPersonProfile':
            return `${typename}:${o.immutableId}`;
          case 'LightPersonGroup':
            return `${typename}:${o.code}`;
          case 'Language':
            return `${typename}:${o.code}`;
          case 'ApiKey':
            return `${typename}:${o.uuid}`;
          case 'ApiKeyEvent':
            return `${typename}:${o.apiKeyUuid}:${o.date}:${o.type}`;
          case 'Avatar':
            return `${typename}:${o.initials}:${o.color}`;
          case 'AccountWithSubscriptions':
            return `${typename}:${o.code}`;
          case 'Service':
            return `${typename}:${o.groupCode}`;
          case 'Error':
            return `${typename}:${o.code}`;
          case 'Account':
            return `${typename}:${o.code}`;
          case 'PersonGroupServiceAssignment':
            return `${typename}:${o.groupCode}:${o.serviceCode}:${o.status}:${o
              .targetAccount?.code || '0'}`;
          case 'ServiceSubscription':
            return `${typename}:${o.account.__ref}:${o.service.__ref}:${o
              .targetAccount?.__ref || '0'}`;
          case 'AccessRight':
            return `${typename}:${o.account?.__ref || '0'}:${o.person.__ref}:${
              o.service.__ref
            }:${o.personGroupSource?.code || 0}`;
          default:
            return defaultDataIdFromObject(o);
        }
      },
      typePolicies
    }),
    connectToDevTools: true,
    uri,
    defaultOptions: {
      query: {
        // "cache-and-network" is not compatible with query.fetchPolicy but it's ok
        // since the <Query> component actually uses "watchQuery"
        // https://github.com/apollographql/apollo-client/issues/3130
        fetchPolicy: 'network-only'
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      }
    },
    link: from([
      errorLink,
      new HttpLink({
        fetchOptions: 'no-cors',
        headers: {
          'X-CSRFToken': (LocalStorage.CsrfToken as unknown) as string,
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Origin': '*'
        },
        uri
      })
    ])
  });
}

export default createClient;
