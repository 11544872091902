import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useStyles } from './Drawer.styles';
import SetExpirationDate from '../SetExpirationDate';
import { Person as UserIcon } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import { GetPersonAccessRights, UpdatePersonAccessRights } from 'Apollo';
import { useMutation } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { mdiCalendarClock } from '@mdi/js';
import { CdsColorActionNeutralActive } from 'Theme/constants/tokens';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import DrawerBottom from 'Components/Shared/BottomDrawer/DrawerBottom';

interface Props {
    serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier;
    userIdentifier: string;
    selectedIds: string[];
    currentMode: string | null;
    setMode: (mode: string | null) => void;
}

function UserServicesActionsDrawer(props: Readonly<Props>) {
    const { serviceSubscriptionIdentifier, userIdentifier, selectedIds, currentMode, setMode } = props;
    const ts = useTranslate();
    const classes = useStyles();
    const { onSuccess, onError } = useNotification();

    const [updateAccessRightsExpirationDate, { loading: loadingUpdateMutation }] = useMutation<{updatePersonAccessRights: boolean}, IUpdatePersonAccessRightVariables>(
        UpdatePersonAccessRights,
        {
            onCompleted: () => {
                onSuccess('page.serviceProfile.serviceMembers.updateEndDateResult.one');
                setMode(null);
            },
            refetchQueries: [GetPersonAccessRights],
            onError
        }
    );

    const setAccessRightsEndDate = async (newExpiryDate: Date | null) => {
        await updateAccessRightsExpirationDate({
            variables: {
                data: [{
                    accountCode: serviceSubscriptionIdentifier.accountCode,
                    endValidityDate: newExpiryDate,
                    personIdentifier: userIdentifier,
                    serviceGroupCode: serviceSubscriptionIdentifier.serviceCode
                }]
            }
        });
    };

    return (
        <DrawerBottom
            drawerHead={
                <>
                <Grid item sm={4}>
                    <div style={{ padding: '8px' }}>
                        <Typography variant="body2" color="textSecondary">
                            <UserIcon
                            color="primary"
                            classes={{ root: classes.headerLeftIcon }}
                            />
                            {ts('page.services.index.selectedServiceCount', {
                            count: selectedIds.length
                            })}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sm={4}>
                    <div className={classes.drawerIcons}>
                        <Tooltip
                            title={ts(
                            'page.userProfile.acceptedServices.updateServiceEndDateForUser'
                            )}
                        >
                            <IconButton
                                onClick={() => setMode('setExpiration')}
                                size="large"
                            >
                            <SvgIcon
                                path={mdiCalendarClock}
                                fill={CdsColorActionNeutralActive}
                                width="24"
                            />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item sm={4}></Grid>
            </>
            }
            drawerBody={
                <>
                    {currentMode === 'setExpiration' && (
                        <SetExpirationDate
                            loading={loadingUpdateMutation}
                            onCancel={() => setMode(null)}
                            onSubmit={(newExpiryDate: Date) => {
                                setAccessRightsEndDate(newExpiryDate);
                            }}
                            title={ts(
                                'page.serviceProfile.serviceMembers.updateServiceEndDateForOneOrManyUser',
                                {
                                serviceName: serviceSubscriptionIdentifier.serviceCode,
                                count: selectedIds.length
                                }
                            )}
                        />
                    )}
                </>
            }
        />
    );
}

export default UserServicesActionsDrawer;
