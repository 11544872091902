import * as React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import {
  Divider,
  ListSubheader,
  Typography,
  Box
} from '@mui/material';
import {
  BubbleChart as IconBubbleChart,
  CheckCircle as IconCheckCircle,
  Dashboard as IconDashboard,
  Person as IconPerson,
  Group as IconGroup,
  Add as IconAdd,
  VpnKey as IconVpnKey,
  Notifications as IconNotifications
} from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { PersonContext } from '../../Context/CurrentPerson';
import { PrivateRoutes } from 'Router/Routes';
import {
  NavigationList,
  NavigationListItem,
  NavigationListItemIcon,
  NavigationListItemText
} from 'Theme/components';
import TestIds from 'Tests/TestIds';
import NavigationListItemLink from '../SidebarListItemLink/NavigationListItemLink';
import ModalUsersCreation from 'Components/ModalUsersCreation';
import { CdsColorActionNeutralInactive } from 'Theme/constants/tokens';
import { useContext } from 'react';
import useTranslate from 'Hooks/useTranslate';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

const useStyles = makeStyles({
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 300
  },
  grow: {
    flex: 1
  }
});

const appVersion = window.AppSettings.PUBLIC_APP_VERSION;
const versionName = appVersion?.substring(appVersion?.lastIndexOf('/') + 1);

interface Props { }

function Navigation(props: Props) {
  const classes = useStyles();
  const ts = useTranslate();
  const { currentPerson } = useContext(PersonContext);
  const { hasAccessToAccounts, isOrganizationOwner, isAccountAdmin, isServiceAdmin } = useCurrentUserInfo();
  const { accountCode } = useParams<{ accountCode: string }>();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const defaultAccountCode =
    accountCode || (currentPerson.account ? currentPerson.account.code : 'all');

  const NavServices = [
    <NavigationListItemLink
      key={1}
      to={generatePath(PrivateRoutes.services.path, {
        accountCode: defaultAccountCode
      })}
      data-testid={TestIds.common.navigation.navigationItem.services}
    >
      <NavigationListItemIcon>
        <IconBubbleChart />
      </NavigationListItemIcon>
      <NavigationListItemText
        primary={<FormattedMessage id="menu.services" />}
      />
    </NavigationListItemLink>,
    <NavigationListItemLink
      key={2}
      to={generatePath(PrivateRoutes.accessRights.path, {
        accountCode: defaultAccountCode
      })}
      data-testid={TestIds.common.navigation.navigationItem.accessRights}
    >
      <NavigationListItemIcon>
        <IconCheckCircle />
      </NavigationListItemIcon>
      <NavigationListItemText
        primary={<FormattedMessage id="menu.accessRights" />}
      />
    </NavigationListItemLink>
  ];

  const NavOrganizations = [
    <NavigationListItemLink
      key={1}
      to={generatePath(PrivateRoutes.organizations.path, {
        accountCode: defaultAccountCode,
      })}
      data-testid={TestIds.common.navigation.navigationItem.organizations}
    >
      <NavigationListItemIcon>
        <ApartmentIcon />
      </NavigationListItemIcon>
      <NavigationListItemText
        primary={ts("menu.organizations")}
      />
    </NavigationListItemLink>
  ];

  const dashboardGeneratedPath = generatePath(PrivateRoutes.dashboard.path, {
    accountCode: defaultAccountCode
  });

  const profileGeneratedPath = generatePath(PrivateRoutes.usersProfile.path, {
    accountCode: defaultAccountCode,
    immutableId: currentPerson.immutableId
  });

  return (
    <div className={classes.navigation}>
      {hasAccessToAccounts && <Box height={50} />}
      <NavigationList>
        <NavigationListItemLink
          to={isAccountAdmin ? dashboardGeneratedPath : profileGeneratedPath}
          data-testid={
            isAccountAdmin
              ? TestIds.common.navigation.navigationItem.home
              : TestIds.common.profileMenu.navigationItem.profile
          }
        >
          <NavigationListItemIcon>
            <IconDashboard />
          </NavigationListItemIcon>
          <NavigationListItemText
            primary={
              <FormattedMessage
                id={isAccountAdmin ? 'menu.dashboard' : 'menu.profile'}
              />
            }
          />
        </NavigationListItemLink>
      </NavigationList>
      <Divider />
      <NavigationList
        subheader={
          <ListSubheader>
            <FormattedMessage id="menu.management" />
          </ListSubheader>
        }
      >
        {isAccountAdmin && (
          <NavigationListItemLink
            to={generatePath(PrivateRoutes.users.path, {
              accountCode: defaultAccountCode
            })}
            data-testid={TestIds.common.navigation.navigationItem.users}
          >
            <NavigationListItemIcon>
              <IconPerson />
            </NavigationListItemIcon>
            <NavigationListItemText
              primary={<FormattedMessage id="menu.users" />}
            />
          </NavigationListItemLink>
        )}
        {isAccountAdmin && (
          <NavigationListItemLink
            to={generatePath(PrivateRoutes.personGroups.path, {
              accountCode: defaultAccountCode
            })}
            data-testid={TestIds.common.navigation.navigationItem.groups}
          >
            <NavigationListItemIcon>
              <IconGroup />
            </NavigationListItemIcon>
            <NavigationListItemText
              primary={<FormattedMessage id="menu.groups" />}
            />
          </NavigationListItemLink>
        )}
        {isServiceAdmin && NavServices}
        {isAccountAdmin && (
          <NavigationListItemLink
            to={generatePath(PrivateRoutes.apiKeyServices.path, {
              accountCode: defaultAccountCode
            })}
            data-testid={
              TestIds.common.navigation.navigationItem.apiKeyServices
            }
          >
            <NavigationListItemIcon>
              <IconVpnKey />
            </NavigationListItemIcon>
            <NavigationListItemText
              primary={<FormattedMessage id="menu.apiKey" />}
            />
          </NavigationListItemLink>
        )}
        {(isAccountAdmin || isOrganizationOwner) && NavOrganizations}
      </NavigationList>
      {isAccountAdmin && [
        <Divider key={'divider1'} />,
        <NavigationList
          key={'menu.settings'}
          subheader={
            <ListSubheader>
              <FormattedMessage id="menu.settings" />
            </ListSubheader>
          }
        >
          <NavigationListItemLink
            key={'menu.notifications'}
            to={generatePath(PrivateRoutes.notifications.path, {
              accountCode: defaultAccountCode
            })}
            data-testid={TestIds.common.navigation.navigationItem.notifications}
          >
            <NavigationListItemIcon>
              <IconNotifications />
            </NavigationListItemIcon>
            <NavigationListItemText
              primary={<FormattedMessage id="menu.notifications" />}
            />
          </NavigationListItemLink>
        </NavigationList>,
        <Divider key={'divider2'} />
      ]}

      {isAccountAdmin && (
        <NavigationList
          subheader={
            <ListSubheader>
              <FormattedMessage id="menu.shortcuts" />
            </ListSubheader>
          }
        >
          <NavigationListItem
            onClick={() => setModalIsOpen(true)}
            data-testid={TestIds.common.navigation.shortcuts.addUser}
          >
            <NavigationListItemIcon>
              <IconAdd />
            </NavigationListItemIcon>
            <NavigationListItemText
              primary={<FormattedMessage id="menu.addUser" />}
            />
          </NavigationListItem>
        </NavigationList>
      )}
      <div className={classes.grow} />
      <Divider key={'divider3'} />
      <Box
        p={1}
        display="flex"
        style={{ background: CdsColorActionNeutralInactive }}
        justifyContent="center"
      >
        <Typography variant="caption" align="center" style={{ color: 'white' }}>
          {versionName}
        </Typography>
      </Box>
      {modalIsOpen && (
        <ModalUsersCreation
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default Navigation;
