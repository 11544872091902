import makeStyles from '@mui/styles/makeStyles';
import { CdsColorSpecificDividerNeutral } from 'Theme/constants/tokens';

export const useStyles = makeStyles(() => ({
  drawer: {
    borderRadius: '20px 20px 0 0',
    maxHeight: '80%',
  },
  drawerContainer: {
    alignItems: 'center',
    borderBottom: `1px solid ${CdsColorSpecificDividerNeutral}`,
  }
}));