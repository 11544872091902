import { gql } from '@apollo/client';
import { ServiceSubscriptionFragment } from '../service/ServiceSubscription';

// TODO: we can't use the Account fragment here because /accounts is of type AccountWithSubscriptions
// We need to figure out a way to create a fragment that can be shared between Account and AccountWithSubscriptions.
// Or perhaps we should not have two types for the same object.
export const AccountWithSubscriptionsFragment = gql`
  fragment AccountWithSubscriptions on AccountWithSubscriptions {
    code
    name
    active
    type
    accountingStatus
    phase
    zipCode
    city
    serviceSubscriptions {
      ...ServiceSubscription
    }
  }
  ${ServiceSubscriptionFragment}
`;
