import {
  GetAvailableServicesForAccessRight,
  ServiceSubscriptionOrder
} from 'Apollo';
import { useQuery } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { useState } from 'react';
import EmptyState from 'Components/Shared/EmptyState';
import { ErrorMsg } from 'Components/Shared';
import { BubbleChart as IconBubbleChart } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useColumnsServices } from 'Hooks/useColumnsServices';
import { MAX_SELECTED, ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import { stringifyServiceSubscriptionNode } from 'Tools/stringifyServiceSubscriptionNode';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './AddServicesDrawer.styles';
import { calculateNewPage } from 'Tools/calculateNewPage';

interface Props {
  servicesFilter: string | null;
  isAdminMode: boolean;
  person: IPerson;
  onUpdate: (value: string[] | null) => void;
  selectedIds: string[] | null;
  isMobileP: boolean;
}

const AvailableServicesForAccessRightContainer = ({
  servicesFilter,
  isAdminMode,
  person,
  selectedIds,
  onUpdate,
  isMobileP
}: Props) => {
  const { onError } = useNotification();
  const ts = useTranslate();
  const classes = useStyles();

  const columns = useColumnsServices({
    isSubscribersHidden: true,
  });

  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<ServiceSubscriptionOrder>(
    ServiceSubscriptionOrder.NameAsc
  );

  function onChangeRowsPerPage(newRowsPerPage: number) {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  }

  function onChangeOrderBy(orderBy: ServiceSubscriptionOrder) {
    setOrderBy(orderBy);
  }

  function onChangePage(newPage: number) {
    setCurrentPage(newPage);
  }

  function onSelect(ids: string[] | null) {
    if (ids == null) {
      onUpdate(null);
      return;
    }

    onUpdate(
      ids.reduce(
        (acc, id) => (acc.includes(id) ? acc : acc.concat([id])),
        selectedIds || []
      )
    );
  }

  function onUnselect(ids: string[] | null) {
    if (ids == null) {
      onUpdate([]);
      return;
    }
    selectedIds == null
      ? onUpdate([])
      : onUpdate(selectedIds.filter(id => !ids.includes(id)));
  }

  const { data, loading, error } = useQuery<{
    availableServicesForAccessRight: IPaginatedList<IServiceSubscription>;
  }>(GetAvailableServicesForAccessRight, {
    onError,
    variables: {
      servicesFilter: servicesFilter,
      personIdentifier: isAdminMode ? person.immutableId : null,
      offset: currentPage * rowsPerPage,
      first: rowsPerPage,
      orderBy: orderBy
    }
  });

  if (loading) {
    return (
      <EmptyState
        icon={
          <CircularProgress
            size={44}
            color="inherit"
            className={classes.circularClearer}
          />
        }
        primary={' '}
        secondary={' '}
        className={classes.emptyState}
      />
    );
  }
  if (!loading && error) {
    return (
      <div className={classes.emptyState}>
        <ErrorMsg />
      </div>
    );
  }

  if (!loading && data?.availableServicesForAccessRight?.totalCount === 0) {
    return (
      <EmptyState
        icon={<IconBubbleChart />}
        primary={ts('page.userProfile.services.emptyStateTitle')}
        className={classes.emptyState}
      />
    );
  }

  return (
    <TableCollection
      loading={loading}
      columns={columns}
      disabledRows={(data?.availableServicesForAccessRight?.edges ?? [])
        .filter(({ node }) => node.isAvailable === false)
        .map(row => stringifyServiceSubscriptionNode(row.node))}
      rows={(data?.availableServicesForAccessRight?.edges ?? []).map(
        ({ node }) => node
      )}
      getRowId={(serviceSubscription: IServiceSubscription) =>
        stringifyServiceSubscriptionNode(serviceSubscription)
      }
      orderBy={orderBy}
      onChangeOrderBy={orderBy =>
        onChangeOrderBy(orderBy as ServiceSubscriptionOrder)
      }
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      page={currentPage}
      onChangePage={onChangePage}
      onUnselect={onUnselect}
      selectedIds={selectedIds}
      onSelect={onSelect}
      maxSelected={MAX_SELECTED}
      totalCount={
        data?.availableServicesForAccessRight
          ? data.availableServicesForAccessRight.totalCount
          : 100
      }
      messages={{
        maxSelectedExceeded: 'page.userProfile.services.maxSelectedExceeded',
        pageAllSelected: 'page.userProfile.services.pageAllSelected',
        selectAll: 'page.userProfile.services.selectAll'
      }}
    />
  );
};

export default AvailableServicesForAccessRightContainer;
