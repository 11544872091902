import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'Theme/constants';
import { CdsSizeRadius } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 64,
    height: 64,
    margin: `16px 16px 32px 16px`,
    fontSize: 32,
    borderRadius: CdsSizeRadius,
    letterSpacing: 1.67,
    backgroundColor: colors.blue[500]
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  blueCard: {
    backgroundColor: colors.blue[500],
    color: theme.palette.common.white
  },
  colorWhite: {
    color: theme.palette.common.white
  },
  headerSubTitle: {
    opacity: 0.6
  },
  colorWhiteB: {
    color: theme.palette.common.white,
    fontSize: '18px'
  }
}));
