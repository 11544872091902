import { ActivationStatus } from 'Apollo/fragments/person/Person';
import CustomStatus from '../CustomStatus';
import useTranslate from 'Hooks/useTranslate';
interface Props {
  person: IPerson;
}

function UserStatusChip({ person }: Readonly<Props>) {
  const ts = useTranslate();
  switch (person.status) {
    case ActivationStatus.Activated:
      return (
        <CustomStatus
          variant="success"
          label={ts('page.userProfile.section.personal.status.activated')}
        />
      );
    case ActivationStatus.ToActivate:
      return (
        <CustomStatus
          variant="pending"
          label={ts('page.userProfile.section.personal.status.toActivate')}
        />
      );
    case ActivationStatus.Created:
      return (
        <CustomStatus
          variant="warning"
          label={ts('page.userProfile.section.personal.status.created')}
        />
      );
    case ActivationStatus.Disabled:
      return (
        <CustomStatus
          variant="error"
          label={ts('page.userProfile.section.personal.status.disabled')}
        />
      );

    default:
      return null;
  }
}

export default UserStatusChip;
