import { ApolloError } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { getSupportedLocale, messages } from '../../../Languages';
import { PageError } from 'Page/PageError';

type Props = {
  error?: ApolloError | Error;
};

const E500 = ({ error }: Props) => {
  const browserLangues = window.navigator.languages || [];
  const defaultLocal = getSupportedLocale(browserLangues);
  return (
    <IntlProvider locale={defaultLocal} messages={messages[defaultLocal]}>
      <PageError
        title={messages[defaultLocal]['error.oops']}
        textPrimary={messages[defaultLocal]['error.type.E500']}
      />
    </IntlProvider>
  );
};

export default E500;
