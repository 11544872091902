import * as React from 'react';
import { Card, CardContent, CardActions, Typography, Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: `4px solid ${theme.palette.primary.main}`,
      minHeight: 148
    },
    cardContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row'
    },
    cardContentText: {
      flex: 1
    },
    cardContentIcon: {
      fontSize: 40
    },
    cardActions: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  });

interface Props extends WithStyles<typeof styles> {
  testId: string;
  title: React.ReactNode;
  counter: React.ReactNode;
  icon: React.ReactElement;
  action?: React.ReactNode;
  loading?: boolean;
}

function CardCounter(props: Props) {
  return (
    <Card classes={{ root: props.classes.root }} data-testid={props.testId}>
      <CardContent classes={{ root: props.classes.cardContent }}>
        <div className={props.classes.cardContentText}>
          <Typography variant="body2" color="textSecondary">
            {props.title}
          </Typography>
          {!props.loading && (
            <Typography variant="h4">{props.counter}</Typography>
          )}
        </div>
        <div className={props.classes.cardContentIcon}>
          {React.cloneElement(props.icon, {
            fontSize: 'inherit',
            color: 'primary'
          })}
        </div>
      </CardContent>
      {props.action && (
        <CardActions classes={{ root: props.classes.cardActions }}>
          {props.action}
        </CardActions>
      )}
    </Card>
  );
}

export default withStyles(styles)(CardCounter);
