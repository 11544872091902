import { useState } from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { frFR } from '@mui/x-date-pickers/locales';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import useTranslate from '../../../Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import CurrentPerson from '../../../Context/CurrentPerson';
import { useStyles } from './DatePickerButton.styles';
import EventIcon from '@mui/icons-material/Event';

type Props = {
  variant?: string;
  onAccept(e: Date): void;
  onDecline(): void;
};

const DatePickerButton = ({ onAccept, onDecline, variant }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('md'));

  const ts = useTranslate();

  const classes = useStyles();

  const renderTextField = (params: TextFieldProps) => {
    return (
      <TextField
        {...params}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start">
                <EventIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  };

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson }: CurrentPersonContext) => {
        let browserLg = navigator.language;
        let userLanguage =
          currentPerson.language?.code.split('-')[0] || browserLg;
        let formatLg = userLanguage === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
        let format = isBelowSm ? formatLg : `${formatLg} hh:mm a`;

        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={userLanguage}
            localeText={
              frFR.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <MobileDateTimePicker
              format={format}
              ampm={true}
              disablePast
              value={selectedDate}
              onChange={(date: any) => {
                setSelectedDate(date);
                onAccept(date.toDate());
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  size: 'small',
                  inputProps: {
                    style: { padding: 0, maxWidth: isBelowSm ? 100 : 170 }
                  }
                }
              }}
              slots={{
                textField: renderTextField
              }}
            />
            <Tooltip title={ts('page.accessRights.cancelExpirationDate')}>
              <Button
                id={TestIds.component.accessRightRequests.closeDatePicker}
                onClick={() => onDecline()}
                aria-label={ts('page.accessRights.cancelExpirationDate')}
                component="span"
                data-testid={
                  TestIds.component.accessRightRequests.closeDatePicker
                }
                className={classes.buttonIcon}
                disableRipple={true}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </Button>
            </Tooltip>
          </LocalizationProvider>
        );
      }}
    </CurrentPerson.Consumer>
  );
};

export default DatePickerButton;
