import { colors, palette } from 'Theme/constants';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorActionPrimaryActive, CdsColorBackgroundPrimaryLight } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  apiActionMenuItem: {
    width: theme.spacing(18),
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: palette.text.secondary,
    cursor: 'default',
    '&:hover': {
      backgroundColor: `${CdsColorBackgroundPrimaryLight} !important`,
      color: `${CdsColorActionPrimaryActive} !important`,
      cursor: 'pointer'
    }
  },
  defaultAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4)
  },
  body2: {
    opacity: 0.6
  },
  card: {
    width: '212px',
    minHeight: '212px',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  cards: {
    height: 'auto',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  keyIcon: {
    color: '#8bc34a'
  },
  moreButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    '&:hover': {
      backgroundColor: `${colors.blue[30]} !important`,
      color: `${colors.blue[500]} !important`
    }
  },
  noApiKeyIcon: {
    marginBottom: theme.spacing(4),
    '@media screen and (max-height: 500px)': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  noApiKeyH5: {
    marginBottom: theme.spacing(1)
  },
  noApiKeySection: {
    color: palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '28%',
    '@media screen and (max-height: 500px)': {
      paddingTop: 0
    }
  },
  popperAction: {
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  },
  popperPaper: {
    maxWidth: '300px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
      1
    )} ${theme.spacing(2)}`,
    textAlign: 'justify'
  },
  selectModal: {
    zIndex: `${theme.zIndex.appBar + 2} !important` as any,
    marginTop: '50px'
  },
  subtitle1: {
    opacity: 0.87,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '56px'
  },
  wordBreak: {
    wordBreak: 'break-word'
  }
}));
