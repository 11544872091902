import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    height: '100%',
    minHeight: 148
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  titleChart: {
    marginTop: -30
  },
  tooltip: {
    background: '#757575',
    '& p': {
      color: 'white'
    },
    '& svg': {
      width: 15,
      marginRight: 5
    },
    '&::after': {
      content: "' '",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '5px 5px 0 5px',
      borderColor: '#757575 transparent transparent transparent',
      position: 'absolute',
      bottom: '-5px',
      left: '50%'
    }
  }
}));
