import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { GetMe } from '../Apollo/queries/person/GetMe';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import CurrentPerson from '../Context/CurrentPerson';
import { GetPersonAdministrationRights } from '../Apollo';
import { IntlProvider } from 'react-intl';
import { getSupportedLocale, messages } from '../Languages';
import ErrorBoundary from '../Components/Shared/ErrorBoundary';

import E500 from '../Components/Shared/Errors/E500';
import { useAppInsightsContext } from '../Logs/AppInsights.context';
import SnackbarProvider from 'Components/Shared/Snackbar/SnackbarProvider';

interface IPrivateRoute extends RouteProps {}

/**
 * Verifies cookie session and feature flag. Get user with account profile.
 * @returns Children component with Person information in context.
 */
const PrivateRoute = (props: IPrivateRoute) => {
  const { children, ...rest } = props;
  const ai = useAppInsightsContext();

  const { data, loading } = useQuery<IMe>(GetMe, {
    onCompleted: data => {
      const immutableId = data.me?.immutableId || '';
      ai.setAuthenticatedUserContext(immutableId, data.me?.account?.code ?? '');
    }
  });

  const { me: currentPerson } = data || {};

  const { data: accessRights, loading: loadingAccessRigths } = useQuery<
    IGetPersonAdministrationRights
  >(GetPersonAdministrationRights, {
    skip: !currentPerson?.immutableId,
    variables: {}
  });

  if (loading || loadingAccessRigths) {
    return <LinearProgress color="secondary" />;
  }

  const { administrationResourceAccess } = accessRights || {};

  const { __typename, ...resourcesAccess } = administrationResourceAccess ?? {};

  if (!currentPerson || !administrationResourceAccess) {
    return <E500 />;
  }

  const personLocales = !currentPerson?.language
    ? []
    : [currentPerson.language.code];
  const browserLocales = window.navigator.languages || [];
  const locale = getSupportedLocale(personLocales.concat(browserLocales));

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <SnackbarProvider>
        <ErrorBoundary locale={locale}>
          <ErrorHandler.Provider>
            <CurrentPerson.Provider
              currentPerson={currentPerson}
              resourceAccess={resourcesAccess as IAdministrationResourceAccess}
            >
              <Switch>
                <Route {...rest} children={() => children} />
              </Switch>
            </CurrentPerson.Provider>
          </ErrorHandler.Provider>
        </ErrorBoundary>
      </SnackbarProvider>
    </IntlProvider>
  );
};

export default PrivateRoute;
