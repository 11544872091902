import { gql } from '@apollo/client';
import { NotificationSettingsFragment } from './NotificationSettings';

// TODO: we can't use the Account fragment here because /accounts is of type AccountWithSubscriptions
// We need to figure out a way to create a fragment that can be shared between Account and AccountWithSubscriptions.
// Or perhaps we should not have two types for the same object.
export const AccountWithNotificationSettingsFragment = gql`
  fragment AccountWithNotificationSettings on AccountWithSubscriptions {
    code
    name
    active
    type
    accountingStatus
    phase
    zipCode
    city
    notificationSettings {
      ...NotificationSettings
    }
  }
  ${NotificationSettingsFragment}
`;
