import {
  CircularProgress,
  Table,
  TableBody,
  TableRow
} from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import EmptyState from 'Components/Shared/EmptyState';
import AccessRightsTableRow from './AccessRightsTableRow';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './AccessRightsTable.styles';
import { TableCollectionPagination } from 'Theme/components/TableCollection/TableCollectionPagination';
import { calculateNewPage } from 'Tools/calculateNewPage';

interface Props {
  testId?: string;
  currentPage: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (v: number) => void;
  loading: boolean;
  accessRights: IPersonAccessRight[];
  totalCount: number;
  onRefetch: () => void;
  isRemovalAllowed?: boolean;
  isMobileP: boolean;
  serviceSubscription?: IServiceSubscription;
}

function AccessRightsTable(props: Readonly<Props>) {

  const { isMobileP } = props;
  const classes = useStyles();
  const ts = useTranslate();

  const handleChangeRowperPage = (newRowsPerPage: number) => {
    props.onChangeRowsPerPage(newRowsPerPage);
    props.onChangePage(
      calculateNewPage(props.currentPage, props.rowsPerPage, newRowsPerPage)
    );
  };

  return (
    <>
      <Table data-testid={props.testId}>
        <TableBody>
          {props.loading && (
            <TableRow>
              <td>
                <EmptyState
                  icon={<CircularProgress size={44} color="inherit" />}
                  primary={' '}
                  secondary={' '}
                  className={classes.circularClearer}
                />
              </td>
            </TableRow>
          )}
          {!props.loading &&
            props.accessRights !== undefined &&
            props.accessRights.length === 0 ? (
            <TableRow>
              <td>
                <EmptyState
                  icon={<BubbleChartIcon color="inherit" />}
                  primary={ts('page.userProfile.services.emptyStateTitle')}
                  secondary={ts('page.userProfile.services.emptyStateText')}
                />
              </td>
            </TableRow>
          ) : (
            props.accessRights.map(accessRight => (
              <AccessRightsTableRow
                serviceSubscriptionIdentifier={{
                  accountCode: accessRight.account?.code as string,
                  serviceCode: accessRight.service.groupCode
                }}
                key={[
                  accessRight.service && accessRight.service.groupCode,
                  accessRight.account && accessRight.account.code
                ]
                  .filter(Boolean)
                  .join('-')}
                accessRight={accessRight}
                onRefetch={props.onRefetch}
                isRemovalAllowed={props.isRemovalAllowed ? props.isRemovalAllowed : false}
                isMobile={isMobileP}
              />
            ))
          )}
        </TableBody>
      </Table>
      <TableCollectionPagination
        variant='outlined'
        rowsPerPage={props.rowsPerPage}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
        onChangeRowsPerPage={handleChangeRowperPage}
        page={props.currentPage}
        onChangePage={(page: number) => props.onChangePage(page)}
        totalCount={props.totalCount}
      />
    </>
  );
}

export default AccessRightsTable;
