import { Link, Typography } from '@mui/material';
import CurrentPerson from 'Context/CurrentPerson';
import useTranslate from 'Hooks/useTranslate';
import { PersonAvatar } from 'Theme/components/Avatar';
import { userFullName } from 'Tools';
import Account from './Account';
import Administrators from './Administrators';
import Directories from './Directories';
import LastActivities from './LastActivities';
import PersonalInformationContainer from './PersonalInformation/PersonalInformation.container';
import { useStyles } from './TabGeneral.styles';
import TechnicalInformation from './TechnicalInformation/TechnicalInformation';
import { ActivationStatus } from 'Apollo';
import AlertDisabledAccount from 'Components/AlertDisabledAccount';
import { useState } from 'react';
import ModalDataPrivacy from 'Components/ModalDataPrivacy/ModalDataPrivacy';
import { CdsColorActionNeutralActive } from 'Theme/constants/tokens';

interface Props {
  person: IPersonWithAccountAndDirectory;
}

function TabGeneral(props: Readonly<Props>) {
  const classes = useStyles();
  const ts = useTranslate();
  const isDisabled = props.person?.status === ActivationStatus.Disabled;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson, currentPersonRoles }: CurrentPersonContext) => (
        <>
          {isDisabled && <AlertDisabledAccount />}
          <div className={classes.header}>
            <PersonAvatar
              component="span"
              person={props.person}
              size="larger"
              className={classes.headerAvatar}
              isDisabled={isDisabled}
            />

            {currentPerson.immutableId === props.person.immutableId && (
              <Typography variant="h5" color="textSecondary">
                {ts('common.welcome')}
              </Typography>
            )}
            <Typography variant="h5">
              {userFullName(props.person.firstName, props.person.lastName)}
            </Typography>
          </div>
          <PersonalInformationContainer person={props.person} />
          <Account person={props.person} />
          {!isDisabled && <Administrators person={props.person} />}
          {!isDisabled && <Directories person={props.person} />}
          <LastActivities person={props.person} />
          {currentPersonRoles.hasAccessToAccounts && (
            <TechnicalInformation person={props.person} />
          )}
          {currentPerson.immutableId === props.person.immutableId && (
            <Typography variant="caption" color="textSecondary" paragraph textAlign={'justify'}>
              {ts('dataPrivacy.userProfileDataPrivacy')}{' '}
              <Link 
                color={CdsColorActionNeutralActive}
                type="button"
                aria-label={ts('common.action.see.seeMore')}
                underline='hover'
                onClick={()=> setModalIsOpen(true)} 
                style={{cursor: 'pointer'}}
              >
                {ts('common.action.see.seeMore')}...
              </Link>
            </Typography>
          )}
          {modalIsOpen && (
            <ModalDataPrivacy
              isOpen={modalIsOpen}
              onClose={() => {
                setModalIsOpen(false);
              }}
            />
          )}
        </>
      )}
    </CurrentPerson.Consumer>
  );
}

export default TabGeneral;
