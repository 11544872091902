import { useState } from 'react';
import { PersonAccessRightStatus } from 'Apollo/fragments/person/PersonAccessRight';
import TestIds from 'Tests/TestIds';
import { Button, useMediaQuery } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import CurrentPerson from 'Context/CurrentPerson';
import FilteredServices from '../FilteredServices';
import useTranslate from '../../../../../Hooks/useTranslate';
import AddServicesDrawerContainer from '../AddServicesDrawer/AddServicesDrawer.container';
import { ActivationStatus } from 'Apollo';

interface Props {
  person: IPersonWithAccount;
}

const AcceptedServices = (props: Props) => {
  const { person } = props;
  const [isAddingServices, setIsAddingServices] = useState(false);
  const ts = useTranslate();

  const isMobileP = useMediaQuery(
    '(max-width:600px) and (orientation: portrait)'
  );

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson, currentPersonRoles }: CurrentPersonContext) => {
        const isAdminMode = currentPersonRoles.isAccountAdmin;
        const isDisabled = person?.status === ActivationStatus.Disabled;

        const canWrite =
          !isDisabled &&
          (isAdminMode || currentPerson.immutableId === person.immutableId);

        return (
          <>
            <FilteredServices
              person={person}
              titleId={'page.services.title'}
              helpId={'page.userProfile.acceptedServices.help'}
              emptyStatePrimary={'page.userProfile.services.emptyStateTitle'}
              emptyStateSecondary={'page.userProfile.services.emptyStateText'}
              tableTestIds={TestIds.pages.profile.acceptedServices.table}
              servicesStatus={PersonAccessRightStatus.Accepted}
              secondaryAction={
                canWrite && (
                  <Button
                    color="primary"
                    onClick={() => setIsAddingServices(true)}
                    startIcon={<AddIcon />}
                    data-testid={
                      TestIds.pages.profile.services.addServiceButton
                    }
                    style={{ paddingRight: isMobileP ? 0 : 'inherit' }}
                  >
                    {ts('page.userProfile.services.add')}
                  </Button>
                )
              }
              isRemovalAllowed={canWrite}
            />
            {isAddingServices && (
              <AddServicesDrawerContainer
                currentPerson={currentPerson}
                person={person}
                isAdminMode={isAdminMode}
                onclose={() => {
                  setIsAddingServices(false);
                }}
                isMobileP={isMobileP}
              />
            )}
          </>
        );
      }}
    </CurrentPerson.Consumer>
  );
};

export default AcceptedServices;
