import {
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ExpandMore as IconExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { TableCollectionColumn } from 'Theme/components/TableCollection/TableCollection';
import { TableCollectionColumnSelection } from 'Theme/components/TableCollection';
import FormAddServicesToGroupContainer from 'Components/FormAddServicesToGroup/FormAddServicesToGroup.container';
import { useStyles } from './AddServicesToGroupDrawer.styles';
import TableGroupAvailableServicesContainer from 'Components/TableGroupAvailableServices/TableGroupAvailableServices.container';
import TableDrawer from 'Components/Shared/TableDrawer';
import LocalStorage from 'Tools/localStorage';
import { useColumnsGroupServices } from 'Hooks/useColumnsGroupServices';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddServicesToGroupDrawer = (props: Props) => {
  const { isOpen, onClose } = props;

  const classes = useStyles();
  const ts = useTranslate();

  const isMobileP = useMediaQuery(
    '(max-width:600px) and (orientation: portrait)'
  );

  let initialColumns = useColumnsGroupServices({});
  let initialLightColumns = useColumnsGroupServices({
    isServiceHidden: false,
    isDescriptionHidden: false,
    isFamilyHidden: true,
    isAccountHidden: false,
    isAdminValidationHidden: true,
    isAccessByDefaultHidden: true
  })

  const [columns, setColumns] = useState<TableCollectionColumn[]>(
    LocalStorage.deserializeColumns(
      useColumnsGroupServices({
        isAdminValidationHidden: true,
        isAccessByDefaultHidden: true
      }),
      LocalStorage.GroupAvailabledServices ?? []
    )
  );

  const [lightColumns, setLightColumns] = useState(
    LocalStorage.deserializeColumns(
      useColumnsGroupServices({
        isDescriptionHidden: true,
        isFamilyHidden: true,
        isAccountHidden: true,
        isAdminValidationHidden: true,
        isAccessByDefaultHidden: true
      }),
      LocalStorage.LightGroupAvailabledServices ?? []
    )
  );
  const [selectedIds, setSelectedIds] = useState<string[] | null>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleClose = () => {
    onClose();
    setSelectedIds([]);
  };

  return (
    <TableDrawer isOpen={isOpen}>
      <AccordionSummary classes={{ content: classes.expansionPanelSummary }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" className={classes.drawerTitle}>
              {ts('page.groupProfile.services.subscription.addServicesTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <TableCollectionColumnSelection
              columns={isMobileP ? lightColumns : columns}
              onChange={newColumns => {
                if(isMobileP) {
                  setLightColumns(newColumns);
                  LocalStorage.LightGroupAvailabledServices = LocalStorage.serializeColumns(
                    newColumns
                  );
                } else {
                  setColumns(newColumns);
                  LocalStorage.GroupAvailabledServices = LocalStorage.serializeColumns(
                    newColumns
                  );
                }
              }}
              onReset={()=> {
                if(isMobileP) {
                  setLightColumns(initialLightColumns);
                  LocalStorage.LightGroupAvailabledServices = null;
                } else {
                  setColumns(initialColumns);
                  LocalStorage.GroupAvailabledServices = null;
                }
              }}
            />
            <IconButton
              disableRipple
              onClick={() => onClose()}
              data-testid={
                TestIds.pages.groupProfile.services.addServices
                  .closeAddOnServicesDrawerExpandMore
              }
              size="large">
              <IconExpandMore />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TableGroupAvailableServicesContainer
          selectedIds={selectedIds ?? []}
          onUpdate={(ids: string[] | null) => {
            setSelectedIds(ids);
          }}
          updateTotalCount={(v: number) => setTotalCount(v)}
          columns={isMobileP ? lightColumns : columns}
        />
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <FormAddServicesToGroupContainer
          onCancel={handleClose}
          selectedIds={selectedIds}
          totalCount={selectedIds === null ? totalCount : selectedIds.length}
          testid={TestIds.pages.groupProfile.services.addServices.addButton}
        />
      </AccordionActions>
    </TableDrawer>
  );
};

export default AddServicesToGroupDrawer;
