import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'Theme/constants';

export const useStyles = makeStyles(theme => ({
  card: {
    width: '212px',
    minHeight: '212px',
    margin: `0px ${theme.spacing(1)} ${theme.spacing(2)}`,
    display: 'flex',
    flexDirection: 'column'
  },
  tabsBarLeft: {
    paddingLeft: '1em',
    justifyContent: 'flex-start',
    width: '30%',
    display: 'flex',
    alignItems: 'center'
  },
  tabsBarRight: {
    paddingRight: '1em',
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    justifyContent: 'flex-end'
  },
  icons: {
    marginRight: '8px'
  },
  refreshButton: {
    padding: '6px 0px 6px 4px',
    marginRight: theme.spacing(1)
  },
  selectModal: {
    zIndex: `${theme.zIndex.appBar + 2} !important` as any,
    marginTop: '50px'
  },
  subtitle1: {
    opacity: 0.87,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '56px'
  },
  tabsBar: {
    backgroundColor: colors.white,
    margin: `-${theme.spacing(4)} -${theme.spacing(3)} 0px -${theme.spacing(3)}`,
    paddingTop: theme.spacing(1),
    display: 'flex',
    boxShadow: 'inset 1px 1px 0px 0px #0000000a',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media screen and (max-width: 310px)': {
      flexDirection: 'column'
    }
  }
}));
