import { useColumnsCandidates } from 'Hooks/useColumnsCandidates';
import useTableHandlers from 'Hooks/useTableHandlers';
import { TableCollection } from 'Theme/components/TableCollection';
import { useState } from 'react';

interface Props {
  rows: ILightPersonProfile[];
  loading: boolean;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  selected: string[];
  onChangePage: (page: number) => void;
  onChangeRowPerPage: (rowsPerPage: number) => void;
  onUpdateSelection: (ids: string[])=> void;
}

const TableServiceOwnerCandidates = (props: Props) => {

  const {
    rows, loading, totalCount, page, rowsPerPage, selected,
    onChangePage, onChangeRowPerPage, onUpdateSelection
  } = props;
  const columns = useColumnsCandidates();

  const onUpdate = (ids: string[]) => {
    setSelectedIds(ids);
    onUpdateSelection(ids)
  };

  const [selectedIds, setSelectedIds] = useState<string[]>(selected ?? []);

  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });

  return (
    <TableCollection
      getRowId={r => r.immutableId}
      columns={columns}
      rows={rows}
      loading={loading}
      totalCount={totalCount}
      page={page}
      selectedIds={selectedIds}
      rowsPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowPerPage}
      maxSelected={1000}
      isSelectAllDisabled
      onUnselect={handleUnselect}
      onSelect={handleSelect}
    />
  );
};

export default TableServiceOwnerCandidates;
