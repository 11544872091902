import { Theme } from "@mui/material";


import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme: Theme) => ({
  noHover: {
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  cell: {
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1
    )} ${theme.spacing(2)}`
  },
  inheritedGroup: {
    fontWeight: 'bold'
  }
}))