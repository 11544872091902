import en from './en';
import fr from './fr';
import es from './es';
import pt from './pt';
import getSupportedLocale, { SupportedLocale } from './getSupportedLocale';

export type TTranslationMessagesList = Record<
  SupportedLocale,
  { [messageId: string]: string }
>;

const messages: TTranslationMessagesList = {
  en,
  fr,
  es,
  pt
};

export { getSupportedLocale, messages, SupportedLocale };
