import { ReactNode } from 'react';
import { default as MaterialTabs } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TProfileManagerMessagesKeys } from '../../../Languages/TProfileManagerMessages';
import useTranslate from '../../../Hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import useActiveTabSelection from 'Hooks/useActiveTabSelection';

export type TTabView = {
  title: TProfileManagerMessagesKeys;
  component: ReactNode;
  icon?: ReactNode;
  path?: string;
  link?: string;
  testid?: string;
};
interface Props {
  views: TTabView[];
}

const Tabs = ({ views }: Props) => {
  const history = useHistory();
  const ts = useTranslate();

  const indexTabSelection = useActiveTabSelection({ tabs: views });

  const isMobileP = useMediaQuery(
    '(max-width:600px) and (orientation: portrait)'
  );

  const handleClick = (link?: string) => {
    if (link) history.replace(link);
  };

  return (
    <MaterialTabs
      value={indexTabSelection}
      indicatorColor="primary"
      textColor="primary"
    >
      {views.map(v => {
        return (
          <Tab
            key={v.title}
            label={(isMobileP && v.icon) ? v.icon : ts(v.title)}
            onClick={() => handleClick(v.link)}
            aria-label={v.title}
            data-testid={v.testid}
          />
        );
      })}
    </MaterialTabs>
  );
};

export default Tabs;
