/**
 * Do not edit directly
 * Generated on Fri, 02 Dec 2022 10:15:39 GMT
 * Downloaded from https://cegid.jfrog.io/ui/packages/npm:%2F%2F@cegid%2Fdesign-tokens/3.1.1?name=%40cegid&type=packages
 */

export const CdsColorActionNeutralActive = "#002C52";
export const CdsColorActionNeutralInactive = "#002C52A3";
export const CdsColorActionNeutralDisabled = "#002C5252";
export const CdsColorActionPrimaryActive = "#0046FE";
export const CdsColorActionPrimaryDisabled = "#0046FE52";
export const CdsColorActionSecondaryActive = "#CC492A";
export const CdsColorActionSecondaryDisabled = "#CC492A52";
export const CdsColorActionWhiteActive = "#FFFFFF";
export const CdsColorActionWhiteInactive = "#FFFFFFA3";
export const CdsColorActionWhiteDisabled = "#FFFFFF52";
export const CdsColorActionInfoDarkActive = "#155AC1";
export const CdsColorActionInfoDarkDisabled = "#155AC152";
export const CdsColorActionCriticalMainActive = "#FF525D";
export const CdsColorActionCriticalMainDisabled = "#FF525D52";
export const CdsColorActionCriticalDarkActive = "#BC0E2E";
export const CdsColorActionCriticalDarkDisabled = "#BC0E2E52";
export const CdsColorActionWarnDarkActive = "#8B5000";
export const CdsColorActionWarnDarkDisabled = "#8B500052";
export const CdsColorActionSuccessDarkActive = "#376A1F";
export const CdsColorActionSuccessDarkDisabled = "#376A1F52";
export const CdsColorBackgroundNeutralMain = "#002C52";
export const CdsColorBackgroundNeutralMainDisabled = "#002C5252";
export const CdsColorBackgroundNeutralLight = "#F7F8F9";
export const CdsColorBackgroundPrimaryMain = "#0046FE";
export const CdsColorBackgroundPrimaryMainDisabled = "#0046FE52";
export const CdsColorBackgroundPrimaryLight = "#F7F9FE";
export const CdsColorBackgroundSecondaryMain = "#CC492A";
export const CdsColorBackgroundSecondaryMainDisabled = "#CC492A52";
export const CdsColorBackgroundSecondaryLight = "#FCF7F6";
export const CdsColorBackgroundInfoMain = "#598EF8";
export const CdsColorBackgroundInfoMainDisabled = "#598EF852";
export const CdsColorBackgroundInfoLight = "#EDF0FF";
export const CdsColorBackgroundInfoDark = "#155AC1";
export const CdsColorBackgroundInfoDarkDisabled = "#155AC152";
export const CdsColorBackgroundCriticalMain = "#FF525D";
export const CdsColorBackgroundCriticalMainDisabled = "#FF525D52";
export const CdsColorBackgroundCriticalLight = "#FFEDEC";
export const CdsColorBackgroundCriticalDark = "#BC0E2E";
export const CdsColorBackgroundCriticalDarkDisabled = "#BC0E2E52";
export const CdsColorBackgroundWarnMain = "#D27C00";
export const CdsColorBackgroundWarnMainDisabled = "#D27C0052";
export const CdsColorBackgroundWarnLight = "#FFEEE1";
export const CdsColorBackgroundWarnDark = "#8B5000";
export const CdsColorBackgroundWarnDarkDisabled = "#8B500052";
export const CdsColorBackgroundSuccessMain = "#689F4D";
export const CdsColorBackgroundSuccessMainDisabled = "#689F4D52";
export const CdsColorBackgroundSuccessLight = "#E3FFD0";
export const CdsColorBackgroundSuccessDark = "#376A1F";
export const CdsColorBackgroundSuccessDarkDisabled = "#376A1F52";
export const CdsColorBackgroundWhiteMain = "#FFFFFF";
export const CdsColorBackgroundWhiteMainDisabled = "#FFFFFF52";
export const CdsColorBorderNeutralHighEmphasis = "#002C52";
export const CdsColorBorderNeutralMediumEmphasis = "#002C52A3";
export const CdsColorBorderNeutralDisabled = "#002C5252";
export const CdsColorBorderNeutralDecorative = "#002C5233";
export const CdsColorBorderPrimaryHighEmphasis = "#0046FE";
export const CdsColorBorderPrimaryDisabled = "#0046FE52";
export const CdsColorBorderWhiteHighEmphasis = "#FFFFFF";
export const CdsColorBorderWhiteMediumEmphasis = "#FFFFFFA3";
export const CdsColorBorderWhiteDisabled = "#FFFFFF52";
export const CdsColorBorderCriticalMainHighEmphasis = "#FF525D";
export const CdsColorBorderCriticalMainDisabled = "#FF525D52";
export const CdsColorBorderCriticalDarkHighEmphasis = "#BC0E2E";
export const CdsColorBorderCriticalDarkDisabled = "#BC0E2E52";
export const CdsColorBrandPrimary0 = "#000000";
export const CdsColorBrandPrimary10 = "#000E33";
export const CdsColorBrandPrimary20 = "#001C66";
export const CdsColorBrandPrimary30 = "#002EAA";
export const CdsColorBrandPrimary40 = "#0038CC";
export const CdsColorBrandPrimary50 = "#0046FE";
export const CdsColorBrandPrimary60 = "#336BFF";
export const CdsColorBrandPrimary70 = "#6690FF";
export const CdsColorBrandPrimary80 = "#99B5FF";
export const CdsColorBrandPrimary90 = "#CCDAFF";
export const CdsColorBrandPrimary95 = "#E5EDFF";
export const CdsColorBrandPrimary99 = "#F7F9FE";
export const CdsColorBrandPrimary100 = "#FFFFFF";
export const CdsColorBrandSecondary0 = "#000000";
export const CdsColorBrandSecondary10 = "#88301C";
export const CdsColorBrandSecondary20 = "#B13F24";
export const CdsColorBrandSecondary30 = "#CC492A";
export const CdsColorBrandSecondary40 = "#E6502C";
export const CdsColorBrandSecondary50 = "#FF5C35";
export const CdsColorBrandSecondary60 = "#FF8466";
export const CdsColorBrandSecondary70 = "#FEAE99";
export const CdsColorBrandSecondary80 = "#FED6CC";
export const CdsColorBrandSecondary90 = "#FEEAE5";
export const CdsColorBrandSecondary95 = "#FBF2F0";
export const CdsColorBrandSecondary99 = "#FCF7F6";
export const CdsColorBrandSecondary100 = "#FFFFFF";
export const CdsColorBrandNeutral0 = "#000000";
export const CdsColorBrandNeutral10 = "#001D36";
export const CdsColorBrandNeutral20 = "#002C52";
export const CdsColorBrandNeutral30 = "#1A4163";
export const CdsColorBrandNeutral40 = "#335674";
export const CdsColorBrandNeutral50 = "#4D6B86";
export const CdsColorBrandNeutral60 = "#668097";
export const CdsColorBrandNeutral70 = "#8095A8";
export const CdsColorBrandNeutral80 = "#B3C0CB";
export const CdsColorBrandNeutral90 = "#CCD4DC";
export const CdsColorBrandNeutral95 = "#E6EAEE";
export const CdsColorBrandNeutral99 = "#F7F8F9";
export const CdsColorBrandNeutral100 = "#FFFFFF";
export const CdsColorBrandBananabread0 = "#000000";
export const CdsColorBrandBananabread10 = "#271900";
export const CdsColorBrandBananabread20 = "#422C00";
export const CdsColorBrandBananabread30 = "#5F4100";
export const CdsColorBrandBananabread40 = "#7D5800";
export const CdsColorBrandBananabread50 = "#9C6F02";
export const CdsColorBrandBananabread60 = "#B98823";
export const CdsColorBrandBananabread70 = "#D7A23D";
export const CdsColorBrandBananabread80 = "#FEC55C";
export const CdsColorBrandBananabread90 = "#FFDEA9";
export const CdsColorBrandBananabread95 = "#FFEED8";
export const CdsColorBrandBananabread99 = "#FFFBFF";
export const CdsColorBrandBananabread100 = "#FFFFFF";
export const CdsColorBrandBlackberry0 = "#000000";
export const CdsColorBrandBlackberry10 = "#001C3A";
export const CdsColorBrandBlackberry20 = "#00315F";
export const CdsColorBrandBlackberry30 = "#004480";
export const CdsColorBrandBlackberry40 = "#2D5F9D";
export const CdsColorBrandBlackberry50 = "#4979B8";
export const CdsColorBrandBlackberry60 = "#6492D3";
export const CdsColorBrandBlackberry70 = "#7FADF0";
export const CdsColorBrandBlackberry80 = "#A5C8FF";
export const CdsColorBrandBlackberry90 = "#D4E3FF";
export const CdsColorBrandBlackberry95 = "#EBF1FF";
export const CdsColorBrandBlackberry99 = "#FDFCFF";
export const CdsColorBrandBlackberry100 = "#FFFFFF";
export const CdsColorBrandBlueberry0 = "#000000";
export const CdsColorBrandBlueberry10 = "#001B3D";
export const CdsColorBrandBlueberry20 = "#003063";
export const CdsColorBrandBlueberry30 = "#00468C";
export const CdsColorBrandBlueberry40 = "#005DB7";
export const CdsColorBrandBlueberry50 = "#0084FF";
export const CdsColorBrandBlueberry60 = "#3C90FF";
export const CdsColorBrandBlueberry70 = "#79ACFF";
export const CdsColorBrandBlueberry80 = "#A9C7FF";
export const CdsColorBrandBlueberry90 = "#D6E3FF";
export const CdsColorBrandBlueberry95 = "#ECF0FF";
export const CdsColorBrandBlueberry99 = "#FDFBFF";
export const CdsColorBrandBlueberry100 = "#FFFFFF";
export const CdsColorBrandGingerbread0 = "#000000";
export const CdsColorBrandGingerbread10 = "#331200";
export const CdsColorBrandGingerbread20 = "#542200";
export const CdsColorBrandGingerbread30 = "#773300";
export const CdsColorBrandGingerbread40 = "#9C4500";
export const CdsColorBrandGingerbread50 = "#BD5C19";
export const CdsColorBrandGingerbread60 = "#E67B38";
export const CdsColorBrandGingerbread70 = "#FE8E49";
export const CdsColorBrandGingerbread80 = "#FFB68E";
export const CdsColorBrandGingerbread90 = "#FFDBCA";
export const CdsColorBrandGingerbread95 = "#FFEDE5";
export const CdsColorBrandGingerbread99 = "#FFFBFF";
export const CdsColorBrandGingerbread100 = "#FFFFFF";
export const CdsColorBrandLychee0 = "#000000";
export const CdsColorBrandLychee10 = "#2B1611";
export const CdsColorBrandLychee20 = "#432A25";
export const CdsColorBrandLychee30 = "#5B403A";
export const CdsColorBrandLychee40 = "#755751";
export const CdsColorBrandLychee50 = "#907069";
export const CdsColorBrandLychee60 = "#AB8982";
export const CdsColorBrandLychee70 = "#C7A39B";
export const CdsColorBrandLychee80 = "#EDC6BE";
export const CdsColorBrandLychee90 = "#FFDAD3";
export const CdsColorBrandLychee95 = "#FFEDE9";
export const CdsColorBrandLychee99 = "#FFFBFF";
export const CdsColorBrandLychee100 = "#FFFFFF";
export const CdsColorBrandPlum0 = "#000000";
export const CdsColorBrandPlum10 = "#001946";
export const CdsColorBrandPlum20 = "#152E61";
export const CdsColorBrandPlum30 = "#2E4579";
export const CdsColorBrandPlum40 = "#475D93";
export const CdsColorBrandPlum50 = "#6076AD";
export const CdsColorBrandPlum60 = "#7A90C9";
export const CdsColorBrandPlum70 = "#95ABE6";
export const CdsColorBrandPlum80 = "#B1C5FF";
export const CdsColorBrandPlum90 = "#DAE2FF";
export const CdsColorBrandPlum95 = "#EEF0FF";
export const CdsColorBrandPlum99 = "#FEFBFF";
export const CdsColorBrandPlum100 = "#FFFFFF";
export const CdsColorBrandTomato0 = "#000000";
export const CdsColorBrandTomato10 = "#3D0700";
export const CdsColorBrandTomato20 = "#621100";
export const CdsColorBrandTomato30 = "#8A1C00";
export const CdsColorBrandTomato40 = "#B52701";
export const CdsColorBrandTomato50 = "#D8411C";
export const CdsColorBrandTomato60 = "#FF5C35";
export const CdsColorBrandTomato70 = "#FF8B6E";
export const CdsColorBrandTomato80 = "#FFB4A3";
export const CdsColorBrandTomato90 = "#FFDAD2";
export const CdsColorBrandTomato95 = "#FFEDE9";
export const CdsColorBrandTomato99 = "#FFFBFF";
export const CdsColorBrandTomato100 = "#FFFFFF";
export const CdsColorDatavViz10 = "#E61A61";
export const CdsColorDatavViz11 = "#6076AD";
export const CdsColorDatavViz12 = "#895EDB";
export const CdsColorDatavViz13 = "#475D93";
export const CdsColorDatavViz14 = "#9CD67D";
export const CdsColorDatavViz15 = "#009FB4";
export const CdsColorDatavViz16 = "#B98823";
export const CdsColorDatavViz01 = "#336BFF";
export const CdsColorDatavViz02 = "#FEC55C";
export const CdsColorDatavViz03 = "#FF5C35";
export const CdsColorDatavViz04 = "#002C52";
export const CdsColorDatavViz05 = "#95ABE6";
export const CdsColorDatavViz06 = "#EDC6BE";
export const CdsColorDatavViz07 = "#0084FF";
export const CdsColorDatavViz08 = "#FFB2BE";
export const CdsColorDatavViz09 = "#B13F24";
export const CdsColorExtendedBlue0 = "#000000";
export const CdsColorExtendedBlue10 = "#001944";
export const CdsColorExtendedBlue20 = "#002D6D";
export const CdsColorExtendedBlue30 = "#004299";
export const CdsColorExtendedBlue40 = "#155AC1";
export const CdsColorExtendedBlue50 = "#3B73DC";
export const CdsColorExtendedBlue60 = "#598EF8";
export const CdsColorExtendedBlue70 = "#84AAFF";
export const CdsColorExtendedBlue80 = "#AFC6FF";
export const CdsColorExtendedBlue90 = "#D9E2FF";
export const CdsColorExtendedBlue95 = "#EDF0FF";
export const CdsColorExtendedBlue99 = "#FEFBFF";
export const CdsColorExtendedBlue100 = "#FFFFFF";
export const CdsColorExtendedBlueGrey0 = "#000000";
export const CdsColorExtendedBlueGrey10 = "#001F2A";
export const CdsColorExtendedBlueGrey20 = "#163440";
export const CdsColorExtendedBlueGrey30 = "#2E4B57";
export const CdsColorExtendedBlueGrey40 = "#466270";
export const CdsColorExtendedBlueGrey50 = "#5E7B89";
export const CdsColorExtendedBlueGrey60 = "#7895A4";
export const CdsColorExtendedBlueGrey70 = "#92B0BF";
export const CdsColorExtendedBlueGrey80 = "#ADCBDB";
export const CdsColorExtendedBlueGrey90 = "#C9E7F7";
export const CdsColorExtendedBlueGrey95 = "#E0F4FF";
export const CdsColorExtendedBlueGrey99 = "#FAFCFF";
export const CdsColorExtendedBlueGrey100 = "#FFFFFF";
export const CdsColorExtendedBrown0 = "#000000";
export const CdsColorExtendedBrown10 = "#2E150B";
export const CdsColorExtendedBrown20 = "#46291E";
export const CdsColorExtendedBrown30 = "#603F33";
export const CdsColorExtendedBrown40 = "#7A5649";
export const CdsColorExtendedBrown50 = "#956E60";
export const CdsColorExtendedBrown60 = "#B18779";
export const CdsColorExtendedBrown70 = "#CEA192";
export const CdsColorExtendedBrown80 = "#EBBCAC";
export const CdsColorExtendedBrown90 = "#FFDBCF";
export const CdsColorExtendedBrown95 = "#FFEDE7";
export const CdsColorExtendedBrown99 = "#FFFBFF";
export const CdsColorExtendedBrown100 = "#FFFFFF";
export const CdsColorExtendedCyan0 = "#000000";
export const CdsColorExtendedCyan10 = "#001F25";
export const CdsColorExtendedCyan20 = "#00363E";
export const CdsColorExtendedCyan30 = "#004E59";
export const CdsColorExtendedCyan40 = "#006876";
export const CdsColorExtendedCyan50 = "#008394";
export const CdsColorExtendedCyan60 = "#009FB4";
export const CdsColorExtendedCyan70 = "#00BCD4";
export const CdsColorExtendedCyan80 = "#44D8F1";
export const CdsColorExtendedCyan90 = "#A1EFFF";
export const CdsColorExtendedCyan95 = "#D4F7FF";
export const CdsColorExtendedCyan99 = "#F7FDFF";
export const CdsColorExtendedCyan100 = "#FFFFFF";
export const CdsColorExtendedDeepOrange0 = "#000000";
export const CdsColorExtendedDeepOrange10 = "#3B0900";
export const CdsColorExtendedDeepOrange20 = "#5F1500";
export const CdsColorExtendedDeepOrange30 = "#862200";
export const CdsColorExtendedDeepOrange40 = "#B02F00";
export const CdsColorExtendedDeepOrange50 = "#DB3E03";
export const CdsColorExtendedDeepOrange60 = "#FF5722";
export const CdsColorExtendedDeepOrange70 = "#FF8B69";
export const CdsColorExtendedDeepOrange80 = "#FFB5A0";
export const CdsColorExtendedDeepOrange90 = "#FFDBD1";
export const CdsColorExtendedDeepOrange95 = "#FFEDE8";
export const CdsColorExtendedDeepOrange99 = "#FFFBFF";
export const CdsColorExtendedDeepOrange100 = "#FFFFFF";
export const CdsColorExtendedDeepPurple0 = "#000000";
export const CdsColorExtendedDeepPurple10 = "#250059";
export const CdsColorExtendedDeepPurple20 = "#3F008D";
export const CdsColorExtendedDeepPurple30 = "#5727A7";
export const CdsColorExtendedDeepPurple40 = "#6F43C0";
export const CdsColorExtendedDeepPurple50 = "#895EDB";
export const CdsColorExtendedDeepPurple60 = "#A378F8";
export const CdsColorExtendedDeepPurple70 = "#BB99FF";
export const CdsColorExtendedDeepPurple80 = "#D3BBFF";
export const CdsColorExtendedDeepPurple90 = "#EBDDFF";
export const CdsColorExtendedDeepPurple95 = "#F7EDFF";
export const CdsColorExtendedDeepPurple99 = "#FFFBFF";
export const CdsColorExtendedDeepPurple100 = "#FFFFFF";
export const CdsColorExtendedGreen0 = "#000000";
export const CdsColorExtendedGreen10 = "#072100";
export const CdsColorExtendedGreen20 = "#103900";
export const CdsColorExtendedGreen30 = "#1F5106";
export const CdsColorExtendedGreen40 = "#376A1F";
export const CdsColorExtendedGreen50 = "#4F8436";
export const CdsColorExtendedGreen60 = "#689F4D";
export const CdsColorExtendedGreen70 = "#81BA64";
export const CdsColorExtendedGreen80 = "#9CD67D";
export const CdsColorExtendedGreen90 = "#B7F396";
export const CdsColorExtendedGreen95 = "#CDFFB0";
export const CdsColorExtendedGreen99 = "#F7FFEC";
export const CdsColorExtendedGreen100 = "#FFFFFF";
export const CdsColorExtendedOrange0 = "#000000";
export const CdsColorExtendedOrange10 = "#2C1600";
export const CdsColorExtendedOrange20 = "#4A2800";
export const CdsColorExtendedOrange30 = "#693C00";
export const CdsColorExtendedOrange40 = "#8B5000";
export const CdsColorExtendedOrange50 = "#AE6600";
export const CdsColorExtendedOrange60 = "#D27C00";
export const CdsColorExtendedOrange70 = "#F79300";
export const CdsColorExtendedOrange80 = "#FFB870";
export const CdsColorExtendedOrange90 = "#FFDCBE";
export const CdsColorExtendedOrange95 = "#FFEEE1";
export const CdsColorExtendedOrange99 = "#FFFBFF";
export const CdsColorExtendedOrange100 = "#FFFFFF";
export const CdsColorExtendedPink0 = "#000000";
export const CdsColorExtendedPink10 = "#400014";
export const CdsColorExtendedPink20 = "#660025";
export const CdsColorExtendedPink30 = "#900038";
export const CdsColorExtendedPink40 = "#BC004B";
export const CdsColorExtendedPink50 = "#E61A61";
export const CdsColorExtendedPink60 = "#FF4E7C";
export const CdsColorExtendedPink70 = "#FF869D";
export const CdsColorExtendedPink80 = "#FFB2BE";
export const CdsColorExtendedPink90 = "#FFD9DE";
export const CdsColorExtendedPink95 = "#FFECEE";
export const CdsColorExtendedPink99 = "#FFFBFF";
export const CdsColorExtendedPink100 = "#FFFFFF";
export const CdsColorExtendedStrawberry0 = "#000000";
export const CdsColorExtendedStrawberry10 = "#410008";
export const CdsColorExtendedStrawberry20 = "#680013";
export const CdsColorExtendedStrawberry30 = "#92001F";
export const CdsColorExtendedStrawberry40 = "#BC0E2E";
export const CdsColorExtendedStrawberry50 = "#E03043";
export const CdsColorExtendedStrawberry60 = "#FF525D";
export const CdsColorExtendedStrawberry70 = "#FF8889";
export const CdsColorExtendedStrawberry80 = "#FFB3B2";
export const CdsColorExtendedStrawberry90 = "#FFDAD9";
export const CdsColorExtendedStrawberry95 = "#FFEDEC";
export const CdsColorExtendedStrawberry99 = "#FFFBFF";
export const CdsColorExtendedStrawberry100 = "#FFFFFF";
export const CdsColorExtendedYellow0 = "#000000";
export const CdsColorExtendedYellow10 = "#231B00";
export const CdsColorExtendedYellow20 = "#3C2F00";
export const CdsColorExtendedYellow30 = "#564500";
export const CdsColorExtendedYellow40 = "#725C03";
export const CdsColorExtendedYellow50 = "#8D7520";
export const CdsColorExtendedYellow60 = "#A88E38";
export const CdsColorExtendedYellow70 = "#C5A94F";
export const CdsColorExtendedYellow80 = "#E2C468";
export const CdsColorExtendedYellow90 = "#FFE081";
export const CdsColorExtendedYellow95 = "#FFF0C9";
export const CdsColorExtendedYellow99 = "#FFFBFF";
export const CdsColorExtendedYellow100 = "#FFFFFF";
export const CdsColorExtendedGrey0 = "#000000";
export const CdsColorExtendedGrey10 = "#1B1B1B";
export const CdsColorExtendedGrey20 = "#303030";
export const CdsColorExtendedGrey30 = "#474747";
export const CdsColorExtendedGrey40 = "#5E5E5E";
export const CdsColorExtendedGrey50 = "#777777";
export const CdsColorExtendedGrey60 = "#919191";
export const CdsColorExtendedGrey70 = "#ABABAB";
export const CdsColorExtendedGrey80 = "#C6C6C6";
export const CdsColorExtendedGrey90 = "#E2E2E2";
export const CdsColorExtendedGrey95 = "#F1F1F1";
export const CdsColorExtendedGrey99 = "#FCFCFC";
export const CdsColorExtendedGrey100 = "#FFFFFF";
export const CdsColorExtendedWhite = "#FFFFFF";
export const CdsColorExtendedBlack = "#000000";
export const CdsColorInteractionNeutralResting = "#002C5200";
export const CdsColorInteractionNeutralHovered = "#002C520F";
export const CdsColorInteractionNeutralDisabled = "#002C520F";
export const CdsColorInteractionNeutralSelected = "#002C521A";
export const CdsColorInteractionNeutralActivated = "#002C521F";
export const CdsColorInteractionNeutralDragged = "#002C5224";
export const CdsColorInteractionNeutralFocused = "#002C5229";
export const CdsColorInteractionNeutralPressed = "#002C5233";
export const CdsColorInteractionPrimaryResting = "#0046FE00";
export const CdsColorInteractionPrimaryHovered = "#0046FE0F";
export const CdsColorInteractionPrimaryDisabled = "#0046FE0F";
export const CdsColorInteractionPrimarySelected = "#0046FE1A";
export const CdsColorInteractionPrimaryActivated = "#0046FE1F";
export const CdsColorInteractionPrimaryDragged = "#0046FE24";
export const CdsColorInteractionPrimaryFocused = "#0046FE29";
export const CdsColorInteractionPrimaryPressed = "#0046FE33";
export const CdsColorInteractionSecondaryResting = "#CC492A00";
export const CdsColorInteractionSecondaryHovered = "#CC492A0F";
export const CdsColorInteractionSecondaryDisabled = "#CC492A0F";
export const CdsColorInteractionSecondarySelected = "#CC492A1A";
export const CdsColorInteractionSecondaryActivated = "#CC492A1F";
export const CdsColorInteractionSecondaryDragged = "#CC492A24";
export const CdsColorInteractionSecondaryFocused = "#CC492A29";
export const CdsColorInteractionSecondaryPressed = "#CC492A33";
export const CdsColorInteractionInfoResting = "#598EF800";
export const CdsColorInteractionInfoHovered = "#598EF80F";
export const CdsColorInteractionInfoDisabled = "#598EF80F";
export const CdsColorInteractionInfoSelected = "#598EF81A";
export const CdsColorInteractionInfoActivated = "#598EF81F";
export const CdsColorInteractionInfoDragged = "#598EF824";
export const CdsColorInteractionInfoFocused = "#598EF829";
export const CdsColorInteractionInfoPressed = "#598EF833";
export const CdsColorInteractionCriticalResting = "#FF525D00";
export const CdsColorInteractionCriticalHovered = "#FF525D0F";
export const CdsColorInteractionCriticalDisabled = "#FF525D0F";
export const CdsColorInteractionCriticalSelected = "#FF525D1A";
export const CdsColorInteractionCriticalActivated = "#FF525D1F";
export const CdsColorInteractionCriticalDragged = "#FF525D24";
export const CdsColorInteractionCriticalFocused = "#FF525D29";
export const CdsColorInteractionCriticalPressed = "#FF525D33";
export const CdsColorInteractionWarnResting = "#D27C0000";
export const CdsColorInteractionWarnHovered = "#D27C000F";
export const CdsColorInteractionWarnDisabled = "#D27C000F";
export const CdsColorInteractionWarnSelected = "#D27C001A";
export const CdsColorInteractionWarnActivated = "#D27C001F";
export const CdsColorInteractionWarnDragged = "#D27C0024";
export const CdsColorInteractionWarnFocused = "#D27C0029";
export const CdsColorInteractionWarnPressed = "#D27C0033";
export const CdsColorInteractionSuccessResting = "#689F4D00";
export const CdsColorInteractionSuccessHovered = "#689F4D0F";
export const CdsColorInteractionSuccessDisabled = "#689F4D0F";
export const CdsColorInteractionSuccessSelected = "#689F4D1A";
export const CdsColorInteractionSuccessActivated = "#689F4D1F";
export const CdsColorInteractionSuccessDragged = "#689F4D24";
export const CdsColorInteractionSuccessFocused = "#689F4D29";
export const CdsColorInteractionSuccessPressed = "#689F4D33";
export const CdsColorInteractionWhiteResting = "#FFFFFF00";
export const CdsColorInteractionWhiteHovered = "#FFFFFF0F";
export const CdsColorInteractionWhiteDisabled = "#FFFFFF0F";
export const CdsColorInteractionWhiteSelected = "#FFFFFF1A";
export const CdsColorInteractionWhiteActivated = "#FFFFFF1F";
export const CdsColorInteractionWhiteDragged = "#FFFFFF24";
export const CdsColorInteractionWhiteFocused = "#FFFFFF29";
export const CdsColorInteractionWhitePressed = "#FFFFFF33";
export const CdsColorSpecificBackdropNeutral = "#002C5280";
export const CdsColorSpecificSkeletonNeutral = "#002C521F";
export const CdsColorSpecificSkeletonWhite = "#FFFFFF1F";
export const CdsColorSpecificDividerNeutral = "#002C5233";
export const CdsColorTextNeutralHighEmphasis = "#002C52";
export const CdsColorTextNeutralMediumEmphasis = "#002C52A3";
export const CdsColorTextNeutralDisabled = "#002C5252";
export const CdsColorTextPrimaryHighEmphasis = "#0046FE";
export const CdsColorTextPrimaryDisabled = "#0046FE52";
export const CdsColorTextSecondaryHighEmphasis = "#CC492A";
export const CdsColorTextSecondaryDisabled = "#CC492A52";
export const CdsColorTextInfoHighEmphasis = "#155AC1";
export const CdsColorTextInfoDisabled = "#155AC152";
export const CdsColorTextCriticalHighEmphasis = "#BC0E2E";
export const CdsColorTextCriticalDisabled = "#BC0E2E52";
export const CdsColorTextWarnHighEmphasis = "#8B5000";
export const CdsColorTextWarnDisabled = "#8B500052";
export const CdsColorTextSuccessHighEmphasis = "#376A1F";
export const CdsColorTextSuccessDisabled = "#376A1F52";
export const CdsColorTextWhiteHighEmphasis = "#FFFFFF";
export const CdsColorTextWhiteMediumEmphasis = "#FFFFFFA3";
export const CdsColorTextWhiteDisabled = "#FFFFFF52";
export const CdsColorTextLinkNeutralResting = "#002C52A3";
export const CdsColorTextLinkNeutralHover = "#002C52";
export const CdsColorTextLinkNeutralActive = "#002C52";
export const CdsColorTextLinkNeutralFocused = "#002C52";
export const CdsColorTextLinkNeutralVisited = "#002C52A3";
export const CdsColorTextLinkNeutralDisabled = "#002C5252";
export const CdsColorTextLinkPrimaryResting = "#0046FE";
export const CdsColorTextLinkPrimaryHover = "#002EAA";
export const CdsColorTextLinkPrimaryActive = "#002EAA";
export const CdsColorTextLinkPrimaryFocused = "#002EAA";
export const CdsColorTextLinkPrimaryVisited = "#0046FE";
export const CdsColorTextLinkPrimaryDisabled = "#0046FE52";
export const CdsEffectElevationUmbra1OffsetX = "0px";
export const CdsEffectElevationUmbra1OffsetY = "1px";
export const CdsEffectElevationUmbra1BlurRadius = "1px";
export const CdsEffectElevationUmbra1SpreadRadius = "0px";
export const CdsEffectElevationUmbra2OffsetX = "0px";
export const CdsEffectElevationUmbra2OffsetY = "2px";
export const CdsEffectElevationUmbra2BlurRadius = "1px";
export const CdsEffectElevationUmbra2SpreadRadius = "-1px";
export const CdsEffectElevationUmbra4OffsetX = "0px";
export const CdsEffectElevationUmbra4OffsetY = "4px";
export const CdsEffectElevationUmbra4BlurRadius = "5px";
export const CdsEffectElevationUmbra4SpreadRadius = "0px";
export const CdsEffectElevationUmbra6OffsetX = "0px";
export const CdsEffectElevationUmbra6OffsetY = "6px";
export const CdsEffectElevationUmbra6BlurRadius = "10px";
export const CdsEffectElevationUmbra6SpreadRadius = "0px";
export const CdsEffectElevationUmbra8OffsetX = "0px";
export const CdsEffectElevationUmbra8OffsetY = "8px";
export const CdsEffectElevationUmbra8BlurRadius = "10px";
export const CdsEffectElevationUmbra8SpreadRadius = "1px";
export const CdsEffectElevationUmbra12OffsetX = "0px";
export const CdsEffectElevationUmbra12OffsetY = "12px";
export const CdsEffectElevationUmbra12BlurRadius = "17px";
export const CdsEffectElevationUmbra12SpreadRadius = "2px";
export const CdsEffectElevationUmbra24OffsetX = "0px";
export const CdsEffectElevationUmbra24OffsetY = "24px";
export const CdsEffectElevationUmbra24BlurRadius = "38px";
export const CdsEffectElevationUmbra24SpreadRadius = "3px";
export const CdsEffectElevationUmbraColor = "#001D3614";
export const CdsEffectElevationPenumbra1OffsetX = "0px";
export const CdsEffectElevationPenumbra1OffsetY = "2px";
export const CdsEffectElevationPenumbra1BlurRadius = "1px";
export const CdsEffectElevationPenumbra1SpreadRadius = "-1px";
export const CdsEffectElevationPenumbra2OffsetX = "0px";
export const CdsEffectElevationPenumbra2OffsetY = "3px";
export const CdsEffectElevationPenumbra2BlurRadius = "1px";
export const CdsEffectElevationPenumbra2SpreadRadius = "-2px";
export const CdsEffectElevationPenumbra4OffsetX = "0px";
export const CdsEffectElevationPenumbra4OffsetY = "1px";
export const CdsEffectElevationPenumbra4BlurRadius = "10px";
export const CdsEffectElevationPenumbra4SpreadRadius = "0px";
export const CdsEffectElevationPenumbra6OffsetX = "0px";
export const CdsEffectElevationPenumbra6OffsetY = "1px";
export const CdsEffectElevationPenumbra6BlurRadius = "18px";
export const CdsEffectElevationPenumbra6SpreadRadius = "0px";
export const CdsEffectElevationPenumbra8OffsetX = "0px";
export const CdsEffectElevationPenumbra8OffsetY = "3px";
export const CdsEffectElevationPenumbra8BlurRadius = "14px";
export const CdsEffectElevationPenumbra8SpreadRadius = "2px";
export const CdsEffectElevationPenumbra12OffsetX = "0px";
export const CdsEffectElevationPenumbra12OffsetY = "5px";
export const CdsEffectElevationPenumbra12BlurRadius = "22px";
export const CdsEffectElevationPenumbra12SpreadRadius = "4px";
export const CdsEffectElevationPenumbra24OffsetX = "0px";
export const CdsEffectElevationPenumbra24OffsetY = "9px";
export const CdsEffectElevationPenumbra24BlurRadius = "46px";
export const CdsEffectElevationPenumbra24SpreadRadius = "-8px";
export const CdsEffectElevationPenumbraColor = "#001D361F";
export const CdsEffectElevationAmbient1OffsetX = "0px";
export const CdsEffectElevationAmbient1OffsetY = "1px";
export const CdsEffectElevationAmbient1BlurRadius = "3px";
export const CdsEffectElevationAmbient1SpreadRadius = "0px";
export const CdsEffectElevationAmbient2OffsetX = "0px";
export const CdsEffectElevationAmbient2OffsetY = "1px";
export const CdsEffectElevationAmbient2BlurRadius = "5px";
export const CdsEffectElevationAmbient2SpreadRadius = "0px";
export const CdsEffectElevationAmbient4OffsetX = "0px";
export const CdsEffectElevationAmbient4OffsetY = "2px";
export const CdsEffectElevationAmbient4BlurRadius = "4px";
export const CdsEffectElevationAmbient4SpreadRadius = "-1px";
export const CdsEffectElevationAmbient6OffsetX = "0px";
export const CdsEffectElevationAmbient6OffsetY = "3px";
export const CdsEffectElevationAmbient6BlurRadius = "5px";
export const CdsEffectElevationAmbient6SpreadRadius = "-1px";
export const CdsEffectElevationAmbient8OffsetX = "0px";
export const CdsEffectElevationAmbient8OffsetY = "5px";
export const CdsEffectElevationAmbient8BlurRadius = "5px";
export const CdsEffectElevationAmbient8SpreadRadius = "-3px";
export const CdsEffectElevationAmbient12OffsetX = "0px";
export const CdsEffectElevationAmbient12OffsetY = "7px";
export const CdsEffectElevationAmbient12BlurRadius = "8px";
export const CdsEffectElevationAmbient12SpreadRadius = "-4px";
export const CdsEffectElevationAmbient24OffsetX = "0px";
export const CdsEffectElevationAmbient24OffsetY = "11px";
export const CdsEffectElevationAmbient24BlurRadius = "15px";
export const CdsEffectElevationAmbient24SpreadRadius = "-15px";
export const CdsEffectElevationAmbientColor = "#001D361F";
export const CdsSizeBreakpointXs = "0px";
export const CdsSizeBreakpointSm = "600px";
export const CdsSizeBreakpointMd = "960px";
export const CdsSizeBreakpointLg = "1280px";
export const CdsSizeBreakpointXl = "1920px";
export const CdsSizeSpacing = "8px";
export const CdsSizeRadius = "6px";
export const CdsSizeDivider = "1px";
export const CdsSizeBorder = "1px";
export const CdsSizeBorderBold = "2px";
export const CdsSizeSpecific = "40px";
export const CdsSizeSpecificXxs = "16px";
export const CdsSizeSpecificXs = "20px";
export const CdsSizeSpecificS = "24px";
export const CdsSizeSpecificM = "32px";
export const CdsSizeSpecificL = "56px";
export const CdsSizeSpecificXl = "72px";
export const CdsSizeSpecificXxl = "100px";
export const CdsOpacityDecorative = 20;
export const CdsOpacityDisabled = 32;
export const CdsOpacityHexa0 = "00";
export const CdsOpacityHexa1 = "03";
export const CdsOpacityHexa2 = "05";
export const CdsOpacityHexa3 = "08";
export const CdsOpacityHexa4 = "0A";
export const CdsOpacityHexa5 = "0D";
export const CdsOpacityHexa6 = "0F";
export const CdsOpacityHexa7 = "12";
export const CdsOpacityHexa8 = "14";
export const CdsOpacityHexa9 = "17";
export const CdsOpacityHexa10 = "1A";
export const CdsOpacityHexa11 = "1C";
export const CdsOpacityHexa12 = "1F";
export const CdsOpacityHexa13 = "21";
export const CdsOpacityHexa14 = "24";
export const CdsOpacityHexa15 = "26";
export const CdsOpacityHexa16 = "29";
export const CdsOpacityHexa17 = "2B";
export const CdsOpacityHexa18 = "2E";
export const CdsOpacityHexa19 = "30";
export const CdsOpacityHexa20 = "33";
export const CdsOpacityHexa21 = "36";
export const CdsOpacityHexa22 = "38";
export const CdsOpacityHexa23 = "3B";
export const CdsOpacityHexa24 = "3D";
export const CdsOpacityHexa25 = "40";
export const CdsOpacityHexa26 = "42";
export const CdsOpacityHexa27 = "45";
export const CdsOpacityHexa28 = "47";
export const CdsOpacityHexa29 = "4A";
export const CdsOpacityHexa30 = "4D";
export const CdsOpacityHexa31 = "4F";
export const CdsOpacityHexa32 = "52";
export const CdsOpacityHexa33 = "54";
export const CdsOpacityHexa34 = "57";
export const CdsOpacityHexa35 = "59";
export const CdsOpacityHexa36 = "5C";
export const CdsOpacityHexa37 = "5E";
export const CdsOpacityHexa38 = "61";
export const CdsOpacityHexa39 = "63";
export const CdsOpacityHexa40 = "66";
export const CdsOpacityHexa41 = "69";
export const CdsOpacityHexa42 = "6B";
export const CdsOpacityHexa43 = "6E";
export const CdsOpacityHexa44 = "70";
export const CdsOpacityHexa45 = "73";
export const CdsOpacityHexa46 = "75";
export const CdsOpacityHexa47 = "78";
export const CdsOpacityHexa48 = "7A";
export const CdsOpacityHexa49 = "7D";
export const CdsOpacityHexa50 = "7F";
export const CdsOpacityHexa51 = "82";
export const CdsOpacityHexa52 = "85";
export const CdsOpacityHexa53 = "87";
export const CdsOpacityHexa54 = "8A";
export const CdsOpacityHexa55 = "8C";
export const CdsOpacityHexa56 = "8F";
export const CdsOpacityHexa57 = "91";
export const CdsOpacityHexa58 = "94";
export const CdsOpacityHexa59 = "96";
export const CdsOpacityHexa60 = "99";
export const CdsOpacityHexa61 = "9C";
export const CdsOpacityHexa62 = "9E";
export const CdsOpacityHexa63 = "A1";
export const CdsOpacityHexa64 = "A3";
export const CdsOpacityHexa65 = "A6";
export const CdsOpacityHexa66 = "A8";
export const CdsOpacityHexa67 = "AB";
export const CdsOpacityHexa68 = "AD";
export const CdsOpacityHexa69 = "B0";
export const CdsOpacityHexa70 = "B3";
export const CdsOpacityHexa71 = "B5";
export const CdsOpacityHexa72 = "B8";
export const CdsOpacityHexa73 = "BA";
export const CdsOpacityHexa74 = "BD";
export const CdsOpacityHexa75 = "BF";
export const CdsOpacityHexa76 = "C2";
export const CdsOpacityHexa77 = "C4";
export const CdsOpacityHexa78 = "C7";
export const CdsOpacityHexa79 = "C9";
export const CdsOpacityHexa80 = "CC";
export const CdsOpacityHexa81 = "CF";
export const CdsOpacityHexa82 = "D1";
export const CdsOpacityHexa83 = "D4";
export const CdsOpacityHexa84 = "D6";
export const CdsOpacityHexa85 = "D9";
export const CdsOpacityHexa86 = "DB";
export const CdsOpacityHexa87 = "DE";
export const CdsOpacityHexa88 = "E0";
export const CdsOpacityHexa89 = "E3";
export const CdsOpacityHexa90 = "E5";
export const CdsOpacityHexa91 = "E8";
export const CdsOpacityHexa92 = "EB";
export const CdsOpacityHexa93 = "ED";
export const CdsOpacityHexa94 = "F0";
export const CdsOpacityHexa95 = "F2";
export const CdsOpacityHexa96 = "F5";
export const CdsOpacityHexa97 = "F7";
export const CdsOpacityHexa98 = "FA";
export const CdsOpacityHexa99 = "FC";
export const CdsOpacityHexa100 = "FF";
export const CdsOpacityInactive = 64;
export const CdsTypographyHeadline1Family = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyHeadline1FontSize = "56px";
export const CdsTypographyHeadline1FontWeight = "300";
export const CdsTypographyHeadline1LineHeight = "80px";
export const CdsTypographyHeadline1LetterSpacing = "-2px";
export const CdsTypographyHeadline2Family = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyHeadline2FontSize = "48px";
export const CdsTypographyHeadline2FontWeight = "300";
export const CdsTypographyHeadline2LineHeight = "64px";
export const CdsTypographyHeadline2LetterSpacing = "-1px";
export const CdsTypographyHeadline3Family = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyHeadline3FontSize = "38px";
export const CdsTypographyHeadline3FontWeight = "400";
export const CdsTypographyHeadline3LineHeight = "54px";
export const CdsTypographyHeadline3LetterSpacing = "-0.75px";
export const CdsTypographyHeadline4Family = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyHeadline4FontSize = "32px";
export const CdsTypographyHeadline4FontWeight = "400";
export const CdsTypographyHeadline4LineHeight = "44px";
export const CdsTypographyHeadline4LetterSpacing = "-0.75px";
export const CdsTypographyHeadline5Family = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyHeadline5FontSize = "24px";
export const CdsTypographyHeadline5FontWeight = "400";
export const CdsTypographyHeadline5LineHeight = "34px";
export const CdsTypographyHeadline5LetterSpacing = "-0.25px";
export const CdsTypographyHeadline6Family = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyHeadline6FontSize = "20px";
export const CdsTypographyHeadline6FontWeight = "600";
export const CdsTypographyHeadline6LineHeight = "28px";
export const CdsTypographyHeadline6LetterSpacing = "-0.25px";
export const CdsTypographySubtitle1Family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
export const CdsTypographySubtitle1FontSize = "16px";
export const CdsTypographySubtitle1FontWeight = "400";
export const CdsTypographySubtitle1LineHeight = "21px";
export const CdsTypographySubtitle1LetterSpacing = "0.15px";
export const CdsTypographySubtitle2Family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
export const CdsTypographySubtitle2FontSize = "14px";
export const CdsTypographySubtitle2FontWeight = "500";
export const CdsTypographySubtitle2LineHeight = "22px";
export const CdsTypographySubtitle2LetterSpacing = "0.1px";
export const CdsTypographyBody1Family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyBody1FontSize = "16px";
export const CdsTypographyBody1FontWeight = "400";
export const CdsTypographyBody1LineHeight = "28px";
export const CdsTypographyBody1LetterSpacing = "0.5px";
export const CdsTypographyBody2Family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyBody2FontSize = "14px";
export const CdsTypographyBody2FontWeight = "400";
export const CdsTypographyBody2LineHeight = "20px";
export const CdsTypographyBody2LetterSpacing = "0.25px";
export const CdsTypographyLabelFamily = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyLabelFontSize = "14px";
export const CdsTypographyLabelFontWeight = "500";
export const CdsTypographyLabelLineHeight = "20px";
export const CdsTypographyLabelLetterSpacing = "0.5px";
export const CdsTypographyCaptionFamily = "'Roboto', 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyCaptionFontSize = "12px";
export const CdsTypographyCaptionFontWeight = "400";
export const CdsTypographyCaptionLineHeight = "16px";
export const CdsTypographyCaptionLetterSpacing = "0.4px";
export const CdsTypographyOverlineFamily = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyOverlineFontSize = "11px";
export const CdsTypographyOverlineFontWeight = "600";
export const CdsTypographyOverlineLineHeight = "16px";
export const CdsTypographyOverlineLetterSpacing = "1.83px";
export const CdsTypographyButtonFamily = "Poppins, 'Helvetica', 'Arial', sans-serif";
export const CdsTypographyButtonFontSize = "14px";
export const CdsTypographyButtonFontWeight = "600";
export const CdsTypographyButtonLineHeight = "20px";
export const CdsTypographyButtonLetterSpacing = "0.5px";
