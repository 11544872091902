import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslate from 'Hooks/useTranslate';
import ButtonLoading from 'Theme/components/ButtonLoading';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(1),
    margin: `${theme.spacing(1)} 0`
  }
}));

interface Props {
  onCancel(): void;
  onSubmit(): void;
  loading: boolean;
  title: string;
}

const ConfirmRemove = ({ onCancel, onSubmit, loading, title }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  return (
    <Box m={5}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Typography variant="subtitle1" align="center">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.buttons}>
            <Button type="button" size="medium" onClick={onCancel} color='inherit'>
              {ts('common.action.cancel')}
            </Button>
            <ButtonLoading
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={loading}
              onClick={onSubmit}
            >
              {ts('common.action.validate')}
            </ButtonLoading>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmRemove;
