export enum SupportedLocale {
  en = 'en',
  fr = 'fr',
  es = 'es',
  pt = 'pt'
}

export const browserLocales = window.navigator.languages || [];
export const locale = getSupportedLocale(browserLocales);

function getSupportedLocale(languages: readonly string[]): SupportedLocale {
  const locales = languages.reduce(
    (acc: SupportedLocale[], language: string) => {
      const supportedLocale = [
        SupportedLocale.en,
        SupportedLocale.fr,
        SupportedLocale.es,
        SupportedLocale.pt
      ].find(locale => language.startsWith(locale));
      return supportedLocale ? acc.concat([supportedLocale]) : acc;
    },
    []
  );
  return locales.length > 0 ? locales[0] : SupportedLocale.en;
}

export default getSupportedLocale;
