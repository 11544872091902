import { gql } from '@apollo/client';
import { PersonAccessRightFragment } from '../../fragments/person/PersonAccessRight';

export const GetPersonAccessRights = gql`
  query GetPersonAccessRights(
    $personIdentifier: String
    $first: Int
    $offset: Int
    $status: [PersonAccessRightStatus]!
    $searchTerm: String
  ) {
    personAccessRights(
      personIdentifier: $personIdentifier
      first: $first
      offset: $offset
      status: $status
      searchTerm: $searchTerm
    ) {
      edges {
        node {
          ...PersonAccessRight
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonAccessRightFragment}
`;
