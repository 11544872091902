import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { ServiceMembersMode } from '../ServiceMembersPanel.container';
import { useStyles } from './Drawer.styles';
import TestIds from 'Tests/TestIds';
import ConfirmRemove from '../ConfirmRemove';
import SetExpirationDate from '../SetExpirationDate';
import {
    RemoveCircle,
    Person as UserIcon,
  } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import { DeletePersonAccessRight, UpdatePersonAccessRights } from 'Apollo';
import { useMutation } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { mdiCalendarClock } from '@mdi/js';
import { CdsColorActionNeutralActive } from 'Theme/constants/tokens';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import DrawerBottom from 'Components/Shared/BottomDrawer/DrawerBottom';


interface Props {
  serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier;
  currentMode: ServiceMembersMode;
  selectedIds: string[];
  setMode: (mode: ServiceMembersMode) => void;
  refetch: () => void;
  updateSelection: (ids: string[]) => void;
}

function ServiceMembersActionsDrawer(props: Readonly<Props>) {
  const { serviceSubscriptionIdentifier, selectedIds, currentMode, refetch, setMode, updateSelection } = props;
  const ts = useTranslate();
  const classes = useStyles();
  const { onSuccess, onError } = useNotification();


  const [removeAccessRights, { loading: loadingRemoveMutation }] = useMutation(
    DeletePersonAccessRight,
    {
      onCompleted: () => {
        onSuccess(
          selectedIds.length > 1
            ? 'page.serviceProfile.serviceMembers.removeResult.many'
            : 'page.serviceProfile.serviceMembers.removeResult.one'
        );
        updateSelection([]);
        setMode(null);
        refetch();
      },
      onError,
      variables: {
        data: selectedIds.map(e => ({
          accountCode: serviceSubscriptionIdentifier.accountCode,
          personIdentifier: e,
          serviceGroupCode: serviceSubscriptionIdentifier.serviceCode
        }))
      }
    }
  );

  const removeMembers = async () => {
    await removeAccessRights();
    refetch();
  };

  const [
    updateAccessRightsExpirationDate,
    { loading: loadingUpdateMutation }
  ] = useMutation<any, IUpdatePersonAccessRightVariables>(
    UpdatePersonAccessRights,
    {
      onCompleted: () => {
        onSuccess(
          selectedIds.length > 1
            ? 'page.serviceProfile.serviceMembers.updateEndDateResult.many'
            : 'page.serviceProfile.serviceMembers.updateEndDateResult.one'
        );
        updateSelection([]);
        setMode(null);
        refetch();
      },
      onError
    }
  );

  const setAccessRightsEndDate = async (newExpiryDate: Date | null) => {
    await updateAccessRightsExpirationDate({
      variables: {
        data: selectedIds.map(e => ({
          accountCode: serviceSubscriptionIdentifier.accountCode,
          endValidityDate: newExpiryDate,
          personIdentifier: e,
          serviceGroupCode: serviceSubscriptionIdentifier.serviceCode
        }))
      }
    });
    refetch();
  };

  return (
    <DrawerBottom 
      drawerHead={
        <>
            <Grid item sm={4}>
          <Typography variant="body2" color="textSecondary">
            <UserIcon
              color="primary"
              classes={{ root: classes.headerLeftIcon }}
            />
            {ts('page.users.index.selectedUserCount', {
              count: selectedIds.length
            })}
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <div className={classes.drawerIcons}>
            <Tooltip
              title={ts(
                'page.serviceProfile.serviceMembers.editExpirationDate'
              )}
            >
              <IconButton
                onClick={() => setMode('setExpiration')}
                data-testid={
                  TestIds.pages.serviceProfile.drawerEditExpirationDateButton
                }
                size="large">
                <SvgIcon
                  path={mdiCalendarClock}
                  fill={CdsColorActionNeutralActive}
                  width="24"
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={ts(
                'page.serviceProfile.serviceMembers.removeOneOrMoreAccess',
                { count: selectedIds.length }
              )}
            >
              <IconButton
                onClick={() => setMode('confirmRemove')}
                data-testid={
                  TestIds.pages.serviceProfile.drawerRemoveAccessButton
                }
                size="large">
                <RemoveCircle />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
        </>
      }
      drawerBody={
        <>
          {currentMode === 'setExpiration' && (
            <SetExpirationDate
              loading={loadingUpdateMutation}
              onCancel={() => setMode(null)}
              onSubmit={(newExpiryDate: Date) => {
                setAccessRightsEndDate(newExpiryDate);
              }}
              title={ts(
                'page.serviceProfile.serviceMembers.updateServiceEndDateForOneOrManyUser',
                {
                  serviceName: serviceSubscriptionIdentifier.serviceCode,
                  count: selectedIds.length
                }
              )}
            />
          )}
          {currentMode === 'confirmRemove' && (
            <ConfirmRemove
              loading={loadingRemoveMutation}
              onCancel={() => setMode(null)}
              onSubmit={() => {
                removeMembers();
              }}
              title={ts('page.serviceProfile.serviceMembers.confirmRemove', {
                serviceName: serviceSubscriptionIdentifier.serviceCode,
                count: selectedIds.length
              })}
            />
          )}
        </>
      }
    />
  );
}
export default ServiceMembersActionsDrawer;
