import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CdsColorActionPrimaryActive,
  CdsColorBackgroundPrimaryLight,
  CdsSizeSpacing
} from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    textTransform: 'initial',
    width: '100%',
    '&:hover': {
      backgroundColor: `${CdsColorBackgroundPrimaryLight} !important`,
      color: `${CdsColorActionPrimaryActive} !important`,
      cursor: 'pointer'
    }
  },
  listItemIcon: {
    marginLeft: CdsSizeSpacing
  }
}));
