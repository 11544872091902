import makeStyles from '@mui/styles/makeStyles';
import { CdsSizeRadius } from 'Theme/constants/tokens';

export const useStyles = makeStyles(() => ({
    status: {
        display: 'flex',
        padding: '4px 8px',
        width: 'fit-content',
        borderRadius: CdsSizeRadius
    }
}));