import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorBackgroundPrimaryLight, CdsColorBackgroundWhiteMain, CdsColorBrandNeutral99 } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  accessRightsPageLayout: {
    margin: `-${theme.spacing(4)} -${theme.spacing(3)} 0px -${theme.spacing(3)}`
  },
  accessRightsTopSection: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    background: CdsColorBackgroundPrimaryLight
  },
  accessRightsRequestsPageTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '2em',
    background: CdsColorBackgroundWhiteMain,
    position: 'relative',
    borderTop: `1px solid ${CdsColorBrandNeutral99}`,
    borderBottom: `1px solid ${CdsColorBrandNeutral99}`,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  accessRightsCardsContainer: {
    height: '100vh',
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      top: '135px', /** IPhone SE, IPhone XR, IPhone 12, IPhone 14 Pro, Pixel 7, Samsung Galaxy S8 - S20 - A51/71, Surface Duo, Galaxy Fold */
    },
    [theme.breakpoints.only('sm')]: {
      '@media (orientation: portrait)': {
        top: '128px' /** Surface Pro 7, IPad */
      },
      '@media (orientation: landscape)': {
        top: '120px' /** IPhone SE, IPhone XR, IPhone 12, IPhone 14 Pro, Pixel 7, Samsung Galaxy S8 - S20 - A51/71, Surface Duo, Galaxy Fold */
      }
    },
    [theme.breakpoints.only('md')]: {
      '@media (orientation: portrait)': {
        top: '270px' /** IPad Pro */
      },
      '@media (orientation: landscape)': {
        top: '260px', /** Nest Hub & IPad */
      }
    },
    [theme.breakpoints.only('lg')]: {
      '@media (orientation: portrait)': {
        top: 'calc(100vh - 87vh)', /** XL Device portrait*/
      },
      '@media (orientation: landscape)': {
        top: '270px' /** IPad Pro, Surface Pro 7, Nest Hub Max */
      },
      top: 'calc(100vh - 85vh)',
    },
    [theme.breakpoints.only('xl')]: {
      top: 'calc(100vh - 83vh)', /** XL Device */
    },
  }
}));
