import {
  Select,
  MenuItem,
  Typography,
  Button,
  useMediaQuery,
  SelectProps
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import useTranslate from 'Hooks/useTranslate';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import { CdsSizeSpacing } from 'Theme/constants/tokens';
import {
  ArrowBackIos as IconArrowBack,
  ArrowForwardIos as IconArrowForward,
  LastPage as IconLastPage,
  FirstPage as IconFirstPage
} from '@mui/icons-material';
import { useState } from 'react';

interface Props {
  totalCount: number;
  onChangeRowsPerPage: (v: number) => void;
  rowsPerPage: number;
  rowsPerPageOptions?: any[];
  page: number;
  onChangePage: (page: number) => void;
}

const StyledSelect = withStyles(() =>
  createStyles({
    root: {
      border: '1px solid grey',
      borderRadius: '6px'
    }
  })
)(Select);

export const TableCollectionPagination = (props: Props & SelectProps) => {
  const {
    rowsPerPageOptions,
    totalCount,
    page,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage
  } = props;

  const [currentRowPerPage, setCurrentRowPerPage] = useState<number>(
    rowsPerPage
  );

  const ts = useTranslate();

  const xsDevice = useMediaQuery('(max-width:300px)');

  const isMobileP = useMediaQuery(
    '(max-width:600px) and (orientation: portrait)'
  );

  let lastPage = Math.floor(totalCount / rowsPerPage);
  let pagesSize = lastPage + 1;

  let pageSelector = [];
  for (let i = 0; i < pagesSize; i++) {
    pageSelector.push(i + 1);
  }

  let lastResultOfPage = (page + 1) * rowsPerPage;

  const handleChangeRowPerPage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCurrentRowPerPage(event.target.value as number);
    onChangeRowsPerPage(event.target.value as number);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: CdsSizeSpacing
      }}
    >
      {!isMobileP && (
        <div>
          <StyledSelect
            labelId="result-per-page-select-label"
            id="result-per-page-select"
            value={currentRowPerPage}
            label={ts('common.label.rowPerPage')}
            onChange={handleChangeRowPerPage}
            variant="standard"
          >
            {rowsPerPageOptions
              ? rowsPerPageOptions?.map((option: number) => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected={option === rowsPerPage}
                  >
                    <Typography variant="caption">{option}</Typography>
                  </MenuItem>
                ))
              : ROW_PER_PAGE_OPTIONS?.map((option: number) => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected={option === rowsPerPage}
                  >
                    <Typography variant="caption">{option}</Typography>
                  </MenuItem>
                ))}
          </StyledSelect>
          <Typography variant="caption" style={{ paddingLeft: '8px' }}>
            {ts('common.label.rowPerPage')}
          </Typography>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          margin: '0 auto'
        }}
      >
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => onChangePage(0)}
            disabled={page === 0}
            title={ts('common.pagination.firstPage')}
          >
            <IconFirstPage />
          </Button>
          <Button
            disabled={page === 0}
            onClick={() => onChangePage(page - 1)}
            style={{ fontSize: '16px' }}
            title={ts('common.pagination.previousPage')}
          >
            <IconArrowBack fontSize="inherit" />
          </Button>
        </div>
        <div>
          {!isMobileP && (
            <Typography variant="caption">
              {ts('common.pagination.displayedRows', {
                from: page * rowsPerPage + 1,
                to:
                  lastResultOfPage > totalCount ? totalCount : lastResultOfPage,
                count: totalCount
              })}
            </Typography>
          )}
          {isMobileP && !xsDevice && (
            <StyledSelect
              labelId="page-selector-MobileP"
              id="page-selector-MobileP"
              value={page + 1}
              label={ts('common.label.page')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangePage(Number(e.target.value) - 1)
              }
              variant="standard"
            >
              {pageSelector?.map((option: number) => (
                <MenuItem
                  key={option}
                  value={option}
                  selected={option === rowsPerPage}
                >
                  <Typography variant="caption">{option}</Typography>
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        </div>

        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => onChangePage(page + 1)}
            disabled={page === lastPage}
            style={{ fontSize: '16px' }}
            title={ts('common.pagination.nextPage')}
          >
            <IconArrowForward fontSize="inherit" />
          </Button>
          <Button
            onClick={() => onChangePage(lastPage)}
            disabled={page === lastPage}
            title={ts('common.pagination.lastPage')}
          >
            <IconLastPage />
          </Button>
        </div>
      </div>

      {!isMobileP && (
        <div>
          <Typography variant="caption" style={{ paddingRight: '8px' }}>
            {ts('common.label.page')}
          </Typography>
          <StyledSelect
            labelId="page-selector-notMobileP"
            id="page-selector-notMobileP"
            value={page + 1}
            label={ts('common.label.page')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePage(Number(e.target.value) - 1)
            }
            variant="standard"
          >
            {pageSelector?.map((option: number) => (
              <MenuItem
                key={option}
                value={option}
                selected={option === rowsPerPage}
              >
                <Typography variant="caption">{option}</Typography>
              </MenuItem>
            ))}
          </StyledSelect>
        </div>
      )}
    </div>
  );
};
