import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { colors } from 'Theme/constants';
import Doughnut from './Doughnut';
import { useParams } from 'react-router-dom';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';

export const UserRolesDistributionCount = gql`
  query UserRolesDistributionCount(
    $personFilter: PersonFilterInputType
    $accountCode: String
  ) {
    fellowAdministrators(accountCode: $accountCode, offset: 0, first: 0) {
      totalCount
    }
    persons(personFilter: $personFilter, offset: 0, first: 0) {
      totalCount
    }
  }
`;

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    height: '100%',
    minHeight: 148
  },
  content: {
    flex: 1,
    display: 'flex'
  }
}));

interface IQueryData {
  fellowAdministrators: { totalCount: number };
  persons: { totalCount: number };
}

type Props = {};

const UserRolesDistribution = (props: Props) => {
  const { accountCode } = useParams<{ accountCode: string }>();
  const classes = useStyles();
  const ts = useTranslate();

  const variables = {
    personFilter: {
      accountCode: accountCode === 'all' ? null : accountCode
    },
    accountCode: accountCode === 'all' ? null : accountCode
  };

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.content }}>
        <Query query={UserRolesDistributionCount} variables={variables}>
          {({ data }: QueryResult<IQueryData>) => {
            const {
              fellowAdministrators = { totalCount: 0 },
              persons = { totalCount: 0 }
            } = data || {};
            const adminToDisplay = fellowAdministrators.totalCount;
            const users = persons.totalCount - adminToDisplay;
            const props = {
              datas: [
                {
                  color: colors.blue[500],
                  label: ts('page.users.index.userRoles.administrators', {
                    count: adminToDisplay
                  }),
                  value: adminToDisplay,
                  testId: TestIds.pages.users.administratorsCount
                },
                {
                  color: colors.blue[300],
                  label: ts('page.users.index.userRoles.notadministrators', {
                    count: users
                  }),
                  value: users,
                  testId: TestIds.pages.users.notAdministratorsCount
                }
              ]
            };

            return <Doughnut.Display displayTitle="aside" {...props} />;
          }}
        </Query>
      </CardContent>
    </Card>
  );
};

export default UserRolesDistribution;
