import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import ServicesAccountDistribution from './ServicesAccountDistribution';
import ServicesTable from './ServicesTable/ServicesTable';
import useTranslate from 'Hooks/useTranslate';
import { ServiceSubscriptionOrder } from 'Apollo';
import ServicesCounter from './ServicesCounter/ServicesCounter';

interface Props {
  rows: IServiceSubscription[];
  totalCount: number;
  loading: boolean;
  isMobileP: boolean;
  currentPage: number;
  orderBy: ServiceSubscriptionOrder;
  rowsPerPage: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onChangeServicesSearch: (value: string) => void;
  onChangeOrderBy: (order: ServiceSubscriptionOrder) => void;
  onChangePage: (page: number) => void;
}

const Services = ({
  rows,
  totalCount,
  loading,
  isMobileP,
  orderBy,
  rowsPerPage,
  currentPage,
  onChangePage,
  onChangeOrderBy,
  onChangeRowsPerPage,
  onChangeServicesSearch
}: Props) => {
  const ts = useTranslate();

  return (
    <>
      <Helmet title={ts('page.services.title')} />
      <Grid container>
        <Grid
          sx={{ display: { xs: 'none', md: 'flex' } }}
          xs={12}
          item
          style={{ marginBottom: '12px' }}
          container
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={6}>
            <ServicesCounter />
          </Grid>
          <Grid item xs={6}>
            <ServicesAccountDistribution />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ServicesTable
            rows={rows}
            totalCount={totalCount}
            loading={loading}
            isMobileP={isMobileP}
            currentPage={currentPage}
            onChangePage={onChangePage}
            orderBy={orderBy}
            onChangeOrderBy={onChangeOrderBy}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangeServicesSearch={onChangeServicesSearch}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Services;
