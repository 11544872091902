import flat from 'flat';
import {
  AuditAction,
  AuditActionResult,
  ActivityType,
  PersonAccessRightStatus
} from '../Apollo';
import { ApiKeyEventType } from '../Apollo/fragments/apikey/ApiKeyEvent';
import TProfileManagerMessages from './TProfileManagerMessages';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';

// This object defines the resource keys
// for the rest of the code. For this reason
// we don't provide an explicit type and
// we let typescript infer it.
export const englishResourceObject = {
  applicationName: 'Profile manager',
  common: {
    action: {
      loading: 'Loading...',
      search: 'Search',
      subscribe: 'Subscribe',
      accept: 'Accept',
      confirm: 'Confirm',
      deny: 'Deny',
      add: 'Add',
      delete: 'Delete',
      edit: 'Edit',
      back: 'back',
      export: 'Export',
      finish: 'Finish',
      previous: 'Previous',
      next: 'next',
      cancel: 'Cancel',
      reset: 'Reset',
      toEnd: 'End',
      agree: 'Agree',
      retry: 'Retry',
      understand: 'I understand',
      moreDetails: 'More details',
      validate: 'Validate',
      migrate: 'Migrate',
      open: 'Open',
      close: 'Close',
      ignore: 'Ignore',
      clear: 'Clear search',
      yes: 'Yes',
      no: 'No',
      ok: 'Ok',
      backToHomePage: 'Back to the home page',
      activate: 'Activate',
      deactivate: 'Deactivate',
      doNotModify: 'Do not modify',
      seeProfile: 'View profile',
      login: 'Login',
      copyCode: 'Copy the code',
      see: {
        seeMore: 'See more',
        seeDescription: 'See description',
        seeExpirationDate: 'See expiration date',
        seeGroupDetails: 'See group details'
      }
    },
    date: {
      editExpirationDate: 'Edit expiration date',
      expireDetails: 'Expires on {date} - {hour}',
    },
    person: {
      role: {
        adm: 'Administrator',
        superAdm: 'Super administrator',
        superAdmSentence: 'You navigate as super administrator',
        superReaderSentence: 'You navigate as super reader',
        admSuperReader: 'Administrator / Super reader',
        reader: 'Reader',
        superReader: 'Super reader',
        user: 'User'
      }
    },
    service: {
      unavailable: 'Service not available'
    },
    terminology: {
      alert: {
        info: 'Information'
      },
      client: 'Client account',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      account: 'Account',
      accounts: 'Accounts',
      description: 'Description',
      codeGroup: 'Group code',
      famillies: 'famillies',
      groups: 'groups',
      localAccount: 'Local account',
      menu: 'Menu',
      MSaccount: 'Microsoft account',
      GoogleAccount: 'Google account',
      required: 'Mandatory',
      services: 'services',
      tutorial: 'Tutorial',
      immutableId: 'Immutable Id',
      email: 'Email address',
      or: 'or',
      groupName: 'Group name',
    },
    dates: {
      creationDate: 'Creation date: {value} ',
      today: 'Today',
      yesterday: 'Yesterday',
      previously: 'Previously',
      thisInstant: '0 seconds ago',
      jours: 'days',
    },
    label: {
      id: 'ID: ',
      name: 'Name',
      page: 'Page',
      rowPerPage: 'Row / page'
    },
    pagination: {
      firstPage: 'First page',
      lastPage: 'Last page',
      previousPage: 'Previous page',
      nextPage: 'Next page',
      displayedRows: '{from}-{to} of {count}',
    },
    xhrMaxItemCountReached:
      'You are not allowed to select more than {XhrMaxItemCount} users.',
    welcome: 'Welcome,',
    accountLabel: '{name} - {zipCode} {city} / {code}',
    switchAccount: 'Switch client account',
    disabledAccount: '(Disabled)',
    tableHead: {
      name: 'Name',
      status: 'Status',
      code: 'Code',
      validationCode: 'Validation code',
      expiration: 'Expiration',
    }
  },
  component: {
    modals: {
      createUser: {
        heading: 'Invite users',
        submit: 'Invite',
        wizard: {
          heading: 'Take control of your users',
          subheading:
            'Effortlessly customize and manage user data, subscriptions, and groups.'
        },
        labels: {
          emails: 'Adresse e-mail des utilisateurs',
          emailsHelperText: 'Add multiple users using commas. Max. 100 users.',
          accountCode: 'Account'
        },
        invitationSent:
          "You've sent {invitationsQuantity, plural, zero {{invitationsQuantity} invitation } one {{invitationsQuantity} invitation} other {{invitationsQuantity} invitations}}",
        invitationFailed: 'The following invitations failed:'
      },
    },
    form: {
      placeholder: {
        emailsInput: 'Ex: name@company.com, name1@company.com'
      },
      label: {
        emailInputCount: 'Total emails to be added: {emailsCount}'
      }
    },
    accessrights: {
      pendingRequest: {
        count: `{value, plural, zero { {value} request } one { {value} request } other { {value} requests }} `,
        sentence: `{value, plural, zero { {value} pending request } one { {value} request } other { {value} pending requests }} `
      }
    },
    administratedAccountsSelector: {
      allAccounts: 'All accounts',
      seeAll: 'See all accounts'
    },
    searchBars: {
      availabledResult: {
        noResultPrimary: 'No results matching your input',
        noResultSecondary:
          'Find a user by his name, his first name or by his email.'
      },
      userSelectedSearchBar: {
        sentence: `{total, plural, one {{total} request matching your search} other {{total} requests matching your search}}`
      },
      placeholder: {
        users: 'Find users',
        services: 'Find services'
      }
    },
    table: {
      selectAll: 'select all ({count})',
      elementSelected: '{count} element(s) selected'
    },
    tableServiceOwners: {
      remove: {
        confirmation: 'You are about to delete the service owner {name}',
        success: 'The user no longer owns the service'
      }
    },
    editToggle: {
      editGroup: {
        update: {
          success: 'The group has been updated'
        }
      }
    },
    userAccountUpdate: {
      tooltip: "Modify the user's third-party account",
      unlink: `Disassociate from the third party account {value}`,
      link: 'Associate with another third party account',
      unlinkDialogTitle: 'You are about to detach the user from the third party account',
      irreversibleAction: 'This action is irreversible. Do you wish to continue?',
      linkDialogTitle: 'You are about to modify the third party associated with this user',
      whichNewAccount: 'To which account do you want to attach the user?',
      chooseAccount: 'Parent account',
      lostAccessCount: `The user will lose access to {value, plural, one {{value} service} other {{value} services}}. `,
      inheritedServicesFromGroup: ` - (service inherited from group {value})`,
      alertInfo: {
        previousMembersList: `The user will no longer appear in the list of users of the third party account {value}.`,
        previousAccountAccessRights: `The user will lose access to the following services:`,
        previousExternalisableAccessRights: `If the user still has access to an externalizable service of {value} before your confirmation, it will be retained.`,
        newAccountMembersList: `The user will appear in the members of the third party {value}.`
      },
      alertWarning: {
        retainedAccessRights: `The following accesses will be retained:`,
        unlinkGroups: `The user will be detached from all groups of the third party account.`,
        alertWarningTitle: 'Attention',
        noMoreAccessToProfile: `You will no longer be able to access this user profile once it is detached from the third party {value}.`,
        removeAllAccessWarning: `If you wish to remove, all or part of, the user's {value} accesses to the services mentioned above, please make sure to delete them before detaching them from the third party. You can perform this action from their list of `,
        awareness: 'This action is sensitive. Be cautious before confirming.'
      },
      switchAccount: {
        newAffiliationAccount: `New parent third party`,
        dialogTitle: `You are about to modify the third party account associated with this user. Do you wish to continue?`,
        newInheritedServices: `The user will automatically gain access to the default services of this third party.`,
        servicesListLink: `View the list of services`,
        alertInfoTitle: `For the new parent third party`
      },
      accessToProfileFromNewAccount: `You will be able to access this user profile from this tier.`,
      forSuperUserOnly: {
        newAccessToProfile: `You will be able to access this user profile from the list of all `,
        administratedUsers: `your managed users`
      },
      mutation: {
        linkToNewAccountSuccess: `The user has been successfully linked to the third party account {value}.`,
        unLinkSuccess: `The user has been successfully unlinked from the third party account {value}.`
      }
    }
  },
  selectorList: {
    ipRangeType: {
      persons: 'Utilisateur',
      apiKey: 'Api Key'
    }
  },
  error: {
    noAccountSelected: 'No customer account selected',
    selectAnAccount: 'Please select a third party beforehand to view the details of this service.',
    somethingHappened: 'An error has occurred',
    comeBackLater: 'Come back later',
    oops: 'Oops.',
    copyText: 'Copy text',
    textCopied: 'Text copied',
    details: 'Errors details',
    noDataAvailable: 'No data available !',
    infoWillBeDisplayedHere: 'Your information will be displayed here.',
    form: {
      invalidEmail: 'This email address is invalid',
      invalidEmailsLength: 'The maximum number of emails is 100.',
      emailsDuplication: 'There is at least one duplicated email',
      invalidIp: 'Invalid format',
      invalidIpRange: 'Invalid IP range',
      requiredField: 'Required field',
      min: `The max value is {value}`,
      max: 'The min value is {value}',
      minLength: `Field is too short. Min.{value} characters`,
      maxLength: `Field is too long. Max.{value} characters`
    },
    type: {
      signin: 'Connection error',
      activation: 'Activation error',
      requiredField: `The field is required.`,
      userAlreadyExists: `User already exists.`,
      network: `A network error occurred. Are you connected to the internet?`,
      batch: `The action was cancelled due to several errors.`,
      E101: `User already exists in this account.`,
      E104: `Many users with those parameters.`,
      E121: `Current user deletion is forbidden`,
      E123: `An account already exists with this email address.`,
      E125: `The email is required.`,
      E126: `Remove last person in account is not allowed`,
      E139: `Not authorized to remove the profile`,
      E145: `The domain of the email is bound to a federated directory.`,
      E247: `Mass person deletion produced errors`,
      E403: `Access Denied`,
      E404: 'Page Not Found',
      E500: `An error has occurred, please try again later.`,
      HC0045: `Processing in progress, please check the result of your operation in a few minutes`,
      invalidDateFormat: 'Date format is invalid',
      superUserAccessRefused: `You don't have any super user access`
    },
    sentence: {
      emailChange: 'The email has not been updated',
      signin: 'An error has occured during your connection attempt',
      activation:
        'Your activation link has expired or your profile is already activated.',
      E403: 'You are not authorized to access this content.',
      E404: 'The requested page cannot be found.'
    },
    illustrationAlt: {
      ballons: 'Illustration of a paper house that flies thanks to balloons'
    }
  },
  menu: {
    activities: 'Activities',
    apiKey: 'API Keys',
    dashboard: 'Dashboard',
    groups: 'Groups',
    management: 'Management',
    settings: 'Settings',
    notifications: 'Notifications',
    services: 'Services',
    accessRights: 'Access rights requests',
    users: 'Users',
    validateRequest: 'Validate a request',
    profileManager: 'Go back to the old version',
    profileManagerWarning: 'End on March 2024',
    profile: 'Profile',
    shortcuts: 'Shortcuts',
    addUser: 'Invite users',
    addGroup: 'Add a group',
    organizations: 'Organizations'
  },
  myMenu: {
    profile: 'Profile',
    logout: 'Disconnect'
  },
  banner: {
    pendingRequest: `Your temporary delegated administration expires in {hours}h {minutes}min`,
    bannerText: `Ask an administrator for temporary access to administer an account.`,
    buttonText: `Request a delegation of administration`,
    accessRightRequestDialog: {
      title: `Ask an access right to an administrator`,
      noAdministratorYet:
        'This account does not have an administrator yet. Please contact support.',
      text: `Enter the email address of a customer account administrator to make changes. We will send a confirmation email to the administrator to validate your request.`,
      subtext: `The email contains a validation link valid for 5 minutes. Once your request has been accepted, you will be granted access to the account administration for 2 hours.`,
      sendEmail: `Send email`,
      pendingRequest: `A message has been sent to the administrator to validate the request of delegation.`,
      pendingRequestInfo: `The email sent to the account administrator contains a link that is valid for 5 minutes. This link allows him to accept your request. Following the validation of your request, you benefit from a temporary access to the account administration for a period of 2 hours.`
    }
  },
  delegation: {
    success: 'Administration rights have been successfully granted.',
    error:
      'The administration rights could not be added. Please contact support.'
  },
  onBoarding: {
    step1H5: 'We have updated the account management.',
    step1Subtitle1: "Let's discover some features of the new version.",
    step2H5: 'Simply view important information',
    step2Subtitle1:
      'A summary dashboard allows you to view the main information of the accounts you administer.',
    step3H5: "Manage your users' subscriptions more easily.",
    step3Subtitle1:
      'Lists allow you to easily select multiple users and services to quickly open access to your users.'
  },
  page: {
    accountProfile: {
      title: 'Account {value}',
      youConsult: 'You consult the customer account',
      aboutTitle: 'Administrative information',
      membersSection: {
        membersTitle: 'Members',
        members: 'Users',
        seeAllMembers: 'See all users',
        notificationSettings: 'Notification settings',
        notificationSettingsTooltip: 'Users are notified by email when access to a service is added.'
      },
      sections: {
        about: {
          accountName: 'Account name',
          accountCode: 'Code'
        },
        administrators: {
          title: 'Administrators',
          numberOfAdministrators: `Number of administrators`,
          seeAllAdministrators: 'See all administrators'
        },
        services: {
          servicesCatalog: 'Services catalog',
          seeCatalog: 'See the catalog'
        },
        apikeys: {
          title: 'API Keys',
          activedApiKeys: 'Actives keys',
          seeAll: 'See all API keys'
        },
        groups: {
          title: 'Groups',
          seeAllGroups: 'See all groups'
        },
        pendingRequests: {
          title: 'Pending requests',
          manageRequests: 'Manage requests'
        }
      }
    },
    apiKeyProfile: {
      info: {
        title: `Key label: `,
        subtitle: `ID: {value}`
      },
      technicalInformations: 'Technical informations',
      contact: 'Contact',
      creator: 'Creator:',
      creationDate: 'Creation date:',
      revocationDate: 'Revocation date:',
      relatedServices: 'Related service',
      noRelatedServices: 'No related service to this Api Key',
      service: 'Service:',
      tiers: 'Account:',
      activities: 'Activities',
      allActivities: 'See all activities'
    },
    apikeyProfile: {
      menu: {
        general: 'General',
        audit: 'Activity'
      },
      title: '{uuid} Api key',
      activities: {
        title: 'Activities',
        type: `Type`,
        date: `Date`,
        author: `Author`
      },
      activity: {
        type: `{value, select, ${ApiKeyEventType.Creation} {Creation} ${ApiKeyEventType.AccessGranted} {Authorized access} ${ApiKeyEventType.Revocation} {Revocation} ${ApiKeyEventType.AccessDenied} {Access denied}  other {-}}`,
        emptyStateTitle: 'There is no history for this api key.',
        emptyStateText: 'Upcoming events will appear here.'
      }
    },
    changeEmailValidation: {
      title: 'The request has been resolved successfully',
      subtitle: 'Your email address has been changed to {email}',
      goToCegid: 'Go to Cegid Account'
    },
    accessRights: {
      newRequests: 'New subscription requests',
      title: 'Service subscription requests',
      subtitle: 'Manage all the subscription requests created by your users',
      empty: {
        title: 'No subscription requests',
        subtitle: 'The next subscription requests will appear here.'
      },
      acceptRequestSuccess: 'You have just accepted the request',
      denyRequestSuccess: `You have just denied the request`,
      updateRequestStatusSuccess: `The request has been updated`,
      expirationDate: `Expiration date: `,
      defineExpirationDate: `Due date`,
      changeExpirationDate: `Edit`,
      cancelExpirationDate: `Cancel`
    },
    accountSelection: {
      title: 'Client account selection',
      whichAccount: 'Which <b>client account</b> would you like to manage?',
      accountsFound:
        '{count, plural, zero {{count, number} accounts found} one {{count, number} account found} other {{count, number} accounts found}}',
      accountLabel: "Account's code",
      loginToAccount: 'Login to account',
      noAccountsFound: 'No accounts found'
    },
    administrators: {
      title: 'Administrators',
      index: {
        administrators: 'Administrators',
        email: 'Email address',
        allStatus: 'Status',
        language: 'Language'
      },
      accountAdministrators: 'Account administrators',
      emptyState: {
        title: 'No administrator',
        description: 'Future administrators will appear here.'
      }
    },
    apiKeys: {
      title: 'API Keys',
      addApikeyTooltip:
        'An API key is associated to a service and give an access right to a program.',
      requireAccountSelectionTooltip:
        'Select an account to generate an API key.',
      refreshTooltip: 'Refresh the API keys list',
      apikeyHistory: `Activities of {name} key`,
      noApiKey: `No API key`,
      futureApiKeysText: `The future generated API keys will appear here.`,
      creator: `Creator: {fullName}`,
      undefinedCreator: 'Unknown creator',
      undefinedCreationDate: 'Unknown creation date',
      revoke: 'Revoke',
      details: 'Details',
      history: 'Activity',
      revocationConfirmationApiKeyName: `You will revoke the API key `,
      revocationConfirmationText: `. This action will be final. Do you want to continue?`,
      revocationSuccessMessage:
        'The api key {value} has been successfully revoked.',
      revokedTitle: 'Revoked',
      activeTitle: 'Actives',
      generate: {
        apiKey: `Generate an API key`,
        theApiKey: `Generate the API key`,
        selectService: `Select a service`,
        enterNewApiKeyName: `Enter the new key name`,
        apiKeyNameAlreadyExist: `This service already has an API key of the same name.`,
        mandatoryService: `You must choose a service.`,
        mandatoryName: `You must enter a name for this new key.`
      },
      success: {
        title: 'Successfully generated API key',
        yourApiKey: `Your API key`,
        apiKeySecret: `API key secret`,
        warning: `The generated API Key Secret will never be displayed again.`
      }
    },
    createPersonGroup: {
      title: 'Create a group',
      heading: 'Manage your users more easily',
      subheading:
        'You can classify the users in your company into groups. The services associated with the groups will be automatically added to the user member of the group.',
      stepInformation: {
        name: 'Group name',
        description: 'Description',
        accountCode: 'Account'
      },
      success: `Group {groupName} successfully added`,
      groupAdded: '• A group was added.'
    },
    dashBoard: {
      title: 'Dashboard',
      index: {
        accountList: 'Account list',
        administrators: 'Administrators',
        seeTheList: 'See the list',
        initializing: 'Initialiazing',
        subscriptionRequests: {
          title: 'New service subscription requests',
          noPendingRequests: 'There are no pending requests',
          seeMore: 'See more'
        },
        totalAdministrators: `{count, plural, one {{count, number} element} other {{count, number} elements}}`
      },
      disabledUser: 'Selected user no longer exists'
    },
    groupProfile: {
      title: '{name} Group',
      youConsultGroup: 'You consult group',
      information: {
        title: 'Information'
      },
      removeGroup: {
        removeGroupConfirmation: `You are about to delete the {groupName} group. Current group members will lose their accesses (via the group) to the associated services. Would you like to continue?`,
        removeSuccess: `The group {groupName} has been successfully removed.`
      },
      creationBy: ' by {creator}',
      editProfile: {
        title: 'Edit group',
        name: 'Name',
        description: 'Description'
      },
      services: {
        subscription: {
          removeConfirmation: `You are about to delete the service {serviceName} from the {groupName} group.`,
          addServicesTitle: `Add one or more services`,
          deleteMessage: 'The service has been removed from the group.',
          successMessage: `{count, plural, one {{count, number} service} other {{count, number} services}} has been added to the group.`
        },
        emptyState: {
          title: 'No associated services!',
          description: 'Future services will appear here.'
        }
      },
      addServices: {
        emptyState: {
          title: 'The list is empty',
          description: 'No available services'
        },
        selection:
          '{count, plural, one {{count, number} service is selected} other {{count, number} services are selected}}',
        completedSuccess:
          'You added {count, plural, one {{count, number} service} other {{count, number} services}}.'
      },
      members: {
        add: 'Add a member',
        removeDialogTitle: 'Confirmation of deletion',
        removeMemberConfirmation: `You are about to delete the user {fullName} from the {groupName} group.`,
        removeSuccess: `The user has been removed from the group.`,
        pageAllSelected:
          '{count, plural, one {{count, number} member is selected} other {{count, number} members are selected}} on this page.',
        selectAll:
          'Select all {count, plural, one {{count, number} member} other {{count, number} members}}',
        maxSelectedExceeded:
          'Your selection exceeds the maximum limit of {count, plural, one {{count, number} member} other {{count, number} members}}. Please select less.',

        emptyState: {
          title: 'No associated member!',
          description: 'Future members will appear here.'
        },
        addMembers: {
          emptyState: {
            title: 'No group candidate',
            description: 'No available members'
          },
          completedSuccess:
            'You added {count, plural, one {{count, number} member} other {{count, number} members}}.',
          selection:
            '{count, plural, one {{count, number} candidate is selected} other {{count, number} candidates are selected}}'
        }
      }
    },
    groups: {
      title: 'Groups',
      addGroupTooltip:
        'Create groups to manage access rights for a group of users',
      help: 'A group links a set of users to a set of services.',
      index: {
        group: 'Group',
        account: 'Account'
      },
      emptyState: {
        title: 'No created group',
        description: 'Start by creating a new group',
        superReaderDescription: 'Created groups will appear here.'
      }
    },
    notifications: {
      title: 'Notifications',
      help: 'Manage emails that you and your colleagues would like to receive.',
      subtitle: 'Select the criteria that suit you.',
      email: 'Email',
      newAccessRightsTitle: 'Adding a subscription',
      newAccessRightsDescription:
        'Whether or not to send an email to users when they subscribe to a new service.',
      successfullySaved: `Your notification preferences have been updated for {account}.`
    },
    organizationProfile: {
      pageTitle: 'Organization {value}',
      youConsultSentence: 'You consult the organization',
      uniqueIdentifier: 'Unique identifier',
      aboutTitle: 'Administrative information',
      creationDate: 'Creation date',
      seeAllDomains: 'See domains',
      menu: {
        general: 'General',
        domains: 'Domains'
      },
      countDomains: 'Managed domains',
    },
    organizations: {
      title: 'Organizations',
      noOrganizationFound: 'No organization found',
    },
    organizationDomains: {
      title: 'Organization {value} domains',
      uniqueIdentifier: 'Unique identifier {value}',
      domains: 'Domains',
      statusType: `{value, select, ${DomainValidationMode.Strong} {Strong} ${DomainValidationMode.None} {None} ${DomainValidationMode.Weak} {Weak}}`,
      emptyState: 'No domain found',
      startAddingDomain: 'Add a domain to start',
    },
    services: {
      title: 'Services',
      tableTitle: 'Services catalog',
      help:
        'Your service catalog gathers all applications that are linked to your Cegid contract. From this table, you can modify the access properties to these services, give access rights to your users on one or many applications, delegate the administration of a service and consult its details.',
      index: {
        family: 'Categories',
        selectedServiceCount: `{count, plural, one {{count, number} selected service} other {{count, number} selected services}}`,
        accountDistribution: '{label} • {serviceCount, number} services',
        fromAccount: ' (from {accountSubscriber})',
        visibleServices: 'Visible services • {value}',
        notVisibleServices: 'Not visible services • {value}'
      },
      action: {
        subscribeExternalUser: 'Subscribe an external user',
        subscribeExternalOwner: 'Add an external owner',
        updateServicesProperties: 'Update services properties',
        updateServicesPropertiesSuccess: 'The services properties have been updated'
      },
      createSubscription: {
        title: 'Subscribe a user',
        stepEmail: {
          email: 'Email',
          invalidEmail: 'This email address is invalid.',
          checkEmail: `Check this email address`,
          userDoesNotExist: "This user doesn't exist."
        },
        stepInfo: {
          checkInfo: 'Check informations'
        }
      },
      updateProperties: {
        title: 'Edit selected services'
      },
      service: {
        availability: 'Availability',
        info: 'Information'
      }
    },
    servicesIpRestrictions: {
      index: {
        title: 'Add an authorized IP range',
        tableTitle: 'Authorized IP range',
        type: 'Type',
        description: 'Description',
        startIp: 'from IP',
        endIp: 'to IP',
        enabled: 'Active',
        disabled: 'Inactive',
        successAdd: 'The Ip Range has been created',
        successRemove: 'The Ip Range has been removed',
        successUpdate: 'The Ip Range has been updated'
      }
    },
    servicesMembers: {
      tableTitle: 'Users with access to the service',
      emptyTitle: 'No user has access to the service',
      noAvailableUserTitle: 'No user can be added to the service',
      invite: 'Invite',
      invitationResult: {
        external: 'The user has been invited to the service.',
        existing: 'The users have been subscribed to the service.'
      },
      groups: {
        tableTitle: 'Groups with access to the service',
        emptyTitle: 'No group has access to the service',
        noAvailableGroupTitle: 'No group can be added to the service',
        successAdd: 'The group has been added to the service'
      }
    },
    externalserviceProfile: {
      title: `Information about your service:`,
      details: `{service} on behalf of {targetAccount}`
    },
    serviceProfile: {
      serviceMembers: {
        add: 'Add a member',
        expirationDate: 'Expiration date',
        removeExpiration: 'Delete the due date',
        changeExpirationDate: `Set or edit expiration date`,
        editExpirationDate: 'Set due date',
        expirationDateAlert: {
          infoSentence1:
            'Access to the service will no longer be time limited for the selected user(s).',
          infoSentence2: `Access to the service for the selected user(s) will expire on: {date}.`
        },
        tableTitle: 'Users with access to the service',
        emptyTitle: 'No user has access to the service',
        giveAccess: 'Give access to the service',
        invite: 'Invite',
        removeAccess: 'Remove access to the service',
        removeOneOrMoreAccess:
          'Remove {count, plural, one {access} other {access}} to the service',
        confirmRemove: 'Do you want to remove access for {count} user(s)?',
        updateServiceEndDateForOneOrManyUser: `Choose the end date of validity of the service for the selected {count, plural, one {user} other {{count, number} users}}`,
        invitationResult: {
          external: 'The user has been invited to the service.',
          existing: 'The users have been subscribed to the service.'
        },
        manageAccess: 'Manage access',
        remove: 'Remove',
        updateEndDateResult: {
          one:
            "This user's access to this service has been successfully updated",
          many: "User's access to this service has been successfully updated"
        },
        removeResult: {
          one:
            "This user's access to this service has been successfully removed",
          many: 'Access to the service has been withdrawn from users'
        },
        groups: {
          tableTitle: 'Groups with access to the service',
          emptyTitle: 'No group has access to the service',
          noAvailableGroupTitle: 'No group can be added to the service',
          successAdd: 'The group has been added to the service'
        }
      },
      title: `Service - {service}`,
      youConsultService: `You consult the service`,
      info: {
        title: `Information about your service: {value}`,
        defaultService: `Automatic subscription for all users by default`,
        adminValidation: `Subscription to the service subject to validation by an administrator`
      },
      label: {
        defaultService: `Automatic subscription`,
        adminValidation: `Administrator validation`,
        serviceCode: `Service code`
      },
      aboutService: `About the service`,
      security: {
        formTitle: `Allow an IP range`,
        formSubTitle: `{serviceName} / {accountName}`,
        descriptionPlaceholder: 'Write your description',
        heading: 'Security option',
        subheading: `The security option allows you to restrict access to a service to one or more authorized IP ranges.`,
        addRange: 'Add range IP',
        emptyTitle: 'There is no restrictions',
        emptySubtitle: 'Use the form to add a restriction'
      },
      subscribers: 'Subscribers',
      owners: {
        ownersTitle: 'Owners',
        help:
          'A Service owner can manage the service, add or remove members and update service properties.',
        emptyTitle: `This service does not have any owner yet`,
        owner: 'Owner',
        add: 'Add an owner',
        sucess: "You've added {ownersCount} owners to the service"
      }
    },
    userProfile: {
      title: 'Profile of {fullName}',
      yourProfile: 'Your profile',
      accountHelp: 'Customer number Cegid',
      acceptedServices: {
        help: 'Authorized access rights for this user.',
        updateServiceEndDateForUser: `Choose the end date of validity of the service for the selected users`,
      },
      deniedServices: {
        help:
          'These access rights requests were denied by an administrator. For more information, contact your administrator.'
      },
      suspendedServices: {
        help:
          'These access rights were suspended by an administrator.For more information, contact your administrator.'
      },
      pendingServices: {
        help:
          'These access rights requests are pending an administrator validation.'
      },
      accountDisabledAlert: {
        title: 'Account is disabled',
        message: 'Sorry, this account is no longer active.'
      },
      section: {
        account: {
          title: 'Account'
        },
        administrators: {
          title: 'Administrators'
        },
        directories: {
          title: 'Directories'
        },
        personal: {
          title: 'Personal information',
          fullname: 'Name',
          password: 'Password',
          securityLevel: 'Password level: ',
          email: 'Email',
          pendingEmailRequest: 'Pending email address for: {value}',
          phone: 'Telephone',
          status: {
            activated: 'Activated',
            created: 'Created',
            toActivate: 'To activate',
            disabled: 'Disabled'
          }
        },
        technical: {
          technicalInformation: 'Technical information',
          immutableId: 'Immutable Id',
          identifier: 'Identifier: ',
          identityIdentifier: 'Identity Identifier: ',
          tenantId: 'Tenant ID: ',
          objectId: 'Object ID: ',
          upn: 'UPN: '
        }
      },
      personalInformations: {
        activatedServices: `{value, plural, one {Activated service} other {Activated services} }`,
        pendingRequest: `{value, plural, zero {Request pending validation} one {Request pending validation} other {Requests awaiting validation} } `,
        cancelRequest: 'Cancel the request',
        cancelRequestConfirmationTitle: 'Cancel request confirmation',
        cancelRequestConfirmationText:
          'Are you sure you want to cancel the current email address change request?',
        cancelRequestSuccess: `Your email address change request has been canceled.`,
        renewRequest: `Send the mail again`,
        resendRequestSuccess:
          'An email has been sent back to this email address for confirmation.',
        activatedSince: 'Active since',
        userNotActivated: 'Activation pending',
        seeAllServices: 'See all activated services'
      },
      lastActivities: {
        title: 'Recent activities',
        activity: 'Activity',
        result: 'Result',
        date: 'Date',
        activityType: `{value, select, ${AuditAction.Signin} {Connection} ${AuditAction.ProfileEdited} {Profile edited} ${AuditAction.PersonStatusChanged} {Status changed} ${AuditAction.MigrationToMicrosoft} {Migration to Microsoft} ${AuditAction.PersonGroupCreation} {Group created} other {-}}`,
        activityResult: `{value, select, ${AuditActionResult.Disabled} {Profile disabled} ${AuditActionResult.SigninSucceeded} {Success} ${AuditActionResult.PersonCreated} {Profile created} ${AuditActionResult.SigninFailed} {Failed} ${AuditActionResult.PersonDisabled} {Profile disabled} ${AuditActionResult.PersonActivated} {Profile activated} ${AuditActionResult.PersonToActivate} {Pending activation} ${AuditActionResult.AuthenticationSucceeded} {Success} ${AuditActionResult.Activated} {Activated} other {-}}`,
        allActivities: 'See all activities'
      },
      activities: {
        title: 'Activities',
        filterLabel: 'Activity',
        activityType: `{value, select, ${ActivityType.All} {All} ${ActivityType.Creation} {Creation} ${ActivityType.Update} {Modification} ${ActivityType.Connection} {Connection} ${AuditAction.PersonGroupCreation} {Group created} other {-}}`,
        emptyStateTitle: 'No activity recorded for the moment',
        emptyStateText: 'Future events will appear here'
      },
      services: {
        service: 'Service',
        family: 'Family',
        subscribers: 'Subscribers',
        numberOfSubscribers: `{subscribers}`,
        numberOfLicenses: `{licenses}`,
        withValidation: 'Validation',
        administratedValidation: "Require an administrator's validation",
        notAdministratedValidation: "Doesn't require validation",
        auto: 'By default',
        accessByDefault: 'Added to users by default',
        notAccessByDefault: 'Not added by default',
        available: 'Available',
        unavailable: 'Not available',
        inheritedFromGroup: 'Inherited from group',
        accessToServiceSince: 'Service access from ',
        expired: 'Expired',
        refused: 'Refused',
        suspendedLabel: 'Suspended',
        suspended: `{value, plural, zero {Suspended service} one {Suspended service} other {Suspended services} } `,
        toValidate: 'Pending request',
        add: 'Add a service',
        removeConfirmation: `You are about to revoke access to this service, do you want to continue?`,
        removeSuccess: `You have unsubscribed from the {serviceName} service.`,
        pageAllSelected:
          '{count, plural, one {{count, number} service is selected} other {{count, number} services are selected}} on this page.',
        selectAll:
          'Select all {count, plural, one {{count, number} service} other {{count, number} services}}',
        maxSelectedExceeded:
          'Your selection exceeds the maximum limit of {count, plural, one {{count, number} service} other {{count, number} services}}. Please select less.',
        completedPending:
          '{count, plural, one {Your request to access {count, number} service has been sent} other {Your requests to access {count, number} services have been sent}}.',
        pendingServices: 'Pending requests',
        deniedServices: `{value, plural, zero {Denied service} one {Denied service} other {Denied services} } `,
        emptyStateTitle: 'No services found',
        emptyStateText: 'Future subscribed services will appear here',
        emptyState: {
          title: 'No pending request.',
          description: 'Your pending requests will appear here.'
        },
        subscriptionEndDate: 'Subscription up to {date} - {hour}',
        filterLabel: 'Status',
        statusType: `{value, select, ${PersonAccessRightStatus.Accepted} {Accepted} ${PersonAccessRightStatus.ToValidate} {Pending validation} ${PersonAccessRightStatus.Refused} {Refused} other {Suspended}}`
      },
      editName: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        editName: 'Change name'
      },
      editPhone: {
        phoneNumber: 'Phone',
        mobileNumber: 'Mobile Phone',
        editPhone: 'Change phone number'
      },
      editLanguage: {
        language: 'Language',
        editLanguage: 'Change language'
      },
      editSecurityLevel: {
        securityLevel: 'Security level',
        editSecurityLevel: 'Change security level'
      },
      editEmail: {
        editEmail: 'Change email',
        email: 'Email',
        confirmation: {
          title: 'Email sent',
          message: `An email has been sent to {email} with subject "Email change confirmation". Click the link it contains to confirm your new email address.`,
          hint: `If you can't find the email, check your spam folder. If you still can't find the email, make sure the new email address is correct and try again.`
        }
      },
      editPassword: 'Update password',
      updatedProfile: 'The profile was successfully updated',
      identityMerged:
        'Your account has been migrated, you will need to authenticate with your Microsoft credentials.',
      beforeDirectoryMergeTitle:
        'You will be redirected to the Microsoft login page',
      beforeDirectoryMergeConditions:
        'To switch from the Cegid account to your {dir} account',
      beforeDirectoryMergeText: `You must connect with your existing {dir} credentials. The email address you use can be the same as the one used to create your Cegid account.`,
      mergeAccountInfo: `You want to authenticate with your {dir} account`,
      mergeAction: `Merge your {value} account`,
      yourInformation: 'Your informations',
      mergeValidationText:
        'After migration, the connection will be made with your {dir} credentials for all the Cegid applications.',
      mergeValidationSubText: `To synchronize your {dir} account, simply log in again.`
    },
    personGroupProfile: {
      tabServices: {
        addServiceButton: 'Add a service'
      },
      about: {
        title: 'About the group'
      }
    },
    users: {
      title: 'Users',
      help:
        'From this list, you can select one or several users to revoke, export or give them some access rights on one or many applications from your services catalog.',
      name: 'Name',
      firstname: 'Firstname',
      action: {
        addUser: 'Invite users',
        delete: {
          confirmation: `You are going to delete {count, plural, one {{count, number} user} other {{count, number} users}}, do you want to continue?`,
          success: `{count, plural, one {{count, number} user has} other {{count, number} users have}} been deleted.`
        }
      },
      pageSelectedUserCount:
        '{count, plural, one {{count, number} user on this page is selected} other {{count, number} users on this page are selected}}.',
      selectUserCount:
        'Select all {count, plural, one {{count, number} user} other {{count, number} users}}',
      maxLimitExceeded:
        'Your selection exceeds the maximum limit of {count, plural, one {{count, number} user} other {{count, number} users}}. Please select less.',
      index: {
        administrators: 'Administrator',
        users: 'User',
        email: 'Email address',
        account: 'Account',
        creationDate: 'Creation date',
        activationDate: 'Activation date',
        language: 'Language',
        phoneNumber: 'Phone number',
        mobileNumber: 'Mobile phone number',
        titleCode: 'Title',
        allGroups: 'All groups',
        allRoles: 'All roles',
        allStatus: 'Status',
        item: {
          email: '{value}',
          group: '{value, select, TOCLASSIFY {To Classify} other {} }',
          role: '{value}',
          status:
            '{value, select, CREATED {Created} TOACTIVATE {To activate} ACTIVATED {Activated} DISABLED {Disabled} }'
        },
        selectedUserCount: `{count, plural, one {{count, number} selected user} other {{count, number} selected users}}`,
        userRoles: {
          administrators: 'Administrators • {count, number}',
          notadministrators: 'Non administrators • {count, number}'
        },
        userStatus: {
          actives: 'Actives • {count, number}',
          pending: 'Waiting for validation • {count, number}',
          created: 'Created • {count, number}'
        }
      },
      subscription: {
        success: {
          meToOne: `You are now subscribed to the service.`,
          meToMany: `You have been subscribed to {servicesCount} services.`,
          oneToOne: `The user is now subscribed to the service.`,
          oneToMany: `The user has been subscribed to {servicesCount} services.`,
          manyToOne: `{usersCount} users have been subscribed to the service.`,
          manyToMany: `{usersCount} users have been subscribed to {servicesCount} services.`
        }
      },
      export: {
        lists: 'Lists',
        columns: 'Columns',
        help: 'Select only the columns you want to export.',
        needServiceList: 'Select only lists you want to export.',
        itemToExport: 'Items to export',
        withServiceList: "User's service list",
        accountCode: 'Account code',
        accountLabel: 'Account label',
        creationDate: 'Creation date',
        email: 'Email',
        securityLevel: 'Security level',
        status: 'Status',
        services: 'List of services per users'
      }
    }
  },
  profile: {
    menu: {
      general: 'General',
      services: 'Services',
      restrictions: 'Restrictions',
      activities: 'Activities',
      members: 'Members',
      domains: 'Domains',
    },
    about: {
      title: 'About',
      creationDate: 'Creation',
      directories: 'Directories',
      language: 'Language',
      outsourceableService: {
        title: 'Outsourceable service'
      },
      serviceFamily: 'Service family'
    }
  },
  securityLevel: {
    weak: 'Weak',
    medium: 'Medium',
    high: 'High'
  },
  tableCollectionColumnSelection: {
    title: 'Columns configuration',
    hint:
      'Select the columns to display and reorganize them with drag & drop. Some columns may not be visible on small screens.'
  },
  about: {
    google: {
      title: 'Connect to Cegid Cloud with my Google account',
      subtitle:
        "Cegid Cloud is Cegid's ecosystem of online applications and services",
      connectToCegidProducts: `You can connect to the platform and the services that support it using your Google account.`,
      followSteps: `To do so, simply follow these steps:`,
      whatYouWant: 'What do you want to do?',
      connectToCegidApplicationWithYourGoogleAccount:
        'You connect to Cegid applications and services with your Google credentials',
      activateYourCegidAccountWithGoogle:
        'Activate your new Cegid account with your Google account',
      youAlrearyHaveACegidAccount:
        'You already have a Cegid account and want to log in with your Google account: Merge your account!',
      youNeedHelpWriteTo: `If you need help to activate or connect your Cegid account to your Google account, you can write to `,
      forInformation: `For information, you can find`,
      ourCGV: `our terms and conditions of use `,
      andOur: `as well as our`,
      privacyPolitic: `privacy policy and cookie`,
      userActivation: {
        youReceiveAnEmailToActivateYourAccount:
          'You have received a Cegid account activation email and wish to use your Google account credentials when connecting to Cegid applications',
        step1Title: '1. You have received an email',
        step1Content: 'Click on the activation link to proceed',
        step2Title: '2. You are redirected to an account activation page',
        step2Content: `You can activate your Cegid account using your Google account by clicking on the "Continue with Google" button`,
        step3Title: '3. You are redirected to a Google authentication page.',
        step3Content: 'Enter your Google Account credentials.',
        step4Title: '4. You are redirected to Cegid Account',
        step4Content: 'Your account has been successfully activated.',
        step4Success:
          'You should now be logged into Cegid Account and access your information.'
      },
      userUpdateIdp: {
        step1: '1. Login to your Cegid Account profile',
        step2:
          '2. From the directories section, you can link your Google account',
        step3: `3. Click on the button "Merge your Google acccount", you will be shown an information. Click on "I understand" to be redirected to a Google login screen`,
        step4:
          '4. Enter your email linked to the Google account you want to use to connect to Cegid applications',
        step5: `5. Verify your information, to continue with the account merge, click on "Migrate"`,
        step5End: `You will be logged out of the application.`,
        step6: `6. Log in with the email of the Google account you just linked to your Cegid profile`,
        step7: `You should now be logged into your profile with you updated informations`
      }
    }
  },
  helperText: {
    groupNameRequired: 'The group name is required',
    groupDescriptionRequired: 'The group description is required'
  },
  dataPrivacy:{
    title: 'Data protection policy',
    userProfileDataPrivacy: "Cegid SAS processes your personal data as part of managing your Cegid Account. You have the right to access, rectify, delete, and port your data, as well as a right to object and restrict certain processing. For more information on the processing and how to exercise your rights, you can consult the data protection policy.",
    sentence1: "The personal information entered in Cegid Account is processed by Cegid SAS to allow you to access Cegid services within the framework of the contractual relationship.",
    sentence2: "Your data may be communicated to subsidiaries of the data controller as well as to external service providers in charge of implementing the processing or to authorized third parties in the case of requests by competent authorities. It is necessary to provide an identifier (email or registration number, for example). Without it, the processing cannot be carried out.",
    sentence3: "Your personal data is retained {value} years.",
    sentence4: "Your personal data may be subject to transfers to countries located outside the European Union for the purposes detailed above. These transfers are subject to a specific legal framework to ensure that these data are covered by an adequate level of protection.",
    sentence5: "In compliance with the regulations applicable to the protection of personal data, you have the right to access, rectify, delete, and port your data, as well as a right to object and limit the processing of all data concerning you.",
    sentence6: "These rights can be exercised by writing to our data protection officer by sending your request to dataprivacy@cegid.com. You can exercise your right to appeal at any time with the Competent Authority for the protection of personal data (CNIL)."
  }
};

export default flat<TProfileManagerMessages, { [messageId: string]: string }>(
  englishResourceObject
);
