import { IconButton, Typography, Popover, Theme, Button, Paper, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { RemoveCircle as IconRemoveCircle } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApolloError, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { enqueueSnackbar } from 'notistack';
import TestIds from 'Tests/TestIds';
import {
  DeletePersonAccessRight,
  DeletePersonAccessRightMutation
} from '../../../../../../Apollo';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { ErrorHandler } from 'Theme/components/ErrorHandler';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1)
    },
    paper: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      maxWidth: 360,
      textAlign: 'center'
    },
    text: {
      marginBottom: theme.spacing(2)
    }
  });

interface Props {
  accessRight: IPersonAccessRight;
  onRefetch: () => void;
}

interface State {
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
}

class RemoveAccessRightButton extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state: State = {
    isOpen: false,
    anchorEl: null
  };

  private buttonRef = React.createRef<HTMLButtonElement>();

  public render() {
    const { accessRight, onRefetch, classes } = this.props;
    const { isOpen, anchorEl } = this.state;

    return (
      <ErrorHandler.Consumer>
        {errorHandler => (
          <Mutation<
            IDeletePersonAccessRightData,
            IDeletePersonAccessRightVariables
          >
            mutation={DeletePersonAccessRight}
            variables={{
              data: [
                {
                  personIdentifier: accessRight.person.immutableId,
                  accountCode: accessRight.account?.code,
                  serviceGroupCode: accessRight.service.groupCode
                }
              ]
            }}
            onCompleted={() => {
              enqueueSnackbar(
                <FormattedMessage
                  id="page.userProfile.services.removeSuccess"
                  values={{ serviceName: accessRight.service.name }}
                />,
                {
                  variant: 'success'
                }
              );
              onRefetch();
            }}
            onError={(e: ApolloError) => errorHandler.onError(e)}
          >
            {(
              deletePersonAccessRight: DeletePersonAccessRightMutation,
              { loading }: MutationResult
            ) => (
              <>
                <Tooltip
                  title={<FormattedMessage id='common.action.delete' />}
                >
                  <IconButton
                    ref={this.buttonRef}
                    onClick={() => {
                      this.setState({
                        isOpen: true,
                        anchorEl: this.buttonRef.current
                      });
                    }}
                    data-testid={
                      TestIds.pages.profile.services.unsubscribeButton
                    }
                    size="large"
                  >
                    <IconRemoveCircle />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={isOpen}
                  anchorEl={anchorEl}
                  onClose={() => {
                    this.setState({
                      isOpen: false,
                      anchorEl: null
                    });
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <Paper classes={{ root: classes.paper }}>
                    <Typography
                      variant="body2"
                      classes={{ root: classes.text }}
                    >
                      <FormattedMessage id="page.userProfile.services.removeConfirmation" />
                    </Typography>
                    <Button
                      size="medium"
                      color="inherit"
                      classes={{ root: classes.button }}
                      onClick={() => this.setState({ isOpen: false })}
                    >
                      <FormattedMessage id="common.action.cancel" />
                    </Button>
                    <ButtonLoading
                      data-testid={
                        TestIds.pages.profile.services.confirmDeletionButton
                      }
                      loading={loading}
                      type="submit"
                      size="medium"
                      color="primary"
                      variant="contained"
                      onClick={() => deletePersonAccessRight()}
                    >
                      <FormattedMessage id="common.action.delete" />
                    </ButtonLoading>
                  </Paper>
                </Popover>
              </>
            )}
          </Mutation>
        )}
      </ErrorHandler.Consumer>
    );
  }
}

export default withStyles(styles)(RemoveAccessRightButton);
