import Typography from '@mui/material/Typography';
import { Alert as MuiAlert, AlertTitle } from '@mui/material';
import { useStyles } from './Alert.styles';
import { ReactNode } from 'react';

enum Severity {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}
interface Props {
  severity: 'success' | 'info' | 'warning' | 'error';
  content: string | ReactNode;
  title?: string;
  icon?: ReactNode;
}

function Alert(props: Readonly<Props>) {
  const { severity, title, content, icon } = props;
  const classes = useStyles();

  const customClasse = () => {
    switch (severity) {
      case Severity.Success:
        return classes.success;
      case Severity.Error:
        return classes.error;
      case Severity.Warning:
        return classes.warning;
      case Severity.Info:
        return classes.info;
    }
  };

  return (
    <MuiAlert
      elevation={6}
      icon={icon}
      variant="filled"
      className={customClasse()}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography variant="body2" color="textPrimary">
        {content ?? ''}
      </Typography>
    </MuiAlert>
  );
}

export default Alert;
