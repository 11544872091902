import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { zIndex } from 'Theme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  toolbarMixin: {
    height: 48
  },
  subHeader: {
    zIndex: zIndex.drawer + 1,
    '&::before': {
      ...theme.mixins.toolbar,
      content: '""'
    }
  },
  subHeaderForSuperUser: {
    zIndex: zIndex.drawer + 1,
    '&::before': {
      height: 120,
      content: '""'
    }
  },
  backButton: {
    marginLeft: -6
  }
}));
