import { Button, Divider, AccordionActions, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApolloError, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  CreatePersonAccessRights,
  CreatePersonAccessRightsMutation
} from '../../../Apollo';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { MAX_SELECTED, ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import { useColumnsServices } from 'Hooks/useColumnsServices';
import TestIds from 'Tests/TestIds';
import { ServiceSubscriptionOrder } from '../../../Apollo/fragments/service/ServiceSubscription';
import { GetServiceSubscriptions } from '../../../Apollo/queries/service/GetServiceSubscriptions';
import { stringifyServiceSubscriptionNode } from '../../../Tools/stringifyServiceSubscriptionNode';
import useNotification from 'Hooks/useNotification';
import { useState } from 'react';
import { TProfileManagerMessagesKeys } from 'Languages/TProfileManagerMessages';
import { calculateNewPage } from 'Tools/calculateNewPage';

export const useStyles = makeStyles(() => ({
  expansionPanelDetails: {
    '& table>tbody>tr>td:nth-last-child(-n+2), & table>thead>tr>th:nth-last-child(-n+2)': {
      textAlign: 'center'
    }
  }
}));

type Props = {
  selectedUserIds: string[];
  selectAllUserEnabled: boolean;
  selectedUserCount: number;
  onCollapse: () => void;
};

const AddSubscriptions = (props: Props) => {
  const { accountCode } = useParams<IUriParams>();
  const columns = useColumnsServices({});
  const classes = useStyles();
  const { onError, onSuccess, onHandle } = useNotification({
    domain: 'service'
  });
  const {
    onCollapse,
    selectAllUserEnabled,
    selectedUserIds,
    selectedUserCount
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[] | null>([]);
  const [selectedServiceCount, setSelectedServiceCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<ServiceSubscriptionOrder>(
    ServiceSubscriptionOrder.NameAsc
  );

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const onChangeOrderBy = (orderBy: ServiceSubscriptionOrder) => {
    setOrderBy(orderBy);
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onUnselect = (ids: string[] | null) => {
    if (ids == null) {
      setSelectedIds([]);
    } else {
      setSelectedIds(
        selectedIds == null ? [] : selectedIds.filter(id => !ids.includes(id))
      );
    }
  };

  const onSelect = (ids: string[] | null) => {
    if (ids == null) {
      setSelectedIds(null);
    } else {
      setSelectedIds(
        ids.reduce(
          (acc, id) => (acc.includes(id) ? acc : acc.concat([id])),
          selectedIds ?? []
        )
      );
    }
  };

  const onCompleted = async (data: CreatePersonAccessRightsData) => {
    const errorMessages = data.createPersonAccessRights.reduce(
      (acc: string[], result) => {
        if (result.error == null || result.error.message == null) {
          return acc;
        }

        return acc.concat([result.error.message]);
      },
      []
    );

    if (errorMessages.length > 0) {
      onHandle(errorMessages);
      setIsLoading(false);
      return;
    }

    // The success message varies depending on the number of selected users and services
    let message: [TProfileManagerMessagesKeys, any?];
    if (
      selectedUserCount === 1 &&
      // personIds can be null even when there is exactly 1 user selected
      // in theory it should only be the case when there is only 1 user that has been selected through the "select all" checkbox
      selectedUserIds &&
      selectedUserIds.length > 0
    ) {
      if (selectedServiceCount === 1) {
        // ...and 1 service
        message = ['page.users.subscription.success.oneToOne'];
      } else {
        // ...and several services
        message = [
          'page.users.subscription.success.oneToMany',
          {
            servicesCount: selectedServiceCount
          }
        ];
      }
    } else {
      // For several users...
      if (selectedServiceCount === 1) {
        // ...and 1 service
        message = [
          'page.users.subscription.success.manyToOne',
          {
            usersCount: selectedUserCount
          }
        ];
      } else {
        // ...and several services
        message = [
          'page.users.subscription.success.manyToMany',
          {
            usersCount: selectedUserCount,
            servicesCount: selectedServiceCount
          }
        ];
      }
    }

    onSuccess(...message, 'persist');
    setIsLoading(false);

    onCollapse();
  };

  return (
    <Query
      query={GetServiceSubscriptions}
      variables={{
        accountCode: accountCode === 'all' ? null : accountCode,
        apiKeyEligibleOnly: false,
        offset: currentPage * rowsPerPage,
        first: rowsPerPage,
        orderBy
      }}
    >
      {({ loading, data }: QueryResult<GetServiceSubscriptionsData>) => (
        <Mutation<
          CreatePersonAccessRightsData,
          CreatePersonAccessRightsVariables
        >
          mutation={CreatePersonAccessRights}
          variables={{
            personIdentifiers: selectAllUserEnabled ? null : selectedUserIds,
            accessRightData: Array.isArray(selectedIds)
              ? selectedIds.map(id => JSON.parse(id))
              : null
          }}
          onError={(error: ApolloError) => {
            onError(error);
            setIsLoading(false);
          }}
          onCompleted={onCompleted}
        >
          {(createSubscription: CreatePersonAccessRightsMutation) => (
            <form
              onSubmit={event => {
                event.preventDefault();

                if (
                  isLoading ||
                  (Array.isArray(selectedIds) && selectedIds.length <= 0)
                ) {
                  return;
                }

                const count =
                  data == null || data.serviceSubscriptions == null
                    ? 0
                    : Array.isArray(selectedIds)
                    ? selectedIds.length
                    : data.serviceSubscriptions.totalCount;

                setIsLoading(true);
                setSelectedServiceCount(count);

                createSubscription();
              }}
              onReset={onCollapse}
            >
              <AccordionDetails
                classes={{ root: classes.expansionPanelDetails }}
              >
                <TableCollection
                  loading={loading}
                  columns={columns}
                  rows={(data?.serviceSubscriptions?.edges || []).map(
                    ({ node }) => node
                  )}
                  disabledRows={(data?.serviceSubscriptions?.edges || [])
                    .filter(({ node }) => node.isAvailable === false)
                    .map(row => stringifyServiceSubscriptionNode(row.node))}
                  getRowId={(serviceSubscription: IServiceSubscription) =>
                    stringifyServiceSubscriptionNode(serviceSubscription)
                  }
                  orderBy={orderBy}
                  onChangeOrderBy={orderBy =>
                    onChangeOrderBy(orderBy as ServiceSubscriptionOrder)
                  }
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  page={currentPage}
                  onChangePage={onChangePage}
                  onUnselect={onUnselect}
                  selectedIds={selectedIds}
                  onSelect={onSelect}
                  maxSelected={MAX_SELECTED}
                  totalCount={
                    data?.serviceSubscriptions
                      ? data.serviceSubscriptions.totalCount
                      : 100
                  }
                  messages={{
                    maxSelectedExceeded:
                      'page.userProfile.services.maxSelectedExceeded',
                    pageAllSelected:
                      'page.userProfile.services.pageAllSelected',
                    selectAll: 'page.userProfile.services.selectAll'
                  }}
                />
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                <Button type="reset" data-testid={TestIds.common.snackbar.action.cancel} color='inherit'>
                  <FormattedMessage id="common.action.cancel" />
                </Button>
                <ButtonLoading
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                >
                  <FormattedMessage id="common.action.add" />
                </ButtonLoading>
              </AccordionActions>
            </form>
          )}
        </Mutation>
      )}
    </Query>
  );
};

export default AddSubscriptions;
