import { useState } from 'react';
import PersonGroupPersons from './PersonGroupPersons';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { useQuery } from '@apollo/client';
import { GetPersonGroupPersons } from 'Apollo/queries/personGroup/GetPersonGroupPersons';
import useNotification from 'Hooks/useNotification';
import { calculateNewPage } from 'Tools/calculateNewPage';

interface Props {
  group: IPersonGroup;
}

const PersonGroupPersonsContainer = ({ group }: Props) => {
  const [search, setSearch] = useState<any>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const handleSearchChange = (searchTerm: any) => {
    setSearch(searchTerm);
    setCurrentPage(0);
  };

  const onChangePage = (page: number) => setCurrentPage(page);

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const { onError } = useNotification();

  const { data, loading, refetch } = useQuery<GetPersonGroupPersonsData>(
    GetPersonGroupPersons,
    {
      variables: {
        code: group.code,
        searchTerm: search,
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      },
      onError,
    }
  );

  return (
    <PersonGroupPersons
      group={group}
      loading={loading && data?.personGroupPersons == null}
      persons={data?.personGroupPersons?.edges.map(({ node }) => node) ?? []}
      totalCount={
        data?.personGroupPersons == null
          ? 100
          : data.personGroupPersons.totalCount
      }
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onRefetch={refetch}
      onSearchChange={handleSearchChange} /* Nouvelle prop pour mettre à jour la recherche */
    />
  );
};

export default PersonGroupPersonsContainer;
