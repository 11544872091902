import { useContext, useState } from 'react';
import useTranslate from '../../Hooks/useTranslate';
import { Helmet } from 'react-helmet';
import AccessRightsCardsContainer from 'Components/AccessRightsCards/AccessRightsCards.container';
import { Container as CustomContainer } from 'Theme/components';
import { useStyles } from './AccessRights.styles';
import { SearchByUser } from 'Components/Shared/SearchBars/SearchByUser';
import { Typography, Grid } from '@mui/material';
import TestIds from 'Tests/TestIds';
import { LocalContext } from 'Context/Local.context';

const AccessRights = () => {
  const ts = useTranslate();
  const { currentAccount } = useContext(LocalContext);
  const selectedAccountCode = currentAccount?.code ?? 'default';
  const classes = useStyles();
  const [currentSearch, setCurrentSearch] = useState<string | undefined>(
    undefined
  );
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );

  const changeSearchedValue = (value: string) => {
    setCurrentSearch(value);
  };

  const initSearch = () => {
    setCurrentSearch(undefined);
    setSelectedUserId(undefined);
  };

  const updateChosenID = (value: string) => {
    setSelectedUserId(value);
  };

  return (
    <>
      <Helmet title={ts('page.accessRights.title')} />
      <div className={classes.accessRightsTopSection}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.accessRightsRequestsPageTitle}
        >
          <Grid item>
            <Typography
              variant="h4"
              paragraph
              align="left"
              color="textPrimary"
              data-testid={TestIds.pages.accessRightsRequests.title}
            >
              {ts('page.accessRights.newRequests')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              color="textSecondary"
              align="left"
              data-testid={TestIds.pages.accessRightsRequests.subtitle}
            >
              {ts('page.accessRights.subtitle')}
            </Typography>
          </Grid>
        </Grid>

        <SearchByUser
          accountCode={selectedAccountCode}
          onSearch={changeSearchedValue}
          onItemSelect={updateChosenID}
          onInit={initSearch}
        />
      </div>
      <CustomContainer>
        <div className={classes.accessRightsCardsContainer}>
          <AccessRightsCardsContainer
            selectedAccountCode={selectedAccountCode}
            currentSearch={currentSearch}
            selectedUserId={selectedUserId}
          />
        </div>
      </CustomContainer>
    </>
  );
};

export default AccessRights;
