import { Typography, Grid, Divider } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DirectoryAvatar } from 'Theme/components/Avatar';
import Skeleton from 'Theme/components/Skeleton';
import { DataRow } from 'Theme/components/DataRow';
import TestIds from 'Tests/TestIds';
import { useStyles } from '../Directories.styles';
import DirectoryExtraInfo from '../DirectoryExtraInfo';
import { GetDirectoriesExtendedInfo } from 'Apollo';
import { Imgs } from 'Assets';
import useTranslate from 'Hooks/useTranslate';
import MergeableDirectory from './MergeableDirectory/MergeableDirectory';
import useNotification from 'Hooks/useNotification';

interface Props {
  person: IPersonWithAccount;
  isCurrentPerson: boolean;
  hasSuperRights: boolean;
}

function PersonDirectories(props: Props) {
  const ts = useTranslate();
  const classes = useStyles();
  const { person, hasSuperRights, isCurrentPerson } = props;
  const { onError } = useNotification();

  let { data, loading } = useQuery<
    GetDirectoriesExtendedInfoData,
    GetDirectoriesExtendedInfoVars
  >(GetDirectoriesExtendedInfo, {
    variables: {
      personImmutableId: person.immutableId
    },
    onError
  });

  if (loading || data == null || data.directoriesAuthenticatingPerson == null)
    return (
      <DataRow
        label={
          <Skeleton
            component="span"
            variant="rectangular"
            width="64px"
            height="64px"
          />
        }
        divider={true}
      >
        <Skeleton variant="rectangular" width="200px" />
      </DataRow>
    );

  const directoriesAuth = data.directoriesAuthenticatingPerson;

  /* shouldn't already have a federated identity */
  const hasAFederatedDirectory = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.isFederated
  );

  const isFederatedWithGoogle = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.label === 'Google'
  );

  const isFederatedWithMicrosoft = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.label === 'Microsoft'
  );

  /* -- To be able to merge a local account with a MS account the current user -- */
  /* shouldn't have an email address that could be managed by a federabled directory */
  /* example even if toto@federatedDirectory.com have no identity, his directory different from MS is federated */
  const userEmailManagedByFederatedDirectoryNotMS =
    hasAFederatedDirectory && !isFederatedWithMicrosoft;
  const userEmailManagedByFederatedDirectoryNotGoogle =
    hasAFederatedDirectory && !isFederatedWithGoogle;

  const canMergeLocalAccountToMS =
    isCurrentPerson &&
    !hasAFederatedDirectory &&
    !userEmailManagedByFederatedDirectoryNotMS;
  const canMergeLocalAccountToGoogle =
    isCurrentPerson &&
    !hasAFederatedDirectory &&
    !userEmailManagedByFederatedDirectoryNotGoogle;

  return (
    <Grid
      container
      direction="column"
      data-testid={TestIds.pages.profile.directories.directoriesList}
    >
      {directoriesAuth.map((directory, index) => (
        <Grid
          key={directory.identityIdentifier}
          item
          container
          direction="row"
          alignItems="center"
          data-testid={
            TestIds.pages.profile.directories.directoriesListItem + index
          }
        >
          <Grid item>
            {directory.label === 'Microsoft' && (
              <img
                src={Imgs.microsoft.src}
                alt={Imgs.microsoft.alt}
                className={classes.logo}
                data-testid={TestIds.pages.profile.mergeAccount.MSlogo}
              />
            )}
            {directory.label === 'Cegid Passeport' && (
              <img
                src={Imgs.cegidCorners.src}
                alt={Imgs.cegidCorners.alt}
                className={classes.logo}
                data-testid={TestIds.pages.profile.mergeAccount.CegidLogo}
              />
            )}
            {directory.label === 'Google' && (
              <img
                src={Imgs.google.src}
                alt={Imgs.google.alt}
                className={classes.logo}
                data-testid={TestIds.pages.profile.mergeAccount.GoogleLogo}
              />
            )}
            {directory.label !== 'Microsoft' &&
              directory.label !== 'Cegid Passeport' &&
              directory.label !== 'Google' && (
                <DirectoryAvatar directory={directory} size="larger" />
              )}
          </Grid>

          <Grid item>
            <Typography variant="body2">
              {directory.label !== null && directory.label}
            </Typography>
          </Grid>

          {hasSuperRights && (
            <Grid
              item
              xs={12}
              sm={9}
              className={classes.directoryExtraInfoBloc}
              data-testid={
                TestIds.pages.userProfile.directories.directoryExtraInfoBloc +
                '-' +
                directory.identityIdentifier
              }
            >
              {!!directory.identifier && (
                <DirectoryExtraInfo
                  key={directory.label + 'directory.identifier'}
                  title={ts('page.userProfile.section.technical.identifier')}
                  content={directory.identifier}
                  id={
                    TestIds.pages.userProfile.directories.identifier +
                    '-' +
                    index
                  }
                />
              )}
              {!!directory.identityIdentifier && (
                <DirectoryExtraInfo
                  key={directory.label + 'directory.identityIdentifier'}
                  title={ts(
                    'page.userProfile.section.technical.identityIdentifier'
                  )}
                  content={directory.identityIdentifier}
                  id={
                    TestIds.pages.userProfile.directories.identityIdentifier +
                    '-' +
                    index
                  }
                />
              )}
              {!!directory.customAttribute &&
                !!directory.customAttribute.tenantId && (
                  <DirectoryExtraInfo
                    key={directory.label + 'directory.customAttribute.tenantId'}
                    id={
                      TestIds.pages.userProfile.directories.tenantid +
                      '-' +
                      index
                    }
                    title={ts('page.userProfile.section.technical.tenantId')}
                    content={directory.customAttribute.tenantId}
                  />
                )}
              {!!directory.customAttribute &&
                !!directory.customAttribute.objectId && (
                  <DirectoryExtraInfo
                    key={directory.label + 'directory.customAttribute.objectId'}
                    id={
                      TestIds.pages.userProfile.directories.objectId +
                      '-' +
                      index
                    }
                    title={ts('page.userProfile.section.technical.objectId')}
                    content={directory.customAttribute.objectId}
                  />
                )}
              {!!directory.customAttribute &&
                !!directory.customAttribute.upn && (
                  <DirectoryExtraInfo
                    key={directory.label + 'directory.customAttribute.upn'}
                    id={TestIds.pages.userProfile.directories.upn + '-' + index}
                    title={ts('page.userProfile.section.technical.upn')}
                    content={directory.customAttribute.upn}
                  />
                )}
            </Grid>
          )}
          <Divider variant="fullWidth" />
        </Grid>
      ))}
      {canMergeLocalAccountToMS && (
        <MergeableDirectory dirKey="microsoft" person={person} />
      )}
      {canMergeLocalAccountToGoogle && (
        <MergeableDirectory dirKey="google" person={person} />
      )}
    </Grid>
  );
}

export default PersonDirectories;
