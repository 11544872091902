import { Link, generatePath, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { PrivateRoutes } from 'Router/Routes';
import { PersonAvatar } from 'Theme/components/Avatar';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

interface Props {
  person: ILightPersonProfile;
}

function ShorterUserNameCell(props: Readonly<Props>) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const classes = useStyles();
  return (
    <Link
      to={generatePath(PrivateRoutes.usersProfile.path, {
        accountCode,
        immutableId: props.person.immutableId
      })}
      className={classes.link}
    >
      <PersonAvatar component="span" person={props.person} size="smaller" />
    </Link>
  );
}

export default ShorterUserNameCell;
