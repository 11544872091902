import { ArrowBack as IconArrowBack } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@mui/material';
import { useStyles } from './SubHeader.styles';
import { useHistory } from 'react-router-dom';
import useTranslate from '../../../Hooks/useTranslate';
import Tabs from 'Components/Shared/Tabs';
import { TTabView } from '../Tabs/Tabs';
import TestIds from 'Tests/TestIds';
import { useContext } from 'react';
import { PersonContext } from 'Context/CurrentPerson';
import { locationHistoryContext } from '../../../Router/PreviousLocation';

interface Props {
  tabs?: TTabView[];
}

const SubHeader = ({ tabs }: Props) => {
  const { previousLocation } = useContext(locationHistoryContext);
  const classes = useStyles();
  const history = useHistory();
  const ts = useTranslate();

  const { currentPersonRoles } = useContext(PersonContext);

  const isSimpleUserOnly = !currentPersonRoles.isAccountAdmin;
  const displayPreviousButton = (previousLocation !== null) && !isSimpleUserOnly

  return (
    <AppBar
      component="div"
      color="default"
      className={
        currentPersonRoles.hasAccessToAccounts ? classes.subHeaderForSuperUser : classes.subHeader
      }
      elevation={0}
    >
      <Toolbar variant="dense">
        <Grid container>
          <Grid item xs={2}>
            {displayPreviousButton && (
              <Button
                onClick={() => history.goBack()}
                startIcon={<IconArrowBack />}
                classes={{ root: classes.backButton }}
                data-testid={TestIds.pages.profile.previousPageButton}
              >
                  <Typography sx={{display: {xs: 'none', md: "block"}}} variant="button" color="textSecondary">
                    {ts('common.action.back')}
                  </Typography>
              </Button>
            )}
          </Grid>
          <Grid item container xs={8} justifyContent="center">
            {!!tabs && <Tabs views={tabs} />}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SubHeader;
