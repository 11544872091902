import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorBackgroundPrimaryLight } from 'Theme/constants/tokens';

export const useStyles = makeStyles((theme: Theme) => ({
    emptyServices: {
        padding: `0 ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)}`
    },
    circularClearer: {
        margin: '44px auto',
        opacity: '0.3'
    },
    emptyState: {
        width: '100%',
        padding: '2em'
    },
    drawer: {
        borderRadius: '20px 20px 0 0',
        '& table>tbody>tr>td:nth-last-child(-n+2), & table>thead>tr>th:nth-last-child(-n+2)': {
            textAlign: 'center'
        },
        maxHeight: '90%',
        paddingTop: theme.spacing(2)
    },
    drawerButtons: {
      display: 'flex',
      justifyContent: "end",
      gap: theme.spacing(1),
      margin: `${theme.spacing(1)} 0`,
      position: 'sticky',
      bottom: 0,
      background: 'white',
      padding: '8px',
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    expansionPanel: {
        backgroundColor: 'transparent'
    },
    expansionPanelSummary: {
        cursor: 'auto'
    },
    input: {
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut
        }),
        width: 0
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      backgroundColor: CdsColorBackgroundPrimaryLight,
    },
    headerLeft: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: "center",
      marginLeft: theme.spacing(2)
    }
}))