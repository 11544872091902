import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import {
  Person as IconPerson,
  ArrowForward as IconArrowForward
} from '@mui/icons-material';
import { PrivateRoutes } from 'Router/Routes';
import CardCounter from 'Theme/components/CardCounter';
import TestIds from 'Tests/TestIds';
import useNotification from 'Hooks/useNotification';

export const GetPersonsTotalCount = gql`
  query GetPersonsTotalCount($personFilter: PersonFilterInputType) {
    persons(personFilter: $personFilter, first: 0) {
      totalCount
    }
  }
`;

interface GetPersonsTotalCountData {
  persons: {
    totalCount: number;
  };
}

interface Props {
  showButton?: boolean;
}

function UsersCounter(props: Readonly<Props>) {
  const { accountCode } = useParams<{ accountCode: string }>();

  const { onError } = useNotification();

  const { data, loading } = useQuery<GetPersonsTotalCountData>(
    GetPersonsTotalCount,
    {
      variables: {
        personFilter: {
          accountCode: accountCode === 'all' ? null : accountCode
        }
      },
      onError,
      fetchPolicy: 'cache-first'
    }
  );

  return (
    <CardCounter
      testId={TestIds.pages.dashboard.card.users}
      title={<FormattedMessage id="menu.users" />}
      counter={
        (loading ?? data === undefined) ? (
          <Skeleton variant="rectangular" width="100px" title='progressbar'/>
        ) : (
          <div data-testid={TestIds.pages.users.totalCount}>
            <FormattedNumber value={data.persons.totalCount} />
          </div>
        )
      }
      icon={<IconPerson />}
      action={
        props.showButton ? (
          <Button
            color="primary"
            component={React.forwardRef<Link, ButtonProps>(
              ({ className, children }, ref) => (
                <Link
                  ref={ref}
                  className={className}
                  to={generatePath(PrivateRoutes.users.path, {
                    accountCode: accountCode
                  })}
                  data-testid={TestIds.pages.dashboard.card.userListButton}
                >
                  {children}
                </Link>
              )
            )}
            endIcon={<IconArrowForward />}
          >
            <FormattedMessage id="page.dashBoard.index.seeTheList" />
          </Button>
        ) : null
      }
    />
  );
}

export default UsersCounter;
