import {
  CdsColorBrandNeutral40,
  CdsColorBackgroundWarnLight,
  CdsColorTextWarnHighEmphasis,
  CdsColorBackgroundCriticalLight,
  CdsColorTextCriticalHighEmphasis,
  CdsColorBackgroundSuccessLight,
  CdsColorTextSuccessHighEmphasis,
  CdsColorTextInfoHighEmphasis,
  CdsColorBackgroundInfoLight,
  CdsColorBrandNeutral90
} from 'Theme/constants/tokens';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './CustomStatus.styles';

interface Props {
  label: string;
  variant?: 'success' | 'error' | 'warning' | 'pending' | 'info';
}

function CustomStatus({ label, variant }: Readonly<Props>) {
  const isMobile = useMediaQuery('(max-width:740px)');
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const classes = useStyles();

  const noBackground = isMobile || isPortrait;
  const displayLabel = !isMobile && !isPortrait;

  switch (variant) {
    case 'info':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: noBackground
              ? 'transparent'
              : CdsColorBackgroundInfoLight
          }}
        >
          <InfoOutlinedIcon
            fontSize="small"
            style={{ color: CdsColorTextInfoHighEmphasis }}
          />
          <Box width="4px" />
          {displayLabel && (
            <Typography variant="caption" color={CdsColorTextInfoHighEmphasis} role="custom-status-info">
              {label}
            </Typography>
          )}
        </div>
      );
    case 'success':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: noBackground
              ? 'transparent'
              : CdsColorBackgroundSuccessLight
          }}
        >
          <CheckCircleOutlineIcon
            fontSize="small"
            style={{ color: CdsColorTextSuccessHighEmphasis }}
          />
          <Box width="4px" />
          {displayLabel && (
            <Typography
              variant="caption"
              color={CdsColorTextSuccessHighEmphasis}
              role="custom-status-success"
            >
              {label}
            </Typography>
          )}
        </div>
      );
    case 'warning':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: noBackground
              ? 'transparent'
              : CdsColorBackgroundWarnLight
          }}
        >
          <ErrorOutlineIcon
            fontSize="small"
            style={{ color: CdsColorTextWarnHighEmphasis }}
          />
          <Box width="4px" />
          {displayLabel && (
            <Typography variant="caption" color={CdsColorTextWarnHighEmphasis} role="custom-status-warning">
              {label}
            </Typography>
          )}
        </div>
      );
    case 'pending':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: noBackground
              ? 'transparent'
              : CdsColorBackgroundCriticalLight
          }}
        >
          <AccessTimeIcon
            fontSize="small"
            style={{ color: CdsColorTextCriticalHighEmphasis }}
          />
          <Box width="4px" />
          {displayLabel && (
            <Typography
              variant="caption"
              color={CdsColorTextCriticalHighEmphasis}
              role="custom-status-pending"
            >
              {label}
            </Typography>
          )}
        </div>
      );
    case 'error':
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: noBackground
              ? 'transparent'
              : CdsColorBackgroundCriticalLight
          }}
        >
          <ErrorOutlineIcon
            fontSize="small"
            style={{ color: CdsColorTextCriticalHighEmphasis }}
          />
          <Box width="4px" />
          {displayLabel && (
            <Typography
              variant="caption"
              color={CdsColorTextCriticalHighEmphasis}
              role="custom-status-error"
            >
              {label}
            </Typography>
          )}
        </div>
      );
    default:
      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: CdsColorBrandNeutral90
          }}
        >
          <Typography variant="caption" color={CdsColorBrandNeutral40} role="custom-status-default">
            {label}
          </Typography>
        </div>
      );
  }
}

export default CustomStatus;
