import { useState, useContext } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import { mdiCalendarClock } from '@mdi/js';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import UserServicesActionsDrawer from 'Components/ServiceMembersPanel/Drawers/UserServicesActionsDrawer';
import AccessRightAvailability from '../AccessRightAvailability';
import RemoveAccessRightButton from './RemoveAccessRightButton';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import TestIds from 'Tests/TestIds';
import accountLabel from 'Tools/LabelFormatter/accountLabel';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './AccessRightsTableRow.styles';
import { PersonContext } from 'Context/CurrentPerson';
import { PersonAccessRightStatus } from 'Apollo';
import AccessRightAvailabilitySmallWidth from '../AccessRightAvailability/AccessRightAvailabilitySmallWidth';
import { CdsColorActionNeutralActive } from 'Theme/constants/tokens';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GroupIcon from '@mui/icons-material/Group';
import { FormattedDate } from 'react-intl';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

interface AccessRightsTableRowProps {
  serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier;
  accessRight: IPersonAccessRight;
  isMobile: boolean;
  onRefetch: () => void;
  isRemovalAllowed?: boolean;
}

const AccessRightsTableRow = ({
  serviceSubscriptionIdentifier,
  accessRight,
  isMobile,
  onRefetch,
  isRemovalAllowed
}: AccessRightsTableRowProps) => {
  const classes = useStyles();
  const ts = useTranslate();
  const { currentPersonRoles } = useContext(PersonContext);

  const [drawerMode, setDrawerMode] = useState<string | null>(null);

  let canEdit = currentPersonRoles.isAccountAdmin || currentPersonRoles.isServiceAdmin;
  let canWrite = isRemovalAllowed || currentPersonRoles.isServiceAdmin;
  const dateToDisplay = accessRight?.creationDate !== null ? accessRight.creationDate : '';

  return (
    <>
      <TableRow className={classes.noHover}>
        <TableCell>
          <Typography variant="subtitle1">
            {accessRight.service ? accessRight.service.name : '/'}
          </Typography>
          {accessRight.account && (
            <Typography variant="body2">
              {isMobile
                ? accessRight.account.name
                : accountLabel(accessRight.account)}
            </Typography>
          )}
        </TableCell>
        {!isMobile && (
          <>
            <TableCell>
              <Typography variant="body2">
                {accessRight.service ? accessRight.service.family : '/'}
              </Typography>
            </TableCell>
            <TableCell>
              <AccessRightAvailability accessRight={accessRight} />
            </TableCell>
          </>
        )}
        <TableCell>
          {accessRight.service.description && (
            <IconButtonWithPopOver
              tooltip={ts('common.action.see.seeDescription')}
              customIcon={<InfoOutlinedIcon />}
              description={
                <div
                  data-testid={
                    TestIds.pages.userProfile.tabServices.serviceRow.description +
                    accessRight.service.groupCode
                  }
                >
                  <Typography variant="body2">
                    {accessRight.service.description}
                  </Typography>
                  {dateToDisplay !== '' && accessRight.status === PersonAccessRightStatus.Accepted && (
                      <Typography variant="caption" color="secondary">
                        {ts('page.userProfile.services.accessToServiceSince')}
                        <FormattedDate
                          value={dateToDisplay}
                          year="numeric"
                          month="2-digit"
                          day="2-digit"
                        />
                      </Typography>
                    )}
                </div>
              }
              id={
                TestIds.pages.profile.services.serviceDescriptionIcon +
                accessRight.service.groupCode
              }
            />
          )}
          {isMobile && (
            <AccessRightAvailabilitySmallWidth accessRight={accessRight} />
          )}
          {accessRight.personGroupSource !== null && (
            <IconButtonWithPopOver
              tooltip={ts('common.action.see.seeGroupDetails')}
              id={
                TestIds.pages.userProfile.tabServices.serviceRow
                  .inheritedFromGroupIcon + accessRight.service.groupCode
              }
              customIcon={<GroupIcon />}
              description={
                <>
                  <Typography variant="body2">
                    {ts('page.userProfile.services.inheritedFromGroup')}
                  </Typography>
                  <Typography variant="body2" className={classes.inheritedGroup}>
                    {accessRight.personGroupSource.name}
                  </Typography>
                </>
              }
            />
          )}
          {accessRight.expirationDate !== null && (
            <IconButtonWithPopOver
              tooltip={ts('common.action.see.seeExpirationDate')}
              customIcon={<AccessTimeFilledIcon />}
              description={
                <Typography variant="body2">
                  {ts('common.date.expireDetails', {
                    date: (
                      <FormattedDate
                        value={new Date(accessRight.expirationDate)}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                      />
                    ),
                    hour: (
                      <FormattedDate
                        value={new Date(accessRight.expirationDate)}
                        hour="2-digit"
                        minute="2-digit"
                      />
                    )
                  })}
                </Typography>
              }
            />
          )}
        </TableCell>
        <TableCell>
          {canEdit &&
            accessRight.status === PersonAccessRightStatus.Accepted &&
            accessRight.personGroupSource === null && (
              <Tooltip title={ts('common.date.editExpirationDate')}>
                <IconButton
                  onClick={() => setDrawerMode('setExpiration')}
                  size="large"
                >
                  <SvgIcon
                    path={mdiCalendarClock}
                    fill={CdsColorActionNeutralActive}
                    width="24"
                  />
                </IconButton>
              </Tooltip>
            )}

          {canWrite &&
            accessRight.status === PersonAccessRightStatus.Accepted &&
            accessRight.personGroupSource === null && (
              <RemoveAccessRightButton
                accessRight={accessRight}
                onRefetch={onRefetch}
              />
            )}
        </TableCell>
      </TableRow>
      {drawerMode && (
        <UserServicesActionsDrawer
          serviceSubscriptionIdentifier={serviceSubscriptionIdentifier}
          userIdentifier={accessRight.person.immutableId}
          selectedIds={[accessRight.service.groupCode]}
          currentMode={drawerMode}
          setMode={setDrawerMode}
        />
      )}
    </>
  );
};

export default AccessRightsTableRow;
