import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DatePickerButton from 'Components/Shared/DatePickerButton';
import useTranslate from 'Hooks/useTranslate';
import { useState } from 'react';
import TestIds from 'Tests/TestIds';
import ButtonLoading from 'Theme/components/ButtonLoading';

interface Props {
  loading: boolean;
  onCancel(): void;
  onSubmit(newExpiryDate: Date | null): void;
  title: string;
}

const SetExpirationDate = ({ onCancel, onSubmit, loading, title }: Props) => {
  const ts = useTranslate();
  const [expiryDate, setExpiryDate] = useState<Date | null | undefined>(
    undefined
  );

  function handleSubmit() {
    if (expiryDate !== undefined) {
      onSubmit(expiryDate);
    } else {
      throw new Error('expiryDate is undefined');
    }
  }

  return (
    <Box m={5}>
      <Grid container direction="column" alignContent="center" spacing={4}>
        <Grid item>
          <Typography variant="subtitle1" align="center">
            {title}
          </Typography>
        </Grid>
        {expiryDate !== null && (
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {expiryDate === undefined && (
                <>
                  <Grid item>
                    <Button
                      type="button"
                      size="medium"
                      variant="outlined"
                      color="primary"
                      onClick={() => setExpiryDate(null)}
                      data-testid={
                        TestIds.component.setExpirationDate
                          .removeExpiryDateButton
                      }
                    >
                      {ts(
                        'page.serviceProfile.serviceMembers.removeExpiration'
                      )}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {ts('common.terminology.or')}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item>
                <Stack gap={2} flexDirection="row" alignItems="center">
                  <Typography variant="caption" color="primary">
                    {ts(
                      'page.serviceProfile.serviceMembers.changeExpirationDate'
                    )}
                  </Typography>

                  <DatePickerButton
                    variant="outlined"
                    onAccept={d => setExpiryDate(d)}
                    onDecline={onCancel}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}

        {expiryDate !== undefined && (
          <Grid item>
            {expiryDate === null && (
              <Typography variant="body2" color="primary" align="center">
                {ts(
                  'page.serviceProfile.serviceMembers.expirationDateAlert.infoSentence1'
                )}
              </Typography>
            )}
            {expiryDate !== null && (
              <Typography variant="body2" color="primary" align="center">
                {ts(
                  'page.serviceProfile.serviceMembers.expirationDateAlert.infoSentence2',
                  { date: expiryDate.toLocaleDateString() }
                )}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item>
          <Grid container justifyContent="space-around">
            <Button type="button" size="medium" onClick={onCancel} color='inherit'>
              {ts('common.action.cancel')}
            </Button>
            <ButtonLoading
              name={TestIds.component.setExpirationDate.confirmButton}
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={loading}
              disabled={expiryDate === undefined}
              onClick={handleSubmit}
            >
              {ts('common.action.confirm')}
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetExpirationDate;
