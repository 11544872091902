import { Link, generatePath, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { PrivateRoutes } from 'Router/Routes';
import { PersonAvatar } from 'Theme/components/Avatar';
import { userFullName } from 'Tools';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  name: {
    display: 'inline-block',
    marginLeft: theme.spacing(2)
  }
}));

interface Props {
  person: ILightPersonProfile;
}

function UserNameCell(props: Props) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const classes = useStyles();
  return (
    <Link
      to={generatePath(PrivateRoutes.usersProfile.path, {
        accountCode,
        immutableId: props.person.immutableId
      })}
      className={classes.link}
    >
      <PersonAvatar component="span" person={props.person} size="smaller" />
      <span className={classes.name}>
        {userFullName(props.person.lastName, props.person.firstName)}
      </span>
    </Link>
  );
}

export default UserNameCell;
