import { Avatar, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AvatarProps } from '@mui/material/Avatar';
import { Person as IconPerson } from '@mui/icons-material';
import MuiTheme from 'Theme/MuiTheme';
import * as React from 'react';
import { userFullName } from 'Tools';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: '1.3em'
  },
  smallest: {
    fontSize: 6,
    letterSpacing: '0.67px',
    width: 16,
    height: 16
  },
  smaller: {
    fontSize: 10,
    letterSpacing: '1.39px',
    width: 24,
    height: 24
  },
  small: {
    fontSize: 12,
    letterSpacing: '1.67px',
    width: 32,
    height: 32
  },
  medium: {
    fontSize: 16,
    letterSpacing: '1.67px',
    width: 40,
    height: 40
  },
  large: {
    fontSize: 24,
    letterSpacing: '1.67px',
    width: 48,
    height: 48
  },
  larger: {
    fontSize: 32,
    letterSpacing: '1.67px',
    width: 72,
    height: 72
  },
  largest: {
    fontSize: 40,
    letterSpacing: '1.67px',
    width: 100,
    height: 100
  }
}));

interface Props extends AvatarProps {
  person: ILightPersonProfile | null;
  isDisabled?: boolean;
  component?: React.ElementType;
  tooltipTitle?: string;
  size?:
    | 'smallest'
    | 'smaller'
    | 'small'
    | 'medium'
    | 'large'
    | 'larger'
    | 'largest';
}

const PersonAvatar = (props: Props) => {
  const classes = useStyles();
  const { person, size = 'medium', isDisabled, tooltipTitle, ...rest } = props;

  const hasInitials =
    person?.avatar?.initials && person?.avatar?.initials?.length > 0;

  const userName = userFullName(
    person?.firstName ?? '',
    person?.lastName ?? ''
  );

  return (
    <Tooltip title={tooltipTitle ?? userName}>
      <Avatar
        {...rest}
        classes={{
          root: classes[size]
        }}
        style={
          hasInitials
            ? {
                backgroundColor: isDisabled
                  ? MuiTheme.palette.grey[300]
                  : person?.avatar?.color
              }
            : {}
        }
      >
        {hasInitials ? (
          person?.avatar?.initials
        ) : (
          <IconPerson className={classes.icon} />
        )}
      </Avatar>
    </Tooltip>
  );
};

export default PersonAvatar;
