import { Helmet } from 'react-helmet';
import {
  injectIntl,
  WrappedComponentProps as InjectedIntlProps
} from 'react-intl';
import { Route, Switch, Redirect, generatePath } from 'react-router-dom';
import Header from 'Components/Header';
import { PersonContext } from 'Context/CurrentPerson';
import { PrivateRoutes } from './Routes';
import { ReactCookieProps } from 'react-cookie';
import LocalRoute from './LocalContextRouter';
import useTranslate from 'Hooks/useTranslate';
import { useContext } from 'react';
import { PageError } from 'Page/PageError';
import { useQuery } from '@apollo/client';
import { GetAccounts } from 'Apollo';
import ErrorHandlerContext from 'Context/ErrorHandler.context';
import {
  ACCOUNTS_MIN_TO_SEARCH_BY_ACCOUNT
} from 'Theme/config';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

interface Props extends InjectedIntlProps, ReactCookieProps {}

function AppRoutes(props: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  const { currentPersonRoles, currentPerson } = useContext(PersonContext);
  const ErrorHandler = useContext(ErrorHandlerContext);
  const { hasTooManyAccounts } = useCurrentUserInfo();

  const { data } = useQuery<GetAccountsData>(GetAccounts, {
    variables: {
      first: ACCOUNTS_MIN_TO_SEARCH_BY_ACCOUNT
    },
    onError: ErrorHandler.onError,
    skip: !currentPersonRoles.isAccountAdmin
  });

  /** Get only first ACCOUNTS_MIN_TO_SEARCH_BY_ACCOUNT accounts */
  const accounts =
    !!data && data?.accounts.totalCount > 0
      ? data?.accounts.edges.map(acc => acc.node)
      : undefined;

  const hasAccessToAccountSelectionPanel =
    (currentPersonRoles.isAccountAdmin && hasTooManyAccounts) ||
    currentPersonRoles.hasAccessToAccounts;

  const ts = useTranslate();
  let token = urlParams.get('token');

  return (
    <>
      <Helmet
        titleTemplate={`${props.intl.formatMessage({
          id: 'applicationName'
        })} | %s`}
        defaultTitle={props.intl.formatMessage({ id: 'applicationName' })}
      />
      <Switch>
        <Route
          cookies={props.cookies}
          path="/"
          exact={true}
          render={() => {
            if (hasAccessToAccountSelectionPanel) {
              return (
                <Redirect
                  to={generatePath(PrivateRoutes.accountSelection.path, {
                    accountCode: 'all'
                  })}
                />
              );
            }
            if (currentPersonRoles.isAccountAdmin) {
              return (
                <Redirect
                  to={generatePath(PrivateRoutes.dashboard.path, {
                    accountCode: 'all'
                  })}
                />
              );
            }

            return (
              <Redirect
                to={generatePath(PrivateRoutes.usersProfile.path, {
                  accountCode: 'all',
                  immutableId: currentPerson.immutableId
                })}
              />
            );
          }}
        />
        <Route
          cookies={props.cookies}
          path={PrivateRoutes.changeEmailValidation.path}
          exact={false}
          render={() => {
            if (token)
              return PrivateRoutes.changeEmailValidation.children({ token });
            return (
              <Redirect
                to={generatePath(PrivateRoutes.usersProfile.path, {
                  accountCode: 'all',
                  immutableId: currentPerson.immutableId
                })}
              />
            );
          }}
        />
        <Route cookies={props.cookies} path="/:accountCode">
          <LocalRoute
            accounts={accounts}
            accountsCount={data?.accounts.totalCount}
          />
        </Route>
        <Route
          cookies={props.cookies}
          render={() => (
            <>
              <Header />
              <PageError
                title={ts('error.type.E404')}
                textPrimary={ts('error.sentence.E404')}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}

export default injectIntl(AppRoutes);
